import css from "./CongratulationsTabMobile.module.scss";
import TabPanel from "../../../TabPanel";
import Lottie from "lottie-react";
import animationData from "../../../../../utils/confetti.json";
import { useAppDispatch } from "../../../../../app/hooks";
import {
    reset as resetTabs,
    setCurrentTab,
} from "../../../../../features/Tabs/tabSlice";
import { reset as resetResources } from "../../../../../features/Resources/resourceSlice";
import { reset as resetDiscounts } from "../../../../../features/Discounts/discountSlice";
interface TabProps {
    index: number;
}

const CongratulationsTabMobile: React.FC<TabProps> = ({ index }) => {
    const dispatch = useAppDispatch();

    return (
        <TabPanel index={19} value={index}>
            <Lottie
                animationData={animationData}
                loop={false}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: "-1",
                }}
            />

            <div className={css.wrapper}>
                <div className={css.centeredText}>
                    <p className={css.orangeTitle}>Congratulations!</p>
                    <p className={css.note}>
                        You’ve submitted the requirements, the sales team will
                        be in tuch with you shortly!
                    </p>
                    <div
                        className={css.purpleCont}
                        onClick={() => {
                            dispatch(setCurrentTab(0));
                            dispatch(resetDiscounts());
                            dispatch(resetResources());
                            dispatch(resetTabs());
                        }}
                    >
                        <p className={css.purpleText}>Try Again!</p>
                        <p className={css.purpleContSubtext}>
                            Try a new combination of resources this time
                        </p>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default CongratulationsTabMobile;
