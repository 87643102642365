import React, { useState, useEffect } from "react";
import css from "./ResourseCard.module.scss";
import Icon from "../../../../../Icon/Icon";
import { vectorIcon } from "../../../../../../css/icons";

type ResourceObject = {
    title: string;
    computedPrice: number;
    count: number;
};

interface ResourseCard {
    resource: ResourceObject;
}

const ResourseCard: React.FC<ResourseCard> = ({ resource }) => {
    const { title, count, computedPrice } = resource;
    return (
        <div className={css.ResourseCard}>
            <div>
                <Icon path={vectorIcon} />
                <p className={css.title}>{title}</p>
                <small className={css.count}>
                    {count} {count === 1 ? "resource" : "resources"}
                </small>
            </div>
            <div className={css.priceDiv}>
                <p className={css.price}>
                    $
                    {Number(Math.abs(computedPrice)).toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                    })}
                    <span> /month</span>
                </p>
                <small>Total</small>
            </div>
        </div>
    );
};

export default ResourseCard;
