import { useState, useEffect, useMemo } from "react";
import css from "./MobileLocationTab.module.scss";
import { Button, MenuItem, Select } from "@mui/material";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TabPanel from "../../TabPanel";
import Icon from "../../../Icon/Icon";
import { chevronIcon } from "../../../../css/icons";
import {
    setRegionById,
    setTabIndexById,
} from "../../../../features/Resources/resourceSlice";
import ResourceService from "../../../../services/resource.service";

interface TabProps {
    index: number;
    id: string;
}

const resourceService = new ResourceService();

const MobileLocationTab: React.FC<TabProps> = ({ index, id }) => {
    const [preference, setPreference] = useState(false);
    const [regionValue, setRegionValue] = useState("");
    const [regionOptions, setRegionOptions] = useState([]);
    const { currentResources } = useAppSelector((state) => state.resources);

    const dispatch = useAppDispatch();
    const currentResource = useMemo(() => {
        return currentResources.find((res) => res.id === id);
    }, [id, currentResources]);

    useEffect(() => {
        let currentResource = currentResources.find((res) => res.id === id);
        let currentRegion = currentResource?.region;
        setRegionValue(currentRegion || "");
        if (currentRegion) {
            setPreference(true);
        }
    }, []);

    const onNext = () => {
        dispatch(
            setTabIndexById({
                id,
                tabIndex: 8,
            })
        );
        dispatch(
            setRegionById({
                id,
                region: preference ? regionValue : "",
            })
        );
    };

    const onPrevious = () => {
        dispatch(
            setTabIndexById({
                id,
                tabIndex: 6,
            })
        );
    };

    const fetchRegions = async () => {
        const res = await resourceService.getRegionList();

        setRegionOptions(res.data.message);
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResource,
                        region: preference ? regionValue : "",
                    },
                },
            })
        );
        dispatch(
            setRegionById({
                id,
                region: preference ? regionValue : "",
            })
        );
    }, [preference, regionValue]);

    useEffect(() => {
        fetchRegions();
    }, []);

    //on preference change
    const onPreferenceChange = (value: boolean) => {
        setPreference(value);
        if (regionValue) {
            dispatch(
                setRegionById({
                    id,
                    region: value ? regionValue : "",
                })
            );
        }
    };

    //on region change
    const onRegionValueChange = (value: string) => {
        setRegionValue(value);
        dispatch(
            setRegionById({
                id,
                region: preference ? regionValue : "",
            })
        );
    };

    return (
        <TabPanel index={7} value={index}>
            <div className={css.blockWrap}>
                <h1 className={css.title}>
                    Do you have any preferences on where the resource should be
                    from?
                </h1>
                <div className={css.selectContainer}>
                    <div className={css.cardContainer}>
                        <div
                            className={cn(css.card, {
                                [css.selectedCard]: preference,
                            })}
                            onClick={() => onPreferenceChange(true)}
                        >
                            Yes
                        </div>
                        <div
                            className={cn(css.card, {
                                [css.selectedCard]: !preference,
                            })}
                            onClick={() => onPreferenceChange(false)}
                        >
                            No
                        </div>
                    </div>
                    {preference && (
                        <>
                            <p className={css.subtitle}>
                                Which region should they be from?
                            </p>
                            <Select
                                fullWidth
                                variant="outlined"
                                IconComponent={(props) => (
                                    <Icon path={chevronIcon} {...props} />
                                )}
                                typeof="select"
                                value={regionValue}
                                onChange={(e) =>
                                    onRegionValueChange(
                                        e.target.value as string
                                    )
                                }
                            >
                                {regionOptions.map(
                                    (option: {
                                        region: string;
                                        cost: number;
                                    }) => {
                                        return (
                                            <MenuItem
                                                value={option.region}
                                                key={`location-option-${option.region}`}
                                            >
                                                {option.region}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </>
                    )}
                </div>
                <div className={css.experiencePriceBlock}>
                    <p className={css.title}>
                        ${" "}
                        {Number(currentResource?.computedPrice).toLocaleString(
                            undefined,
                            {
                                maximumFractionDigits: 1,
                            }
                        )}
                        <span className={css.subtext}>/ month</span>
                    </p>
                </div>
                {/* <div className={css.buttonFlex}>
                    <Button
                        color="secondary"
                        onClick={onPrevious}
                        sx={{
                            padding: "8px 34px",
                            display: "block",
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        color="primary"
                        onClick={onNext}
                        sx={{
                            padding: "8px 34px",
                            display: "block",
                        }}
                        disabled={preference && regionValue.length === 0}
                    >
                        Next
                    </Button>
                </div> */}
            </div>
        </TabPanel>
    );
};

export default MobileLocationTab;
