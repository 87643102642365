import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import VolumeResourcesTabDesktop from "./VolumeResourcesTabDesktop/VolumeResourcesTabDesktop";
import VolumeResourcesTabMobile from "./VolumeResourcesTabMobile/VolumeResourcesTabMobile";

interface TabProps {
    index: number;
}

const VolumeResourcesTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <VolumeResourcesTabDesktop index={index} />
    ) : (
        <VolumeResourcesTabMobile index={index} />
    );
};

export default VolumeResourcesTab;
