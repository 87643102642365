import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import DiscountOverviewTabDesktop from "./DiscountOverviewTabDesktop/DiscountOverviewTabDesktop";
import DiscountOverviewTabMobile from "./DiscountOverviewTabMobile/DiscountOverviewTabMobile";

interface TabProps {
    index: number;
}

const FrameAgreementTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    /* return width > MOBILE_SCREEN_WIDTH ? (
        <DiscountOverviewTabDesktop index={index} />
    ) : (
        <DiscountOverviewTabDesktop index={index} />
    ); */
    return <DiscountOverviewTabDesktop index={index} />;
};

export default FrameAgreementTab;
