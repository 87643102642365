import css from "./VolumeResourcesTabMobile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { setCurrentTab } from "../../../../../../features/Tabs/tabSlice";
import { Button } from "@mui/material";
import cn from "classnames";
import { useEffect, useState } from "react";
import { setFrameAgreement } from "../../../../../../features/Discounts/discountSlice";

interface TabProps {
    index: number;
}

const VolumeResourcesTabMobile: React.FC<TabProps> = ({ index }) => {
    const dispatch = useAppDispatch();
    const [frameAgreementVal, setFrameAgreementVal] = useState(false);
    const {
        budget,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const {
        total_discount,
        discounts: { frame_agreement_percentage_can_avail },
        discountPayload,
    } = useAppSelector((state) => state.discounts);

    const onGoBack = () => {
        dispatch(setCurrentTab(14));
    };

    const onNext = () => {
        dispatch(setCurrentTab(16));
    };

    const getMaxDiscount = () => {
        setFrameAgreementVal(true);
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_discount", {
                detail: {
                    total_amount: projectCost,
                    discount: {
                        ...discountPayload,
                        frame_agreement: frameAgreementVal ? "Yes" : "No",
                    },
                },
            })
        );
        dispatch(setFrameAgreement(frameAgreementVal ? "Yes" : "No"));
    }, [frameAgreementVal]);

    return (
        <>
            <div className={css.wrapper}>
                <div className={css.content}>
                    <h1 className={css.title}>Volume resources discounts</h1>
                    <p className={css.resultSubtext}>
                        Currently you have a total of 320 hours for all your
                        resources which makes you eligible for a 1% discount. To
                        get more discount you need to have at least 4,000 hours,
                        making you eligible for a discount of 2%.
                    </p>
                    <div className={css.resultConatiner}>
                        <div className={css.result}>
                            <p className={css.resultSubtext}>
                                Your total hours
                            </p>
                            <p>
                                <span className={css.hours}>
                                    {Number(320).toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}{" "}
                                </span>
                                <span className={css.resultSubtext}>
                                    {" "}
                                    /hours
                                </span>
                            </p>
                        </div>
                        <div className={css.result}>
                            <p className={css.resultSubtext}>You will save</p>
                            <p>
                                <span className={css.resultGreen}>
                                    ${" "}
                                    {Number(total_discount).toLocaleString(
                                        undefined,
                                        {
                                            maximumFractionDigits: 0,
                                        }
                                    )}{" "}
                                </span>
                                <span className={css.resultSubtext}>
                                    {" "}
                                    /month
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            padding: "12px",
                        }}
                        fullWidth
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </div>
                <div className={css.botMobileContainer}>
                    <BotMobile />
                </div> */}
            </div>
        </>
    );
};

export default VolumeResourcesTabMobile;
