import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import MonthlyPercentTabMobile from "./MonthlyPercentTabMobile/MonthlyPercentTabMobile";
import MonthlyPercentTabDesktop from "./MonthlyPercentTabDesktop/MonthlyPercentTabDesktop";

interface TabProps {
    index: number;
}

const MonthlyPercentTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <MonthlyPercentTabDesktop index={index} />
    ) : (
        <MonthlyPercentTabMobile index={index} />
    );
};

export default MonthlyPercentTab;
