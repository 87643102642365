import { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import css from "./DiscountOverviewTabDesktop.module.scss";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    setCurrentTab,
    reset as resetTabs,
} from "../../../../../features/Tabs/tabSlice";
import {
    copyIcon,
    chevronDownIcon,
    successIcon,
    mailIcon,
    saveIcon,
} from "../../../../../css/icons";
import Icon from "../../../../Icon/Icon";
import cn from "classnames";
import { Button, TextField } from "@mui/material";
import Input from "../../../../Input/Input";
import ModalBase from "../../../../Modals/ModalBase";
import ResourceService from "../../../../../services/resource.service";
import { ENVIROMENT } from "../../../../../constants";
import TopNavigationBar from "../../../../TopNavigationBar/TopNavigationBar";
import DoughnutChart from "./DoughnutChart/DoughnutChart";
import ResourseCard from "./ResourseCard/ResourseCard";
import GanttComponent from "../../../AddResourceTabs/TimelineTab/GanttComponent";
import MonthlyPercentTabDesktop from "../../MonthlyPercentTab/MonthlyPercentTabDesktop/MonthlyPercentTabDesktop";
import UseCreditCardTabDesktop from "../../UseCreditCardTab/UseCreditCardTabDesktop/UseCreditCardTabDesktop";
import InvoiceTermsTabDesktop from "../../InvoiceTermsTab/InvoiceTermsTabDesktop/InvoiceTermsTabDesktop";
import FirstTenPercentTabDesktop from "../../FirstTenPercentTab/FirstTenPercentTabDesktop/FirstTenPercentTabDesktop";
import FrameAgreementTabDesktop from "../../FrameAgreementTab/FrameAgreementTabDesktop/FrameAgreementTabDesktop";
import {
    reset as resetResources,
    setName,
} from "../../../../../features/Resources/resourceSlice";
import { reset as resetDiscounts } from "../../../../../features/Discounts/discountSlice";
import inputCss from "../../../../Input/Input.module.scss";
import {
    calculateWeeksDifference,
    formatDate,
    getExperienceLabel,
} from "../../../../../utils/helpers";

interface TabProps {
    index: number;
}
interface DiscountCard {
    title: string;
    percent: number;
    saving: number;
    heightDiscount: boolean;
}

const resourceService = new ResourceService();

const DiscountOverviewTabDesktop: React.FC<TabProps> = ({ index }) => {
    const { currentTab } = useAppSelector((state) => state.tabs);
    const {
        resources,
        name,
        budget,
        duration,
        currency,
        totals: { monthlyCost, projectCost, totalHours },
    } = useAppSelector((state) => state.resources);
    const currentResources = useAppSelector(
        (state) => state.resources.currentResources
    );
    const { total_discount, discounts, discountPayload } = useAppSelector(
        (state) => state.discounts
    );
    const [isOpen, setIsOpen] = useState(false);

    const [isQuotaOpen, setIsQuotaOpen] = useState(false);

    const [isCongratulationsOpen, setIsCongratulationsOpen] = useState(false);

    const [isDiscountDone, setIsDiscountDone] = useState(false);
    const [isDiscountOpen, setIsDiscountOpen] = useState(false);
    const [selectedDiscount, setSelectedDiscount] = useState(0);

    const [modalTitle, setModalTitle] = useState("");
    const [contactNameVal, setContactNameVal] = useState("");
    const [contactEmailVal, setContactEmailVal] = useState("");
    const [contactCompanyVal, setContactCompanyVal] = useState("");
    const [messageVal, setMessageVal] = useState("");

    const [quotaURL, setQuotaURL] = useState("");
    const [emailVal, setEmailVal] = useState("");

    const [activeTap, setActiveTap] = useState(0);
    const [discountArray, setDiscountArray] = useState<DiscountCard[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openAnchor = Boolean(anchorEl);

    const [openDisclaimer, setOpenDisclaimer] = useState(false);
    const [copied, setCopied] = useState(false);

    const [path, setPath] = useState("");
    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    const dispatch = useAppDispatch();

    const onBack = () => {
        dispatch(setCurrentTab(16));
    };

    const onSaveAsQuota = async () => {
        if (name && emailVal.length > 0) {
            const res = await resourceService.sendUrlToEmail(
                name,
                emailVal,
                quotaURL
            );
            setIsOpen(false);
            setEmailVal("");
        }
    };

    const onSendProposal = () => {
        if (name) {
            resourceService.sendMessageToSupport(
                name,
                contactEmailVal,
                messageVal,
                contactNameVal,
                contactCompanyVal
            );
        }
        setIsQuotaOpen(false);
        setIsCongratulationsOpen(true);
    };

    useEffect(() => {
        setDiscountArray([
            {
                //payment_duration_availed_percentage
                //payment_duration_percentage_can_avail
                title: "Pay Bi-monthly on Credit Card",
                percent: discounts?.payment_duration_percentage_can_avail,
                saving:
                    (discounts?.payment_duration_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                heightDiscount:
                    discountPayload.credit_card === "Yes" &&
                    discountPayload.credit_card_duration === "Weekly",
            },
            {
                //credit_card_percentage_can_avail
                //credit_card_availed_percentage
                title: "75% Payment via Credit Card monthly",
                percent: discounts?.credit_card_percentage_can_avail,
                saving:
                    (discounts?.credit_card_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                heightDiscount:
                    discountPayload.credit_card_payment_percent_per_month ===
                    "100%",
            },
            {
                //invoice_payment_terms_availed_percentage
                //invoice_payment_terms_percentage_can_avail
                title: "30 day Invoice Payment Terms",
                percent: discounts?.invoice_payment_terms_percentage_can_avail,
                saving:
                    (discounts?.invoice_payment_terms_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                heightDiscount:
                    discountPayload.invoice_payment_terms === "60 days" ||
                    discountPayload.invoice_payment_terms === "45 days",
            },
            {
                //upfront_payment_availed_percentage
                //upfront_payment_percentage_can_avail
                title: "Pay 20% amount in first 10% of the project",
                percent: discounts?.upfront_payment_percentage_can_avail,
                saving:
                    (discounts?.upfront_payment_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                heightDiscount: discountPayload.upfront_payment === "100%",
            },
            {
                //frame_agreement_availed_percentage
                //frame_agreement_percentage_can_avail
                title: "Provide + 3yrs agreement",
                percent: discounts?.frame_agreement_percentage_can_avail,
                saving:
                    (discounts?.frame_agreement_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                heightDiscount: discountPayload.frame_agreement === "Yes",
            },
        ]);
    }, [discounts, discountPayload]);

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            {...props}
        />
    ))(({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: 12,
            marginTop: theme.spacing(2),
            minWidth: 180,
            color:
                theme.palette.mode === "light"
                    ? "rgb(55, 65, 81)"
                    : theme.palette.grey[300],
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                padding: "4px 0",
            },
            "& .MuiMenuItem-root": {
                "& .MuiSvgIcon-root": {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                "&:active": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    }));

    const [tasks, setTasks] = useState<any[]>([]);
    useEffect(() => {
        setTasks(
            resources.map((resource) => {
                return {
                    id: resource.id,
                    name: resource.title,
                    start: resource.timeline?.startWeek,
                    end: resource.timeline?.endWeek,
                    progress: 100,
                    dependencies: "",
                    color: resource.color,
                };
            })
        );
    }, [resources]);

    useEffect(() => {
        console.log("Mobile name", name);
        if (name && isOpen) {
            resourceService.saveAsQuota(name).then((res) => {
                setQuotaURL(
                    res.data.message[
                        ENVIROMENT === "STAGE"
                            ? "staging_url"
                            : "production_url"
                    ]
                );
            });
        } else if (path && path.length > 0 && isOpen) {
            resourceService.saveAsQuota(path).then((res) => {
                setQuotaURL(
                    res.data.message[
                        ENVIROMENT === "STAGE"
                            ? "staging_url"
                            : "production_url"
                    ]
                );
            });
        }
    }, [isOpen]);

    return (
        <TabPanel index={17} value={index}>
            <TopNavigationBar title="Overview" tabNumber={11} />
            <div className={css.wrapper}>
                <ModalBase
                    title=""
                    open={isCongratulationsOpen}
                    onClose={() => setIsCongratulationsOpen(false)}
                    hasCloseButton={false}
                >
                    <div className={css.customModal}>
                        <Icon path={successIcon} />
                        <h2>Congratulations!</h2>
                        <p className={css.modalText}>
                            You’ve successfully submitted a request for quota.
                            Our team will reach out to you shortly.
                        </p>
                        <div className={css.footerBtns}>
                            <Button
                                color="primary"
                                onClick={() => setIsCongratulationsOpen(false)}
                            >
                                Close
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => {
                                    dispatch(setCurrentTab(0));
                                    dispatch(resetDiscounts());
                                    dispatch(resetResources());
                                    dispatch(resetTabs());
                                }}
                            >
                                Plan another project
                            </Button>
                        </div>
                    </div>
                </ModalBase>
                <ModalBase
                    title=""
                    open={openDisclaimer}
                    onClose={() => setOpenDisclaimer(false)}
                    hasCloseButton={false}
                >
                    <div className={css.customModal}>
                        <h2>Disclaimer</h2>
                        <p className={css.modalText}>
                            Please note that the estimated cost is subject to
                            change until full details are available.
                        </p>
                        <Button
                            color="primary"
                            fullWidth
                            onClick={() => {
                                setModalTitle("Request a Quota");
                                setIsQuotaOpen(true);
                                setOpenDisclaimer(false);
                            }}
                        >
                            Send Proposal
                        </Button>
                    </div>
                </ModalBase>
                <ModalBase
                    open={isQuotaOpen}
                    onClose={() => setIsQuotaOpen(false)}
                    title={modalTitle}
                    footer={
                        <div className={css.footerBtns}>
                            <Button
                                color="secondary"
                                sx={{
                                    margin: "16px 0",
                                }}
                                onClick={() => setIsQuotaOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                sx={{
                                    margin: "16px 0",
                                }}
                                onClick={() => {
                                    if (
                                        contactNameVal.length > 5 &&
                                        contactEmailVal.length > 5 &&
                                        contactCompanyVal.length > 5 &&
                                        messageVal.length > 5
                                    ) {
                                        onSendProposal();
                                    }
                                }}
                            >
                                Send
                            </Button>
                        </div>
                    }
                >
                    <div className={css.modalContent}>
                        <form className={css.fromWrapper}>
                            <Input
                                fieldName="name"
                                placeholder="Enter your name"
                                label="Name"
                                type="text"
                                value={contactNameVal}
                                onChange={(e) => {
                                    setContactNameVal(e.target.value);
                                }}
                            />
                            <Input
                                fieldName="email"
                                placeholder="Enter your email"
                                label="Email"
                                type="text"
                                value={contactEmailVal}
                                onChange={(e) => {
                                    setContactEmailVal(e.target.value);
                                }}
                            />
                            <Input
                                fieldName="email"
                                placeholder="Enter your company name"
                                label="Company Name"
                                type="text"
                                value={contactCompanyVal}
                                onChange={(e) => {
                                    setContactCompanyVal(e.target.value);
                                }}
                            />
                            <div className={css.textarea}>
                                <span className={inputCss.label}>Message</span>
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    placeholder="Enter your message"
                                    value={messageVal}
                                    onChange={(e) => {
                                        setMessageVal(e.target.value);
                                    }}
                                />
                            </div>
                            <p className={css.note}>
                                Note: Your requirements will automatically be
                                attached with this message
                            </p>
                        </form>
                    </div>
                </ModalBase>
                <ModalBase
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    title="Save as Quota"
                    footer={
                        <div className={css.footerBtns}>
                            <Button
                                color="secondary"
                                sx={{
                                    margin: "16px 0",
                                }}
                                onClick={() => setIsOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                sx={{
                                    margin: "16px 0",
                                }}
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                Done
                            </Button>
                        </div>
                    }
                >
                    <div className={css.modalContent}>
                        <div className={css.sendMail}>
                            <Input
                                fieldName="email"
                                placeholder="Enter your email"
                                label="Enter your email to send it"
                                type="text"
                                value={emailVal}
                                onChange={(e: any) => {
                                    setEmailVal(e.target.value);
                                }}
                            />
                            <Button
                                color="primary"
                                sx={{
                                    width: 144,
                                    height: 48,
                                }}
                                onClick={onSaveAsQuota}
                            >
                                Send Now
                            </Button>
                        </div>
                        <p className={css.modalOr}></p>
                        <Input
                            fieldName="link"
                            placeholder="Link..."
                            label="Save as Link"
                            type="text"
                            value={quotaURL}
                            disabled
                            postfix={
                                copied ? (
                                    <span className={css.copied}>copied</span>
                                ) : (
                                    <button
                                        type="button"
                                        className={css.unstyledBtn}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                quotaURL
                                            );
                                            setCopied(true);
                                            setTimeout(() => {
                                                setCopied(false);
                                            }, 2000);
                                        }}
                                    >
                                        <Icon path={copyIcon} />
                                    </button>
                                )
                            }
                        />
                    </div>
                </ModalBase>
                <ModalBase
                    open={isDiscountOpen}
                    onClose={() => setIsDiscountOpen(false)}
                    title={discountArray[selectedDiscount]?.title}
                    footer={
                        <div className={css.footerBtns}>
                            <Button
                                color="secondary"
                                sx={{
                                    margin: "16px 0",
                                }}
                                onClick={() => setIsDiscountOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                sx={{
                                    margin: "16px 0",
                                }}
                                onClick={() => {
                                    setIsDiscountDone(true);
                                    const resource_id =
                                        name && name.length > 0
                                            ? name
                                            : path && path.length > 0
                                            ? path
                                            : "";
                                    resourceService
                                        .addResource(
                                            resource_id,
                                            currency || "USD",
                                            budget || 10000,
                                            duration || 8,
                                            currentTab,
                                            resources.map((res) => ({
                                                role: res.role,
                                                number: res.count,
                                                experience: getExperienceLabel(
                                                    res.experience
                                                ),
                                                duration:
                                                    calculateWeeksDifference(
                                                        res.timeline?.startWeek,
                                                        res.timeline?.endWeek
                                                    ),
                                                workload: res.weeklyWorkload,
                                                start_week: 0,
                                                end_week: 4,

                                                title: res.title,
                                                work_format: res.workFormat,
                                                work_location: res.location,
                                                resource_location: res.region,
                                                start_week_date: formatDate(
                                                    res.timeline?.startWeek
                                                ),
                                                end_week_date: formatDate(
                                                    res.timeline?.endWeek
                                                ),
                                            })),
                                            discountPayload.credit_card,
                                            discountPayload.credit_card_duration,
                                            discountPayload.frame_agreement,
                                            discountPayload.upfront_payment,
                                            discountPayload.credit_card_payment_percent_per_month
                                        )
                                        .then((res) => {
                                            dispatch(
                                                setName(res.data.message.name)
                                            );
                                        });
                                }}
                            >
                                Done
                            </Button>
                        </div>
                    }
                >
                    <div className={css.modalContent}>
                        {selectedDiscount === 0 && (
                            <UseCreditCardTabDesktop
                                index={index}
                                show={isDiscountOpen}
                                done={isDiscountDone}
                                setIsDiscountOpen={setIsDiscountOpen}
                            />
                        )}
                        {selectedDiscount === 1 && (
                            <MonthlyPercentTabDesktop
                                index={index}
                                show={isDiscountOpen}
                                done={isDiscountDone}
                                setIsDiscountOpen={setIsDiscountOpen}
                            />
                        )}
                        {selectedDiscount === 2 && (
                            <InvoiceTermsTabDesktop
                                index={index}
                                show={isDiscountOpen}
                                done={isDiscountDone}
                                setIsDiscountOpen={setIsDiscountOpen}
                            />
                        )}
                        {selectedDiscount === 3 && (
                            <FirstTenPercentTabDesktop
                                index={index}
                                show={isDiscountOpen}
                                done={isDiscountDone}
                                setIsDiscountOpen={setIsDiscountOpen}
                            />
                        )}
                        {selectedDiscount === 4 && (
                            <FrameAgreementTabDesktop
                                index={index}
                                show={isDiscountOpen}
                                done={isDiscountDone}
                                setIsDiscountOpen={setIsDiscountOpen}
                            />
                        )}
                    </div>
                </ModalBase>
                <div className={css.innerContainer}>
                    <div className={css.leftSection}>
                        <h2 className={css.title}>Overview</h2>
                        <DoughnutChart
                            resources={currentResources}
                            projectCost={projectCost - total_discount}
                            budget={budget}
                        />
                    </div>
                    <div className={css.rightSection}>
                        <div className={css.tabsContainer}>
                            <div
                                className={cn(css.tab, {
                                    [css.selected]: activeTap === 0,
                                })}
                                onClick={() => setActiveTap(0)}
                            >
                                Resources
                            </div>
                            <div
                                className={cn(css.tab, {
                                    [css.selected]: activeTap === 1,
                                })}
                                onClick={() => setActiveTap(1)}
                            >
                                Timeline
                            </div>
                            <div
                                className={cn(css.tab, {
                                    [css.selected]: activeTap === 2,
                                })}
                                onClick={() => setActiveTap(2)}
                            >
                                Discounts
                            </div>
                        </div>
                        <div
                            className={cn(css.tabContent, {
                                [css.selected]: activeTap === 0,
                            })}
                        >
                            <div className={css.resources}>
                                {resources.map((resource, index) => {
                                    return (
                                        <ResourseCard
                                            key={index}
                                            resource={resource}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div
                            className={cn(css.tabContent, {
                                [css.selected]: activeTap === 1,
                            })}
                        >
                            <div className={css.timeline}>
                                {tasks.length > 0 && (
                                    <GanttComponent
                                        tasks={tasks}
                                        handleDateChange={() => {}}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={cn(css.tabContent, {
                                [css.selected]: activeTap === 2,
                            })}
                        >
                            <div className={css.discount}>
                                <div className={css.titleOverall}>
                                    <p>You’re Saving</p>
                                    <h5>
                                        $
                                        {Number(
                                            Math.abs(total_discount)
                                        ).toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}
                                        <span> / month</span>
                                    </h5>
                                </div>

                                <div className={css.discountCards}>
                                    {discountArray.map((discount, index) => {
                                        return (
                                            <div
                                                className={css.card}
                                                key={
                                                    discount.percent +
                                                    discount.saving +
                                                    discount.title +
                                                    index
                                                }
                                            >
                                                <div>
                                                    <p className={css.title}>
                                                        {discount.title}
                                                    </p>
                                                    <small>
                                                        30 days - Save{" "}
                                                        {discount.percent}%
                                                    </small>
                                                </div>
                                                <div className={css.bottom}>
                                                    <p className={css.green}>
                                                        -$
                                                        {discount.saving.toLocaleString(
                                                            undefined,
                                                            {
                                                                maximumFractionDigits: 0,
                                                            }
                                                        )}
                                                        <span> /month</span>
                                                    </p>
                                                    {!discount.heightDiscount && (
                                                        <span
                                                            className={
                                                                css.saveMore
                                                            }
                                                            onClick={() => {
                                                                setSelectedDiscount(
                                                                    index
                                                                );
                                                                setIsDiscountDone(
                                                                    false
                                                                );
                                                                setIsDiscountOpen(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            save more
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`${css.btnContainer} ${css.btnContainerDesktop}`}
                >
                    <div>
                        <Button
                            color="secondary"
                            id="basic-button"
                            aria-controls={
                                openAnchor ? "basic-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openAnchor ? "true" : undefined}
                            onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                setAnchorEl(event.currentTarget);
                            }}
                        >
                            Contact <Icon path={chevronDownIcon} />
                        </Button>
                        <StyledMenu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openAnchor}
                            onClose={() => {
                                setAnchorEl(null);
                            }}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setModalTitle("Send Us an Email");
                                    setIsQuotaOpen(true);
                                    setAnchorEl(null);
                                }}
                            >
                                Send Email
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setAnchorEl(null);
                                }}
                            >
                                Book a Meeting
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setAnchorEl(null);
                                }}
                            >
                                WhatsApp
                            </MenuItem>
                        </StyledMenu>
                    </div>
                    <Button color="secondary" onClick={() => setIsOpen(true)}>
                        Save
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            setOpenDisclaimer(true);
                        }}
                    >
                        Request a Quota
                    </Button>
                </div>

                <div className={css.btnContainerMobile}>
                    <Button
                        color="secondary"
                        id="basic-button"
                        aria-controls={openAnchor ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openAnchor ? "true" : undefined}
                        onClick={() => {
                            setModalTitle("Send Us an Email");
                            setIsQuotaOpen(true);
                            setAnchorEl(null);
                        }}
                    >
                        <Icon path={mailIcon} />
                    </Button>
                    <Button color="secondary" onClick={() => setIsOpen(true)}>
                        <Icon path={saveIcon} />
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            setOpenDisclaimer(true);
                        }}
                    >
                        Send a Proposal
                    </Button>
                </div>
            </div>
        </TabPanel>
    );
};

export default DiscountOverviewTabDesktop;
