import { createSlice } from "@reduxjs/toolkit";

interface InitialStateType {
    currentTab: number;
    currentResourceIndex: number;
    isBotMobiltVisible: boolean;
}

const initialState: InitialStateType = {
    currentTab: 0,
    currentResourceIndex: 0,
    isBotMobiltVisible: false,
};

const tabSlice = createSlice({
    name: "tabSlice",
    initialState,
    reducers: {
        setCurrentTab(state, action) {
            state.currentTab = action.payload;
        },
        setCurrentResourceIndex(state, action) {
            state.currentResourceIndex = action.payload;
        },
        setIsBotMobileVisible(state, action) {
            state.isBotMobiltVisible = action.payload;
        },
        reset(state) {
            state = initialState;
        },
    },
});

export const {
    setCurrentTab,
    setCurrentResourceIndex,
    setIsBotMobileVisible,
    reset,
} = tabSlice.actions;

export default tabSlice;
