import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import tabSlice from "../features/Tabs/tabSlice";
import resourceSlice from "../features/Resources/resourceSlice";
import discountSlice from "../features/Discounts/discountSlice";

export const store = configureStore({
    reducer: {
        tabs: tabSlice.reducer,
        resources: resourceSlice.reducer,
        discounts: discountSlice.reducer,
    },
    middleware: (getDefaultMiddleWare) =>
        getDefaultMiddleWare({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
