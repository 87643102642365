import css from "./ResourcePreviewTab.module.scss";
import { Button } from "@mui/material";
import ResourcesCarousel from "../../../ResourcesCarousel/ResourcesCarousel";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import TabPanel from "../../TabPanel";
import Icon from "../../../Icon/Icon";
import { youpalLogo } from "../../../../css/icons";
import { Grey } from "../../../../mui/colors";
import CloseButton from "../../../CloseButton/CloseButton";
import {
    completeResources,
    computeProjectTotals,
} from "../../../../features/Resources/resourceSlice";
import { useMemo, useState } from "react";
import ModalBase from "../../../Modals/ModalBase";
import { formatAmountDifferenceWithSign } from "../../../../utils/helpers";

interface TabProps {
    index: number;
}

const ResourcePreviewTab: React.FC<TabProps> = ({ index }) => {
    const [open, setOpen] = useState(false);
    const { currentResources, duration } = useAppSelector(
        (state) => state.resources
    );
    const { currentResourceIndex } = useAppSelector((state) => state.tabs);

    const currentResource = useMemo(() => {
        return currentResources[currentResourceIndex];
    }, [currentResourceIndex, currentResources]);

    const dispatch = useAppDispatch();

    const onPrevious = () => {
        dispatch(setCurrentTab(8));
    };

    const onDone = () => {
        const isAllResourcesDone = currentResources.every(
            (res) => res.tabIndex === 9
        );

        if (isAllResourcesDone) {
            dispatch(setCurrentTab(10));
            dispatch(completeResources());
            dispatch(computeProjectTotals());
        } else {
            setOpen(true);
        }
    };

    return (
        <TabPanel index={9} value={index}>
            <ModalBase
                onClose={() => setOpen(false)}
                open={open}
                title="Are you sure you want to proceed?"
            >
                <p>You haven't added details for other talents.</p>
                <div className={cn(css.flexSB, css.modalBtns)}>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => setOpen(false)}
                    >
                        Add Details
                    </Button>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => {
                            dispatch(setCurrentTab(10));
                            dispatch(completeResources());
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </ModalBase>
            <div className={css.wrapper}>
                <div className={css.header}>
                    <div className={css.logoFlex}>
                        <CloseButton />
                        <Icon path={youpalLogo} />
                    </div>
                    <Button
                        color="primary"
                        onClick={onDone}
                        sx={{
                            padding: "6px 32px",
                            borderRadius: "6px",
                            backgroundColor: Grey[900],
                            "&:hover": {
                                backgroundColor: Grey[900],
                            },
                        }}
                    >
                        {currentResources.every((res) => res.tabIndex === 9)
                            ? "Confirm All Resources"
                            : "Done"}
                    </Button>
                </div>
                <div className={css.resourceCarouselWrapper}>
                    <ResourcesCarousel resources={currentResources} />
                </div>
                <div>
                    <div className={cn(css.flexSB, css.resourceTitle)}>
                        <h1 className={css.title}>
                            {currentResource?.count}x {currentResource?.title}
                        </h1>
                        {/* <span className={css.orange}>
                            83 candidates available
                        </span> */}
                    </div>

                    <div className={css.cardContainer}>
                        <div className={css.card}>
                            <span>
                                <b>
                                    {currentResource?.experience > 1
                                        ? currentResource?.experience + " years"
                                        : currentResource?.experience +
                                          " year"}{" "}
                                </b>{" "}
                                of experience
                            </span>
                            <span className={css.boldOrange}>
                                {formatAmountDifferenceWithSign(
                                    currentResource?.costDetails
                                        ?.experience_cost
                                )}
                            </span>
                        </div>
                        <div className={css.card}>
                            <span>
                                {currentResource?.workFormat === "on-site" ? (
                                    <>
                                        <b>On-site</b> in{" "}
                                        {currentResource.location}
                                    </>
                                ) : (
                                    <div>Remote</div>
                                )}
                            </span>
                            <span className={css.boldOrange}>
                                {formatAmountDifferenceWithSign(
                                    currentResource?.costDetails
                                        ?.work_format_cost
                                )}
                            </span>
                        </div>
                        <div className={css.card}>
                            <span>
                                Resource from <b>{currentResource?.region}</b>
                            </span>
                            <span className={css.boldOrange}>
                                {formatAmountDifferenceWithSign(
                                    currentResource?.costDetails
                                        ?.resource_region_cost
                                )}
                            </span>
                        </div>
                        <div className={css.card}>
                            <span>
                                <b>
                                    {currentResource?.weeklyWorkload * 8} hours
                                </b>{" "}
                                per week
                            </span>
                            <span className={css.boldOrange}>
                                {formatAmountDifferenceWithSign(
                                    Math.abs(
                                        currentResource?.costDetails
                                            ?.days_per_week_cost
                                    )
                                )}
                            </span>
                        </div>
                        <div className={css.card}>
                            Work from{" "}
                            {/* <span>
                                <b>
                                    Week {currentResource?.timeline?.startWeek}
                                </b>{" "}
                                to
                                <b>
                                    {" "}
                                    Week {currentResource?.timeline?.endWeek}
                                </b>
                            </span> */}
                        </div>
                    </div>

                    <div className={css.timelineWrapper}>
                        <p className={css.subtitle}>Timeline (in weeks)</p>
                        <div>
                            <div className={css.timelineContainer}>
                                {/* <div
                                    className={css.timeline}
                                    style={{
                                        left:
                                            (currentResource?.timeline
                                                ?.startWeek /
                                                ((duration || 1) * 4)) *
                                                100 +
                                            "%",
                                        right:
                                            (1 -
                                                currentResource?.timeline
                                                    ?.endWeek /
                                                    ((duration || 1) * 4)) *
                                                100 +
                                            "%",
                                    }}
                                ></div> */}
                            </div>
                            <div className={css.timelineNumbers}>
                                {new Array((duration || 1) * 4 + 1)
                                    .fill(0)
                                    .map((num, index) => index)
                                    .filter((num) => num % 2 === 0)
                                    .map((num) => (
                                        <span key={"timeline-number-" + num}>
                                            {num}
                                        </span>
                                    ))}
                            </div>
                        </div>
                    </div>

                    <div className={css.totalResults}>
                        <div className={css.flexSB}>
                            <span className={css.subtext}>Total Hours</span>
                            <span className={css.resultBold}>
                                {
                                    currentResource?.costDetails
                                        ?.total_project_hours
                                }{" "}
                                <span className={css.subtext}>hours</span>
                            </span>
                        </div>
                        <div className={css.flexSB}>
                            <span className={css.subtext}>
                                Talent Monthly Cost
                            </span>
                            <span className={css.resultBold}>
                                $
                                {Number(
                                    currentResource?.costDetails
                                        ?.talent_monthly_cost
                                ).toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                })}{" "}
                                <span className={css.subtext}>/month</span>
                            </span>
                        </div>
                        <div className={css.flexSB}>
                            <span className={css.subtext}>
                                Accomodation Cost
                            </span>
                            <span className={css.resultBold}>
                                $
                                {Number(
                                    currentResource?.costDetails
                                        ?.monthly_accomodation_cost
                                ).toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                })}{" "}
                                <span className={css.subtext}>/month</span>
                            </span>
                        </div>
                        <div className={css.flexSB}>
                            <span className={css.subtext}>
                                Total Monthly Cost
                            </span>
                            <span className={css.resultBold}>
                                $
                                {Number(
                                    currentResource?.costDetails
                                        ?.total_monthly_cost
                                ).toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                })}{" "}
                                <span className={css.subtext}>/project</span>
                            </span>
                        </div>
                        <div className={css.flexSB}>
                            <span className={css.subtext}>
                                Total Project Cost
                            </span>
                            <span className={css.resultBold}>
                                $
                                {Number(
                                    currentResource?.costDetails
                                        ?.total_project_cost
                                ).toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                })}{" "}
                                <span className={css.subtext}>/project</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={css.buttonFlex}>
                        <Button
                            color="secondary"
                            onClick={onPrevious}
                            sx={{
                                padding: "8px 70px",
                                display: "block",
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            color="primary"
                            onClick={onDone}
                            sx={{
                                padding: "8px 70px",
                                display: "block",
                            }}
                        >
                            {currentResources.every((res) => res.tabIndex === 9)
                                ? "Confirm All Resources"
                                : "Add Resource"}
                        </Button>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default ResourcePreviewTab;
