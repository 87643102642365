import { useState } from "react";
import css from "./ContactSupportTabMobile.module.scss";
import inputCss from "../../../../Input/Input.module.scss";
import TabPanel from "../../../TabPanel";
import { Button, TextField } from "@mui/material";
import Input from "../../../../Input/Input";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import Icon from "../../../../Icon/Icon";
import { arrowLeftLargeIcon } from "../../../../../css/icons";
import ModalBase from "../../../../Modals/ModalBase";
import ResourceService from "../../../../../services/resource.service";

interface TabProps {
    index: number;
}

const resourceService = new ResourceService();

const ContactSupportTabMobile: React.FC<TabProps> = ({ index }) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [emailVal, setEmailVal] = useState("");
    const [messageVal, setMessageVal] = useState("");
    const { name } = useAppSelector((state) => state.resources);

    const onBack = () => {
        dispatch(setCurrentTab(17));
    };
    const openDisclaimerModal = () => {
        setOpen(true);
    };

    const onSendProposal = () => {
        dispatch(setCurrentTab(19));
        if (name) {
            resourceService.sendMessageToSupport(name, emailVal, messageVal);
        }
    };

    return (
        <TabPanel index={18} value={index}>
            <div>
                <ModalBase
                    title="Disclaimer"
                    open={open}
                    onClose={() => setOpen(false)}
                    hasCloseButton={false}
                    footer={
                        <div>
                            <Button
                                color="primary"
                                fullWidth
                                onClick={onSendProposal}
                            >
                                Send Proposal
                            </Button>
                        </div>
                    }
                >
                    <p className={css.modalText}>
                        Please note that the estimated cost is subject to change
                        until full details are available.
                    </p>
                </ModalBase>
                <div className={css.header}>
                    <button className={css.unstyledBtn} onClick={onBack}>
                        <Icon path={arrowLeftLargeIcon} />
                    </button>
                    <p className={css.titleOpacity}>Contact Support</p>
                </div>
                <form className={css.fromWrapper}>
                    <Input
                        fieldName="email"
                        placeholder="Enter your email"
                        label="Email"
                        type="text"
                        value={emailVal}
                        onChange={(e) => {
                            setEmailVal(e.target.value);
                        }}
                    />
                    <div className={css.textarea}>
                        <span className={inputCss.label}>Message</span>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            placeholder="Enter your message"
                            value={messageVal}
                            onChange={(e) => {
                                setMessageVal(e.target.value);
                            }}
                        />
                    </div>
                    <p className={css.note}>
                        Note: Your requirements will automatically be attached
                        with this message
                    </p>
                    <div className={css.btnContainer}>
                        <Button
                            color="primary"
                            sx={{
                                padding: "12px",
                            }}
                            fullWidth
                            onClick={openDisclaimerModal}
                            type="button"
                        >
                            Send
                        </Button>
                    </div>
                </form>
            </div>
        </TabPanel>
    );
};

export default ContactSupportTabMobile;
