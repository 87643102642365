import React, { useState, useEffect } from "react";
import cn from "classnames";
import css from "./UserCard.module.scss";
import Icon from "../../../../Icon/Icon";
import { vectorIcon, arrowUp, arrowDowan } from "../../../../../css/icons";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentResources } from "../../../../../features/Resources/resourceSlice";

interface UserCard {
    id: string;
    resourceIndex?: number;
}

const UserCard: React.FC<UserCard> = ({ id, resourceIndex }) => {
    const dispatch = useAppDispatch();
    const { currentTab } = useAppSelector((state) => state.tabs);
    const { currentResources, resources } = useAppSelector(
        (state) => state.resources
    );
    const [selectedResource, setSelectedResource] = useState<any>();
    const [index, setIndex] = useState<any>();

    useEffect(() => {
        const foundItem =
            currentTab === 17
                ? resources.find((item) => item.id === id)
                : currentResources.find((item) => item.id === id);
        setSelectedResource(foundItem);

        const index =
            currentTab === 17
                ? resources.findIndex((item) => item.id === id)
                : currentResources.findIndex((item) => item.id === id);
        setIndex(index);
    }, [id, currentTab, currentResources]);

    const moveItem = (index: number, direction: "up" | "down") => {
        if (
            (direction === "up" && index === 0) ||
            (direction === "down" && index === currentResources.length - 1)
        ) {
            // Can't move outside the array bounds
            return;
        }

        const newItems = [...currentResources]; // Create a copy of the array
        const targetIndex = direction === "up" ? index - 1 : index + 1;

        // Swap the elements
        [newItems[index], newItems[targetIndex]] = [
            newItems[targetIndex],
            newItems[index],
        ];

        dispatch(setCurrentResources([...newItems])); // Update the state with the new array
    };

    return (
        <div className={css.userCard}>
            <div className={css.userCardLeft}>
                {currentTab !== 17 && (
                    <div className={css.userButtons}>
                        <button
                            className={cn({
                                [css.disabled]: resourceIndex === 0,
                            })}
                            onClick={() => moveItem(index, "up")}
                        >
                            <Icon path={arrowUp} />
                        </button>
                        <span>move</span>
                        <button
                            className={cn({
                                [css.disabled]:
                                    resourceIndex ===
                                    currentResources.length - 1,
                            })}
                            onClick={() => moveItem(index, "down")}
                        >
                            <Icon path={arrowDowan} />
                        </button>
                    </div>
                )}
                <div className={css.userCard}>
                    <Icon path={vectorIcon} />
                    {currentTab !== 17 && (
                        <div className={css.userCardInfo}>
                            <p>
                                $
                                {Number(
                                    selectedResource?.computedPrice
                                ).toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                })}
                                <span className={css.perResourceText}>
                                    {" "}
                                    <span className={css.desktopText}>
                                        per resource
                                    </span>
                                    <span className={css.mobileText}>
                                        / resource
                                    </span>{" "}
                                </span>
                            </p>
                            <small>
                                {selectedResource?.count}{" "}
                                {selectedResource?.count === 1
                                    ? "resource"
                                    : "resources"}
                            </small>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserCard;
