import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import BudgetTabDesktop from "./BudgetTabDesktop/BudgetTabDesktop";
import BudgetTabMobile from "./BudgetTabMobile/BudgetTabMobile";

interface TabProps {
    index: number;
}

const BudgetTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    /* return width > MOBILE_SCREEN_WIDTH ? (
        <BudgetTabDesktop index={index} />
    ) : (
        <BudgetTabMobile index={index} />
    ); */

    return <BudgetTabDesktop index={index} />;
};

export default BudgetTab;
