import cn from "classnames";

import {
    blackDropdownIcon,
    dotsLargeIcon,
    arrowLeftWithBorder,
    arrowRightWithBorder,
    trashWithBorder,
    editWithBorder,
    chevronDownIcon,
    ExperienceIcon,
    WorktypeIcon,
    WorkLoadIcon,
    LocationIcon,
} from "../../css/icons";
import { Button } from "@mui/material";
import Icon from "../Icon/Icon";
import css from "./MobileResourceDropdown.module.scss";
import React, { useState, useMemo, useEffect } from "react";
import MobileExperienceTab from "../Tabs/MobileTabs/MobileExperienceTab/MobileExperienceTab";
import MobileWorkFormatTab from "../Tabs/MobileTabs/MobileWorkFormatTab/MobileWorkFormatTab";
import MobileWeeklyWorkloadTab from "../Tabs/MobileTabs/MobileWeeklyWorkloadTab/MobileWeeklyWorkloadTab";
import MobileLocationTab from "../Tabs/MobileTabs/MobileLocationTab/MobileLocationTab";
import MobileTimelineTab from "../Tabs/MobileTabs/MobileTimelineTab/MobileTimelineTab";
import MobileResourcePreviewTab from "../Tabs/MobileTabs/MobileResourcePreviewTab/MobileResourcePreviewTab";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SetResourceDetailsMobile from "./SetResourceDetailsMobile/SetResourceDetailsMobile";
import ContextMenu from "./ContextMenu/ContextMenu";
import {
    setCurrentResourceIndex,
    setCurrentTab,
} from "../../features/Tabs/tabSlice";
import ModalBase from "../Modals/ModalBase";
import {
    Resource,
    setCurrentResources,
    setResourceTabIndex,
} from "../../features/Resources/resourceSlice";

interface MobileResourceDropdownProps {
    title: string;
    count: number;
    tabIndex: number;
    id: string;
    index: number;
}

const MobileResourceDropdown: React.FC<MobileResourceDropdownProps> = ({
    title,
    count,
    tabIndex,
    index,
    id,
}) => {
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState("");

    const [pickerDrop, setpickerDrop] = useState(false);

    const { currentResources } = useAppSelector((state) => state.resources);
    const currentResourceIndex = useAppSelector(
        (state) => state.tabs.currentResourceIndex
    );

    const dispatch = useAppDispatch();

    const setResourceDetails = () => {
        setModalOpen("details");

        dispatch(
            setCurrentResourceIndex(
                currentResources.findIndex((resource) => resource.id === id)
            )
        );
    };

    const setContextMenu = () => {
        if (modalOpen === "context") {
            setModalOpen("");
        } else {
            setModalOpen("context");
        }

        dispatch(
            setCurrentResourceIndex(
                currentResources.findIndex((resource) => resource.id === id)
            )
        );
    };

    const resourceIndex = useMemo(
        () => currentResources.findIndex((resource) => resource.id === id),
        [currentResources]
    );

    const onDelete = () => {
        handleDelete();
    };

    const handleDelete = () => {
        const newResourceList = [...currentResources];
        newResourceList.splice(index, 1);
        dispatch(setCurrentResources(newResourceList));
        dispatch(
            newResourceList.length !== 0
                ? index !== 0
                    ? setCurrentResourceIndex(index - 1)
                    : setCurrentResourceIndex(0)
                : setCurrentTab(3)
        );
        setOpen(false);
    };

    //scroll to top on render
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div className={css.dropdownWrapper}>
            {modalOpen === "details" && (
                <SetResourceDetailsMobile
                    open={modalOpen === "details"}
                    setOpen={setModalOpen}
                    id={id}
                />
            )}
            {modalOpen === "context" &&
                currentResourceIndex ===
                    currentResources.findIndex(
                        (resource) => resource.id === id
                    ) && <ContextMenu id={id} setOpen={setModalOpen} />}

            <ModalBase
                title="Delete Resource?"
                onClose={() => setOpen(false)}
                footer={
                    <div className={css.modalButtons}>
                        <Button
                            color="secondary"
                            fullWidth
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={css.deleteButton}
                            fullWidth
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    </div>
                }
                open={open}
                hasCloseButton={false}
            >
                <p className={css.modalText}>
                    Are you sure you want to delete
                    <b>{/* {resources[currentResourceIndex]?.title} */}</b>{" "}
                    resource?
                </p>
            </ModalBase>

            <div className={css.singleResourceMobile}>
                <div className={css.singleResourceSubheader}>
                    <div className={css.singleResourceAmount}>
                        ${" "}
                        {Number(
                            currentResources[resourceIndex]?.computedPrice
                        ).toLocaleString(undefined, {
                            maximumFractionDigits: 1,
                        })}{" "}
                        <span>/month</span>
                    </div>
                    <div className={css.singleResourceMiscButtons}>
                        <button onClick={() => setOpen(true)}>
                            <Icon path={trashWithBorder} />
                        </button>

                        <button>
                            <Icon path={editWithBorder} />
                        </button>
                    </div>
                </div>
            </div>

            <div className={css.content}>
                <div className={css.customDropDownPicker}>
                    <div
                        className={css.selectedPickerItem}
                        onClick={() => setpickerDrop(!pickerDrop)}
                    >
                        <div className={css.pickerContainerLeft}>
                            <div className={css.pickerIcon}>
                                <Icon path={WorktypeIcon} />
                            </div>
                            <div className={css.pickerText}>
                                <h4>Experience</h4>
                                <div className={css.pickerAddons}>
                                    <span>Senior</span>
                                    <span className={css.green}>+$200</span>
                                    <small>/month</small>
                                </div>
                            </div>
                        </div>

                        <div className={css.pickerContainerRight}>
                            <Icon path={chevronDownIcon} />
                        </div>
                    </div>
                    {pickerDrop && (
                        <ul>
                            <li>
                                <div className={css.selectedPickerItem}>
                                    <a
                                        href="#experience"
                                        onClick={() =>
                                            setpickerDrop(!pickerDrop)
                                        }
                                    ></a>
                                    <div className={css.pickerContainerLeft}>
                                        <div className={css.pickerIcon}>
                                            <Icon path={WorktypeIcon} />
                                        </div>
                                        <div className={css.pickerText}>
                                            <h4>Experience</h4>
                                            <div className={css.pickerAddons}>
                                                <span>Senior</span>
                                                <span className={css.green}>
                                                    +$200
                                                </span>
                                                <small>/month</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={css.selectedPickerItem}>
                                    <a
                                        href="#workformat"
                                        onClick={() =>
                                            setpickerDrop(!pickerDrop)
                                        }
                                    ></a>
                                    <div className={css.pickerContainerLeft}>
                                        <div className={css.pickerIcon}>
                                            <Icon path={WorktypeIcon} />
                                        </div>
                                        <div className={css.pickerText}>
                                            <h4>Work Type</h4>
                                            <div className={css.pickerAddons}>
                                                <span>Remote</span>
                                                <span className={css.green}>
                                                    +$0
                                                </span>
                                                <small>/month</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={css.selectedPickerItem}>
                                    <a
                                        href="#workload"
                                        onClick={() =>
                                            setpickerDrop(!pickerDrop)
                                        }
                                    ></a>
                                    <div className={css.pickerContainerLeft}>
                                        <div className={css.pickerIcon}>
                                            <Icon path={WorktypeIcon} />
                                        </div>
                                        <div className={css.pickerText}>
                                            <h4>Work Load</h4>
                                            <div className={css.pickerAddons}>
                                                <span>40 Hrs</span>
                                                <span className={css.green}>
                                                    +$100
                                                </span>
                                                <small>/month</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={css.selectedPickerItem}>
                                    <a
                                        href="#location"
                                        onClick={() =>
                                            setpickerDrop(!pickerDrop)
                                        }
                                    ></a>
                                    <div className={css.pickerContainerLeft}>
                                        <div className={css.pickerIcon}>
                                            <Icon path={WorktypeIcon} />
                                        </div>
                                        <div className={css.pickerText}>
                                            <h4>Location</h4>
                                            <div className={css.pickerAddons}>
                                                <span>Default</span>
                                                <span className={css.green}>
                                                    +$0
                                                </span>
                                                <small>/month</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )}
                </div>

                <div id="experience">
                    <MobileExperienceTab index={4} id={id} />
                </div>
                <div id="workformat">
                    <MobileWorkFormatTab index={5} id={id} />
                </div>
                <div id="workload">
                    <MobileWeeklyWorkloadTab index={6} id={id} />
                </div>
                <div id="location">
                    <MobileLocationTab index={7} id={id} />
                </div>
            </div>

            {/* <div className={css.dropdownHeader}>
                <div className={css.dropdownHeaderFlexItemLeft}>
                    <button
                        className={cn(css.closeBtn, { [css.open]: !open })}
                        onClick={() => setOpen(!open)}
                    >
                        <Icon path={blackDropdownIcon} />
                    </button>
                    <div className={css.dropdownHeaderLeft}>
                        <span className={css.dropdownTitle}>{title}</span>
                        <span>
                            x{count}{" "}
                            {count > 1 && (
                                <button
                                    className={css.setDetailsBtn}
                                    onClick={setResourceDetails}
                                >
                                    Make Resources Unique
                                </button>
                            )}
                        </span>
                    </div>
                </div>
                <div className={css.dropdownHeaderFlexItemRight}>
                    <span>
                        <span className={css.dropdownTitle}>
                            ${" "}
                            {Number(
                                currentResources[resourceIndex]?.computedPrice
                            ).toLocaleString(undefined, {
                                maximumFractionDigits: 1,
                            })}
                        </span>
                        <span className={css.dropdownSubtext}> /month</span>
                    </span>
                    <button
                        type="button"
                        className={css.closeBtn}
                        onClick={setContextMenu}
                    >
                        <Icon path={dotsLargeIcon} />
                    </button>
                </div>
            </div>
            {open && (
                <div className={css.content}>
                    <MobileExperienceTab index={tabIndex} id={id} />
                    <MobileWorkFormatTab index={tabIndex} id={id} />
                    <MobileWeeklyWorkloadTab index={tabIndex} id={id} />
                    <MobileLocationTab index={tabIndex} id={id} />
                    <MobileTimelineTab index={tabIndex} id={id} />
                    <MobileResourcePreviewTab index={tabIndex} id={id} />
                </div>
            )} */}
        </div>
    );
};

export default MobileResourceDropdown;
