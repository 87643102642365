import { createTheme } from "@mui/material";
import { Error, Grey, Warning } from "./colors";

const theme = createTheme({
    palette: {
        common: {
            white: "#ffffff",
            black: "#000000",
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.multiline) {
                        return {
                            border: `1px solid ${Grey[300]}`,
                            color: `${Grey[500]} !important`,
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "8px",
                            outline: "none",
                            padding: "10px 14px",
                        };
                    }
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    padding: "15px 0 !important",
                },
                rail: {
                    backgroundColor: Grey[200],
                    height: "4px",
                    borderRadius: "4px",
                    opacity: 1,
                },
                thumb: {
                    backgroundColor: Warning[400],
                    width: "24px",
                    height: "24px",
                    boxShadow: "none !important",
                },
                track: ({ ownerState }) => {
                    return {
                        backgroundColor:
                            ownerState.color === "primary"
                                ? Warning[200]
                                : Warning[500],
                        border: "none",
                    };
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.typeof === "select") {
                        return {
                            border: `1px solid ${Grey[200]}`,
                            borderRadius: "8px !important",
                        };
                    }
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    padding: "8px 12px",
                },
                select: ({ ownerState }) => {
                    if (ownerState.variant === "standard") {
                        return {
                            fontFamily: "Helvetica",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: Grey[500],
                            display: "flex",
                            alignItems: "center",
                            padding: "0",
                        };
                    }
                },
                iconStandard: {
                    top: "50%",
                    transform: "translateY(-50%)",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.color === "primary") {
                        return {
                            textTransform: "none",
                            color: "#fff",
                            backgroundColor: Warning[500],
                            fontFamily: "Helvetica",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "28px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "10px",
                            "&:hover": {
                                backgroundColor: Warning[500],
                            },
                            "&:disabled": {
                                backgroundColor: "#CBCBCB",
                                color: "#fff",
                            },
                        };
                    }
                    if (ownerState.color === "secondary") {
                        return {
                            textTransform: "none",
                            color: Grey[500],
                            backgroundColor: "#FFF",
                            fontFamily: "Helvetica",
                            border: `1px solid ${Grey[200]}`,
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "28px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "10px",
                            "&:hover": {
                                backgroundColor: Grey[50],
                            },
                        };
                    }
                    if (ownerState.color === "info") {
                        return {
                            textTransform: "none",
                            color: "#CD0B0B",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                        };
                    }
                },
            },
        },
    },
});

export default theme;
