import css from "./ClickableCard.module.scss";

interface ClickableCardProps {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    colors: {
        background: string;
        text: string;
        border: string;
    };
    onClick: () => void;
}

const ClickableCard: React.FC<ClickableCardProps> = ({
    icon,
    colors,
    subtitle,
    title,
    onClick,
}) => {
    return (
        <div
            onClick={onClick}
            className={css.wrapper}
            style={{
                borderColor: colors.border,
                backgroundColor: colors.background,
            }}
        >
            {icon}
            <p style={{ color: colors.text }} className={css.title}>
                {title}
            </p>
            <p className={css.subtitle}>{subtitle}</p>
        </div>
    );
};

export default ClickableCard;
