import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import DiscountDashboardTabDesktop from "./DiscountDashboardTabDesktop/DiscountDashboardTabDesktop";
import DiscountDashboardTabMobile from "./DiscountDashboardTabMobile/DiscountDashboardTabMobile";

interface TabProps {
    index: number;
}

const DiscountDashboardTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <DiscountDashboardTabDesktop index={index} />
    ) : (
        <DiscountDashboardTabMobile index={index} />
    );
};

export default DiscountDashboardTab;
