import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalBase from "../../Modals/ModalBase";
import { Button } from "@mui/material";
import css from "./SetResourceDetailsMobile.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setCurrentResources } from "../../../features/Resources/resourceSlice";
import cn from "classnames";

interface Props {
    open: boolean;
    setOpen: Dispatch<SetStateAction<string>>;
    id: string;
}

const SetResourceDetailsMobile: React.FC<Props> = ({ open, setOpen, id }) => {
    const { currentResources } = useAppSelector((state) => state.resources);
    const [resourceList, setResourceList] = useState([...currentResources]);

    const currentResourceIndex = resourceList.findIndex(
        (resource) => resource.id === id
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        setResourceList([...currentResources]);
    }, [currentResources]);

    const onDone = () => {
        const currentResource = resourceList[currentResourceIndex];

        const newResources = new Array(
            resourceList[currentResourceIndex].isUnique.length
        )
            .fill(null)
            .map(() => ({
                ...currentResource,
                count: 1,
                isUnique: [],
                id: uuidv4(),
            }));

        const newResourcesList = [
            ...newResources,
            {
                ...currentResource,
                count: currentResource.count - currentResource.isUnique.length,
                isUnique: [],
            },
            ...resourceList.slice(0, currentResourceIndex),
            ...resourceList.slice(currentResourceIndex + 1),
        ].filter(({ count }) => count !== 0);

        setResourceList(newResourcesList);

        dispatch(setCurrentResources(newResourcesList));

        setOpen("");
    };

    return (
        <ModalBase
            title="Make Resources Unique"
            onClose={() => setOpen("")}
            footer={
                <Button color="primary" fullWidth onClick={onDone}>
                    Done
                </Button>
            }
            open={open}
        >
            <p className={css.modalSubtext}>
                If you want to give unique properties to specific resource, make
                them unique
            </p>
            <div className={css.modalResources}>
                {new Array(resourceList[currentResourceIndex]?.count)
                    .fill(null)
                    .map((res, index, arr) => {
                        const currentResource =
                            resourceList[currentResourceIndex];

                        return (
                            <div
                                className={css.modalResource}
                                key={
                                    currentResource.title +
                                    currentResource.count +
                                    index
                                }
                            >
                                <p>
                                    {resourceList[currentResourceIndex].title} #
                                    {index + 1}
                                </p>
                                <Button
                                    className={cn(css.modalBtn, {
                                        [css.secondaryBtn]:
                                            currentResource?.count > 1 &&
                                            !currentResource?.isUnique.includes(
                                                index
                                            ),
                                    })}
                                    color={
                                        currentResource?.count > 1
                                            ? currentResource?.isUnique.includes(
                                                  index
                                              )
                                                ? "primary"
                                                : "secondary"
                                            : "primary"
                                    }
                                    onClick={() => {
                                        setResourceList(
                                            resourceList
                                                .slice(0, currentResourceIndex)
                                                .concat(
                                                    [
                                                        {
                                                            ...currentResource,
                                                            isUnique:
                                                                currentResource.isUnique.includes(
                                                                    index
                                                                )
                                                                    ? currentResource.isUnique.filter(
                                                                          (
                                                                              val
                                                                          ) =>
                                                                              val !==
                                                                              index
                                                                      )
                                                                    : currentResource.isUnique.concat(
                                                                          index
                                                                      ),
                                                        },
                                                    ],
                                                    resourceList.slice(
                                                        currentResourceIndex + 1
                                                    )
                                                )
                                        );
                                    }}
                                >
                                    {currentResource?.count > 1
                                        ? currentResource?.isUnique.includes(
                                              index
                                          )
                                            ? "Unique"
                                            : "Make Unique"
                                        : "Unique"}
                                </Button>
                            </div>
                        );
                    })}
            </div>
        </ModalBase>
    );
};

export default SetResourceDetailsMobile;
