import { useState, useEffect } from "react";
import css from "./InvoiceTermsTabDesktop.module.scss";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import {
    chevronLeftIcon,
    chevronRightIcon,
    youpalLogo,
} from "../../../../../css/icons";
import Icon from "../../../../Icon/Icon";
import RoundedSlider from "../../../../RoundedSlider/RoundedSlider";
import { Button } from "@mui/material";
import cn from "classnames";
import { setInvoicePaymentTerms } from "../../../../../features/Discounts/discountSlice";
import ProjectPrice from "../../../../ProjectPrice/ProjectPrice";
import TopNavigationBar from "../../../../TopNavigationBar/TopNavigationBar";
import DiscountsCarousel from "../../../../DiscountsCarousel/DiscountsCarousel";
import ResourceService from "../../../../../services/resource.service";
import {
    calculateWeeksDifference,
    formatDate,
    getExperienceLabel,
} from "../../../../../utils/helpers";
import { setName } from "../../../../../features/Resources/resourceSlice";

interface TabProps {
    index: number;
    show?: boolean;
    done?: boolean;
    setIsDiscountOpen?: any;
}
const resourceService = new ResourceService();
const InvoiceTermsTabDesktop: React.FC<TabProps> = ({
    index,
    show,
    done,
    setIsDiscountOpen,
}) => {
    const { currentTab } = useAppSelector((state) => state.tabs);
    const {
        discounts: { invoice_payment_terms_percentage_can_avail },
        total_discount,
        discountPayload,
        discountPayload: { invoice_payment_terms },
    } = useAppSelector((state) => state.discounts);
    const {
        name,
        resources,
        budget,
        duration,
        currency,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const dispatch = useAppDispatch();
    const [invoiceDurationVal, setInvoiceDurationVal] = useState(
        invoice_payment_terms
    );

    const onGoBack = () => {
        dispatch(setCurrentTab(12));
    };

    const onNext = () => {
        dispatch(setCurrentTab(14));
    };

    const [path, setPath] = useState("");
    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    const onDone = () => {
        dispatch(setCurrentTab(17));
        const resource_id =
            name && name.length > 0
                ? name
                : path && path.length > 0
                ? path
                : "";
        resourceService
            .addResource(
                resource_id,
                currency || "USD",
                budget || 10000,
                duration || 8,
                17,
                resources.map((res) => ({
                    role: res.role,
                    number: res.count,
                    experience: getExperienceLabel(res.experience),
                    duration: calculateWeeksDifference(
                        res.timeline?.startWeek,
                        res.timeline?.endWeek
                    ),
                    workload: res.weeklyWorkload,
                    start_week: 0,
                    end_week: 4,

                    title: res.title,
                    work_format: res.workFormat,
                    work_location: res.location,
                    resource_location: res.region,
                    start_week_date: formatDate(res.timeline?.startWeek),
                    end_week_date: formatDate(res.timeline?.endWeek),
                })),
                discountPayload.credit_card,
                discountPayload.credit_card_duration,
                discountPayload.frame_agreement,
                discountPayload.upfront_payment,
                discountPayload.credit_card_payment_percent_per_month
            )
            .then((res) => {
                dispatch(setName(res.data.message.name));
            });
    };

    const getMaxDiscount = () => {
        setInvoiceDurationVal("60 days");
    };

    useEffect(() => {
        if (!show) {
            document.dispatchEvent(
                new CustomEvent("update_discount", {
                    detail: {
                        total_amount: projectCost,
                        discount: {
                            ...discountPayload,
                            invoice_payment_terms: invoiceDurationVal,
                        },
                    },
                })
            );
            dispatch(setInvoicePaymentTerms(invoiceDurationVal));
        }
        if (done) {
            document.dispatchEvent(
                new CustomEvent("update_discount", {
                    detail: {
                        total_amount: projectCost,
                        discount: {
                            ...discountPayload,
                            invoice_payment_terms: invoiceDurationVal,
                        },
                    },
                })
            );
            dispatch(setInvoicePaymentTerms(invoiceDurationVal));
            setIsDiscountOpen(false);
        }
    }, [invoiceDurationVal, done]);

    return (
        <>
            <TabPanel index={13} value={index}>
                <TopNavigationBar />
                <DiscountsCarousel />
                <div className={css.wrapper}>
                    <div className={css.innerContainer}>
                        <div className={css.leftButton}>
                            <button className={css.nextIcon} onClick={onGoBack}>
                                <Icon path={chevronLeftIcon} />
                            </button>
                        </div>

                        <div className={css.content}>
                            <h1 className={css.title}>
                                What invoice payment terms can you offer us?
                            </h1>

                            <div className={css.cardFlex}>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                invoiceDurationVal ===
                                                "60 days",
                                        }
                                    )}
                                    onClick={() =>
                                        setInvoiceDurationVal("60 days")
                                    }
                                >
                                    <p>60 days</p>
                                    <p className={css.greenSubtext}>Save 10%</p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                invoiceDurationVal ===
                                                "45 days",
                                        }
                                    )}
                                    onClick={() =>
                                        setInvoiceDurationVal("45 days")
                                    }
                                >
                                    <p>45 days</p>
                                    <p className={css.greenSubtext}>Save 10%</p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                invoiceDurationVal ===
                                                "30 days",
                                        }
                                    )}
                                    onClick={() =>
                                        setInvoiceDurationVal("30 days")
                                    }
                                >
                                    <p>30 days</p>
                                    <p className={css.greenSubtext}>Save 0%</p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                invoiceDurationVal ===
                                                "15 days",
                                        }
                                    )}
                                    onClick={() =>
                                        setInvoiceDurationVal("15 days")
                                    }
                                >
                                    <p>15 days</p>
                                    <p className={css.greenSubtext}>Save 0%</p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                invoiceDurationVal === "7 days",
                                        }
                                    )}
                                    onClick={() =>
                                        setInvoiceDurationVal("7 days")
                                    }
                                >
                                    <p>7 days</p>
                                    <p className={css.greenSubtext}>Save 0%</p>
                                </div>
                            </div>

                            <div className={css.result}>
                                <p className={css.resultSubtext}>
                                    You will save
                                </p>
                                <p>
                                    <span className={css.resultGreen}>
                                        ${" "}
                                        {Number(total_discount).toLocaleString(
                                            undefined,
                                            {
                                                maximumFractionDigits: 0,
                                            }
                                        )}{" "}
                                    </span>
                                    <span className={css.resultSubtext}>
                                        {" "}
                                        /month
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className={css.rightButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronRightIcon} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            width: "180px",
                        }}
                        onClick={onDone}
                    >
                        Applying Discount
                    </Button>
                </div>
            </TabPanel>
            {show && (
                <div className={css.innerContainer}>
                    <div className={css.content}>
                        <h1 className={css.title}>
                            What invoice payment terms can you offer us?
                        </h1>

                        <div className={css.cardFlex}>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            invoiceDurationVal === "60 days",
                                    }
                                )}
                                onClick={() => setInvoiceDurationVal("60 days")}
                            >
                                <p>60 days</p>
                                <p className={css.greenSubtext}>Save 10%</p>
                            </div>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            invoiceDurationVal === "45 days",
                                    }
                                )}
                                onClick={() => setInvoiceDurationVal("45 days")}
                            >
                                <p>45 days</p>
                                <p className={css.greenSubtext}>Save 10%</p>
                            </div>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            invoiceDurationVal === "30 days",
                                    }
                                )}
                                onClick={() => setInvoiceDurationVal("30 days")}
                            >
                                <p>30 days</p>
                                <p className={css.greenSubtext}>Save 0%</p>
                            </div>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            invoiceDurationVal === "15 days",
                                    }
                                )}
                                onClick={() => setInvoiceDurationVal("15 days")}
                            >
                                <p>15 days</p>
                                <p className={css.greenSubtext}>Save 0%</p>
                            </div>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            invoiceDurationVal === "7 days",
                                    }
                                )}
                                onClick={() => setInvoiceDurationVal("7 days")}
                            >
                                <p>7 days</p>
                                <p className={css.greenSubtext}>Save 0%</p>
                            </div>
                        </div>

                        <div className={css.result}>
                            <p className={css.resultSubtext}>You will save</p>
                            <p>
                                <span className={css.resultGreen}>
                                    ${" "}
                                    {Number(total_discount).toLocaleString(
                                        undefined,
                                        {
                                            maximumFractionDigits: 0,
                                        }
                                    )}{" "}
                                </span>
                                <span className={css.resultSubtext}>
                                    {" "}
                                    /month
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InvoiceTermsTabDesktop;
