import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AddResourceTab from "./components/Tabs/AddResourceTabs/AddResourceTab";
import BudgetTab from "./components/Tabs/AddResourceTabs/BudgetTab";
import DurationTab from "./components/Tabs/AddResourceTabs/DurationTab";
import ExperienceTab from "./components/Tabs/AddResourceTabs/ExperienceTab/ExperienceTab";
import HomeTab from "./components/Tabs/AddResourceTabs/HomeTab";
import LocationTab from "./components/Tabs/AddResourceTabs/LocationTab/LocationTab";
import ResourcePreviewTab from "./components/Tabs/AddResourceTabs/ResourcePreviewTab/ResourcePreviewTab";
import TimelineTab from "./components/Tabs/AddResourceTabs/TimelineTab/TimelineTab";
import WeeklyWorkloadTab from "./components/Tabs/AddResourceTabs/WeeklyWorkloadTab/WeeklyWorkloadTab";
import WorkFormatTab from "./components/Tabs/AddResourceTabs/WorkFormatTab/WorkFormatTab";
import CongratulationsTab from "./components/Tabs/DiscountTabs/CongratulationsTab";
import ContactSupportTab from "./components/Tabs/DiscountTabs/ContactSupportTab";
import DiscountOverviewTab from "./components/Tabs/DiscountTabs/DiscountOverviewTab";
import DiscountSummaryTab from "./components/Tabs/DiscountTabs/DiscountSummaryTab";
import FirstTenPercentTab from "./components/Tabs/DiscountTabs/FirstTenPercentTab";
import FrameAgreementTab from "./components/Tabs/DiscountTabs/FrameAgreementTab";
import VolumeResourcesTab from "./components/Tabs/DiscountTabs/VolumeResourcesTab";
import InvoiceTermsTab from "./components/Tabs/DiscountTabs/InvoiceTermsTab";
import MonthlyPercentTab from "./components/Tabs/DiscountTabs/MonthlyPercentTab";
import UseCreditCardTab from "./components/Tabs/DiscountTabs/UseCreditCardTab";
import { useWindowDimensions } from "./hooks";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
    Resource,
    completeResources,
    computePrice,
    computeProjectTotals,
    setCurrentResources,
    setProjectBudget,
    setProjectDuration,
    setResourceCostDetails,
    setResourceCostDetailsSaved,
    setResourceTabIndex,
} from "./features/Resources/resourceSlice";
import { setCurrentTab, setIsBotMobileVisible } from "./features/Tabs/tabSlice";
import ResourceDropdownsTab from "./components/Tabs/AddResourceTabs/ResourceDropdownsTab/ResourceDropdownsTab";
import DiscountDashboardTab from "./components/Tabs/DiscountTabs/DiscountDashboardTab";
import Icon from "./components/Icon/Icon";
import { closeIcon, frontendIcon } from "./css/icons";
import { MOBILE_SCREEN_WIDTH } from "./constants";
import "./css/App.scss";
import ResourceService from "./services/resource.service";
import {
    calculateWeeks,
    colorSet,
    getExperienceLabel,
    getExperienceNumber,
    parseCustomDateString,
} from "./utils/helpers";
import {
    DiscountType,
    setDiscounts,
    setStoredDiscounts,
} from "./features/Discounts/discountSlice";

const resourceService = new ResourceService();

const App: React.FC = () => {
    const [path, setPath] = useState("");
    const currentResources = useAppSelector(
        (state) => state.resources.currentResources
    );
    const { currentTab, currentResourceIndex, isBotMobiltVisible } =
        useAppSelector((state) => state.tabs);
    const { width } = useWindowDimensions();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (currentTab >= 4 && currentResources.length > 0) {
            dispatch(
                setResourceTabIndex({
                    index: currentResourceIndex,
                    tabIndex: currentTab,
                })
            );
        }
    }, [currentTab]);

    useEffect(() => {
        if (currentTab >= 4 && currentResources.length > 0) {
            dispatch(
                setCurrentTab(currentResources[currentResourceIndex].tabIndex)
            );
        }
    }, [currentResourceIndex]);

    // useEffect(() => {
    //     const landbot = document.getElementById("myLandbot");
    //     const botCloseBtn = document.getElementById("botCloseIcon");

    //     if (isBotMobiltVisible || width > MOBILE_SCREEN_WIDTH) {
    //         if (landbot) {
    //             landbot.style.display = "block";
    //         }

    //         if (botCloseBtn) {
    //             botCloseBtn.style.display = "block";
    //         }
    //     } else {
    //         if (landbot) {
    //             landbot.style.display = "none";
    //         }

    //         if (botCloseBtn) {
    //             botCloseBtn.style.display = "none";
    //         }
    //     }
    // }, [width, isBotMobiltVisible]);

    useEffect(() => {
        const fetchPrice = (e: CustomEvent<{ resource: Resource }>) => {
            const { experience, title, location, region, workFormat, id } =
                e.detail.resource;

            if (!title || !experience) {
                return;
            }

            if (!location || workFormat === "remote") {
                if (region && region.length > 0) {
                    resourceService
                        .getResourcePriceByRegion(
                            title,
                            getExperienceLabel(experience),
                            region
                        )
                        .then((res) => {
                            const price = res.data.message.resource_cost;
                            dispatch(
                                computePrice({
                                    id,
                                    price,
                                })
                            );
                        });
                } else {
                    resourceService
                        .getResourceBasicPrice(
                            title,
                            getExperienceLabel(experience)
                        )
                        .then((res) => {
                            const price = res.data.message.resource_cost;
                            dispatch(
                                computePrice({
                                    id,
                                    price,
                                })
                            );
                        });
                }
            } else {
                if (region && region.length > 0) {
                    resourceService
                        .getResourcePrice(
                            title,
                            getExperienceLabel(experience),
                            "Onsite",
                            location,
                            region
                        )
                        .then((res) => {
                            const price = res.data.message.resource_cost;
                            dispatch(
                                computePrice({
                                    id,
                                    price,
                                })
                            );
                        });
                } else {
                    resourceService
                        .getResourcePriceByLocation(
                            title,
                            getExperienceLabel(experience),
                            "Onsite",
                            location
                        )
                        .then((res) => {
                            const price = res.data.message.resource_cost;
                            dispatch(
                                computePrice({
                                    id,
                                    price,
                                })
                            );
                        });
                }
            }
        };

        const fetchPriceDetails = (e: CustomEvent<{ resource: Resource }>) => {
            const {
                experience,
                title,
                location,
                region,
                workFormat,
                id,
                weeklyWorkload,
                timeline,
            } = e.detail.resource;
            resourceService
                .getResourcePriceDetails(
                    title,
                    getExperienceLabel(experience),
                    workFormat === "on-site" ? "Onsite" : "Remote",
                    location?.length > 0 ? location : "Remote",
                    region?.length > 0 ? region : "Remote",
                    weeklyWorkload,
                    timeline
                )
                .then((res) => {
                    dispatch(
                        setResourceCostDetails({
                            cost_details: res.data.message,
                            id,
                        })
                    );
                });
        };

        const fetchPriceDetailsSaved = (
            e: CustomEvent<{ resource: Resource }>
        ) => {
            const {
                experience,
                title,
                location,
                region,
                workFormat,
                id,
                weeklyWorkload,
                timeline,
            } = e.detail.resource;
            resourceService
                .getResourcePriceDetails(
                    title,
                    getExperienceLabel(experience),
                    workFormat === "on-site" ? "Onsite" : "Remote",
                    location?.length > 0 ? location : "Remote",
                    region?.length > 0 ? region : "Remote",
                    weeklyWorkload,
                    timeline
                )
                .then((res) => {
                    dispatch(
                        setResourceCostDetailsSaved({
                            cost_details: res.data.message,
                            id,
                        })
                    );
                });
        };

        const fetchDiscounts = (
            e: CustomEvent<{ discount: DiscountType; total_amount: number }>
        ) => {
            const {
                total_amount,
                discount: {
                    credit_card,
                    credit_card_duration,
                    credit_card_payment_percent_per_month,
                    frame_agreement,
                    invoice_payment_terms,
                    upfront_payment,
                },
            } = e.detail;

            resourceService
                .getResourceDiscounts(
                    total_amount,
                    credit_card,
                    credit_card_duration,
                    credit_card_payment_percent_per_month,
                    invoice_payment_terms,
                    upfront_payment,
                    frame_agreement
                )
                .then((res) => {
                    dispatch(setDiscounts({ discounts: res.data.message }));
                });
        };

        document.addEventListener(
            "update_price" as keyof DocumentEventMap,
            fetchPrice as EventListener
        );

        document.addEventListener(
            "update_price_details" as keyof DocumentEventMap,
            fetchPriceDetails as EventListener
        );

        document.addEventListener(
            "update_price_details_saved" as keyof DocumentEventMap,
            fetchPriceDetailsSaved as EventListener
        );

        document.addEventListener(
            "update_discount" as keyof DocumentEventMap,
            fetchDiscounts as EventListener
        );

        return () => {
            document.removeEventListener(
                "update_price" as keyof DocumentEventMap,
                fetchPrice as EventListener
            );
            document.removeEventListener(
                "update_price_details" as keyof DocumentEventMap,
                fetchPriceDetails as EventListener
            );
            document.removeEventListener(
                "update_price_details_saved" as keyof DocumentEventMap,
                fetchPriceDetailsSaved as EventListener
            );
            document.removeEventListener(
                "update_discount" as keyof DocumentEventMap,
                fetchDiscounts as EventListener
            );
        };
    }, []);

    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    useEffect(() => {
        if (path && path.length > 0) {
            resourceService.getResourceDetail(path).then((res) => {
                const detail = res.data.message;
                if (typeof detail !== "string") {
                    dispatch(setStoredDiscounts(detail));
                    dispatch(setProjectBudget(detail.budget));
                    dispatch(setProjectDuration(detail.project_duration));
                    const currentResources = [
                        ...detail.resource_details.map(
                            (resource: any, index: number) => {
                                const {
                                    title,
                                    role,
                                    total_cost,
                                    resource_per_hour_cost,
                                    work_format,
                                    work_location,
                                    resource_location,
                                    start_week_date,
                                    end_week_date,
                                } = resource;
                                const { number, experience, workload } =
                                    detail.resource_requirements[index];
                                const computedPrice = total_cost;
                                return {
                                    title,
                                    icon: frontendIcon,
                                    count: number,
                                    price: resource_per_hour_cost,
                                    role,
                                    isUnique: [],
                                    tabIndex: 4,
                                    id: uuidv4(),
                                    timeline: {
                                        startWeek:
                                            parseCustomDateString(
                                                start_week_date
                                            ),
                                        endWeek:
                                            parseCustomDateString(
                                                end_week_date
                                            ),
                                    },
                                    computedPrice,
                                    weeklyWorkload: +workload,
                                    experience: getExperienceNumber(experience),
                                    workFormat: work_format,
                                    location: work_location,
                                    region: resource_location,
                                    color: colorSet[index % colorSet.length],
                                };
                            }
                        ),
                    ];
                    dispatch(setCurrentResources(currentResources));
                    for (let resource of currentResources) {
                        document.dispatchEvent(
                            new CustomEvent("update_price", {
                                detail: {
                                    resource: {
                                        ...resource,
                                        timeline: {
                                            startWeek:
                                                resource.timeline?.startWeek,
                                            endWeek: resource.timeline?.endWeek,
                                        },
                                    },
                                },
                            })
                        );
                        document.dispatchEvent(
                            new CustomEvent("update_price_details", {
                                detail: {
                                    resource: {
                                        ...resource,
                                        timeline: {
                                            startWeek:
                                                resource.timeline.startWeek,
                                            endWeek: resource.timeline.endWeek,
                                        },
                                    },
                                },
                            })
                        );
                    }
                    setTimeout(() => {
                        dispatch(completeResources());
                        dispatch(computeProjectTotals());
                        dispatch(setCurrentTab(+detail.current_tab));
                    }, 2000);
                    // if (
                    //     +detail.current_tab >= 11 &&
                    //     +detail.current_tab <= 16
                    // ) {
                    //     setTimeout(() => {
                    //         dispatch(completeResources());
                    //         dispatch(computeProjectTotals());
                    //         dispatch(setCurrentTab(+detail.current_tab));
                    //     }, 3000);
                    // } else {
                    //     dispatch(completeResources());
                    //     dispatch(computeProjectTotals());
                    //     dispatch(setCurrentTab(+detail.current_tab));
                    // }
                }
            });
        }
    }, [path]);

    return (
        <>
            <div className="wrapper" data-currenttab={currentTab}>
                <div className="page-container">
                    <HomeTab index={currentTab} />
                    <BudgetTab index={currentTab} />
                    <DurationTab index={currentTab} />
                    <AddResourceTab index={currentTab} />
                    {/* <React.Fragment>
                        <ExperienceTab index={currentTab} />
                        <WorkFormatTab index={currentTab} />
                        <WeeklyWorkloadTab index={currentTab} />
                        <LocationTab index={currentTab} />
                        <TimelineTab index={currentTab} />
                        <ResourcePreviewTab index={currentTab} />
                    </React.Fragment> */}
                    <TimelineTab index={currentTab} />
                    {width > MOBILE_SCREEN_WIDTH && (
                        <React.Fragment>
                            <ExperienceTab index={currentTab} />
                            <WorkFormatTab index={currentTab} />
                            <WeeklyWorkloadTab index={currentTab} />
                            <LocationTab index={currentTab} />
                            {/* <TimelineTab index={currentTab} /> */}
                            <ResourcePreviewTab index={currentTab} />
                        </React.Fragment>
                    )}
                    {width <= MOBILE_SCREEN_WIDTH && currentTab === 4 && (
                        <ResourceDropdownsTab />
                    )}
                    <DiscountDashboardTab index={currentTab} />
                    <UseCreditCardTab index={currentTab} />
                    <MonthlyPercentTab index={currentTab} />
                    <InvoiceTermsTab index={currentTab} />
                    <FirstTenPercentTab index={currentTab} />
                    <FrameAgreementTab index={currentTab} />
                    <VolumeResourcesTab index={currentTab} />
                    <DiscountSummaryTab index={currentTab} />
                    <DiscountOverviewTab index={currentTab} />
                    <ContactSupportTab index={currentTab} />
                    <CongratulationsTab index={currentTab} />
                </div>
                {width < MOBILE_SCREEN_WIDTH && (
                    <button
                        id="botCloseIcon"
                        onClick={() => dispatch(setIsBotMobileVisible(false))}
                    >
                        <Icon path={closeIcon} />
                    </button>
                )}
            </div>
        </>
    );
};

export default App;
