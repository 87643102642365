import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    completeResources,
    computeProjectTotals,
    setCurrentResources,
} from "../../../../features/Resources/resourceSlice";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import BotMobile from "../../../BotMobile/BotMobile";
import CloseButton from "../../../CloseButton/CloseButton";
import MobileResourceDropdown from "../../../MobileResourceDropdown/MobileResourceDropdown";
import css from "./ResourceDropdownsTab.module.scss";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
    arrowLeftIcon,
    arrowLeftWithBorder,
    arrowRightWithBorder,
    usersIcon,
} from "../../../../css/icons";
import Icon from "../../../Icon/Icon";

import {
    calculateWeeks,
    calculateWeeksDifference,
    colorSet,
    formatAmountDifferenceWithSign,
    getExperienceLabel,
} from "../../../../utils/helpers";

import SelectedResources from "./SelectedResources/SelectedResources";
import ModalBase from "../../../Modals/ModalBase";

type ResourceObject = {
    computedPrice?: number;
    count: number;
    price?: number;
    timeline?: {
        startWeek: Date;
        endWeek: Date;
    };
    weeklyWorkload?: number;
};

const ResourceDropdownsTab: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [open, setOpen] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const { currentResources } = useAppSelector((state) => state.resources);

    const dispatch = useAppDispatch();

    const onDone = () => {
        const isAllResourcesDone = currentResources.every(
            (res) => res?.costDetails
        );
        addColor();
        dispatch(completeResources());
        if (isAllResourcesDone) {
            addColor();
            dispatch(setCurrentTab(8));
            // dispatch(completeResources());
            dispatch(computeProjectTotals());
        } else {
            // setOpen(true);
        }
    };

    const addColor = () => {
        for (let resource of currentResources) {
            document.dispatchEvent(
                new CustomEvent("update_price_details", {
                    detail: {
                        resource: {
                            ...resource,
                            timeline: {
                                startWeek: resource.timeline.startWeek,
                                endWeek: resource.timeline.endWeek,
                            },
                        },
                    },
                })
            );
        }
        setTimeout(() => {
            dispatch(setCurrentTab(8));
        }, 1000);
        dispatch(
            setCurrentResources([
                ...currentResources.map((role, index) => {
                    return {
                        ...role,
                        color: colorSet[index % colorSet.length],
                    };
                }),
            ])
        );
    };

    const calculateTotalCost = (resources: ResourceObject[]): string => {
        let totalCost = 0;
        const weeks = calculateWeeks();

        resources.forEach((resource) => {
            let cost = 0;

            if (resource.computedPrice) {
                cost = resource.computedPrice * resource.count;
            } else {
                const startWeek =
                    resource.timeline?.startWeek || weeks?.startWeek;
                const endWeek = resource.timeline?.endWeek || weeks?.endWeek;
                const weeklyWorkload = resource.weeklyWorkload || 0;
                const price = resource.price || 0;
                const count = resource.count;

                cost =
                    count *
                    price *
                    calculateWeeksDifference(startWeek, endWeek) *
                    weeklyWorkload *
                    8;
            }

            totalCost += cost;
        });

        return Number(totalCost).toLocaleString(undefined, {
            maximumFractionDigits: 0,
        });
    };

    const buget = useAppSelector((state) =>
        state.resources.budget?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
        })
    );

    const onBackButtonPress = () => {
        dispatch(setCurrentTab(3));
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? currentResources.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === currentResources.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        //console.log("currentResources.length ", currentResources);
        //console.log("currentIndex ", currentIndex);
        if (
            currentResources.length > 0 &&
            currentIndex === currentResources.length
        ) {
            setCurrentIndex(0);
        }
    }, [currentResources]);

    const toggleDrawer =
        (open: boolean) =>
        (
            event: React.KeyboardEvent | React.MouseEvent,
            reason?: "backdropClick" | "escapeKeyDown"
        ) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setIsOpen(open); // Directly set the state
        };

    return (
        <div className={css.wrapper}>
            <ModalBase
                onClose={() => setOpen(false)}
                open={open}
                title="Are you sure you want to proceed?"
            >
                <p>You haven't added details for other talents.</p>
                <div className={cn(css.flexSB, css.modalBtns)}>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => setOpen(false)}
                    >
                        Add Details
                    </Button>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => {
                            addColor();
                            // dispatch(setCurrentTab(8));
                            setOpen(false);
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </ModalBase>
            <div className={css.header}>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                    <Box sx={{ display: "flex" }} className={css.container}>
                        <Box>
                            <button
                                className={css.prevbutton}
                                onClick={onBackButtonPress}
                            >
                                <Icon path={arrowLeftIcon} />
                            </button>
                        </Box>
                        <Box>
                            <div className={css.mobileTitle}>
                                Configuring Resources
                            </div>
                        </Box>
                        <Box>
                            <button
                                // disabled={numberSelectedRoles === 0}
                                className={css.usersButton}
                                onClick={(event) =>
                                    toggleDrawer(true)(
                                        event as React.MouseEvent
                                    )
                                }
                            >
                                {" "}
                                <span className={css.number}>
                                    {currentResources.length}
                                </span>
                                <Icon path={usersIcon} />
                            </button>
                        </Box>
                    </Box>
                </Box>
            </div>

            <div className={css.budgetContainer}>
                <p>
                    ${calculateTotalCost(currentResources)}
                    <span> / ${buget}</span>
                </p>
                <small>
                    {currentResources.reduce(
                        (acc, cur) => acc + cur.count,
                        0
                    ) === 1 ? (
                        <>
                            {currentResources.reduce(
                                (acc, cur) => acc + cur.count,
                                0
                            )}{" "}
                            resource selected
                        </>
                    ) : (
                        <>
                            {currentResources.reduce(
                                (acc, cur) => acc + cur.count,
                                0
                            )}{" "}
                            resources selected
                        </>
                    )}
                </small>
            </div>
            {currentResources[currentIndex] !== undefined && (
                <div className={css.resourceDropdownContainer}>
                    <div className={css.singleResourceHeader}>
                        <div
                            className={css.singleResourceHeaderLeft}
                            onClick={goToPrevious}
                        >
                            <Icon path={arrowLeftWithBorder} />
                        </div>
                        <div className={css.singleResourceHeaderTitle}>
                            <div>{currentResources[currentIndex].title}</div>
                            <small>
                                {currentResources[currentIndex].count}{" "}
                                {currentResources[currentIndex].count > 1
                                    ? "resources"
                                    : "resource"}
                            </small>
                        </div>
                        <div
                            className={css.singleResourceHeaderRight}
                            onClick={goToNext}
                        >
                            <Icon path={arrowRightWithBorder} />
                        </div>
                    </div>
                    <MobileResourceDropdown
                        key={
                            currentResources[currentIndex].title + currentIndex
                        }
                        title={currentResources[currentIndex].title}
                        count={currentResources[currentIndex].count}
                        tabIndex={currentResources[currentIndex].tabIndex}
                        id={currentResources[currentIndex].id}
                        index={currentIndex}
                    />
                </div>
            )}

            {/* 
            old code commented
            {currentResources.map((resource, index) => {
                    return (
                        <MobileResourceDropdown
                            key={resource.title + index}
                            title={resource.title}
                            count={resource.count}
                            tabIndex={resource.tabIndex}
                            id={resource.id}
                        />
                    );
                })} */}

            <div className={css.botMobileContainer}>
                <BotMobile />
            </div>
            <div className={css.doneButtonMobile}>
                <Button
                    color="primary"
                    onClick={onDone}
                    sx={{
                        padding: "12px 24px",
                        width: "100%",
                    }}
                >
                    Finish Resource Configuration
                </Button>
            </div>

            <Drawer
                anchor="right"
                open={isOpen}
                onClose={(event, reason) =>
                    toggleDrawer(false)(event as React.KeyboardEvent, reason)
                }
            >
                <SelectedResources
                    resources={currentResources}
                    onCloseDrawer={(
                        event: React.MouseEvent<Element, MouseEvent>
                    ) => toggleDrawer(false)(event as React.MouseEvent)}
                />
            </Drawer>
        </div>
    );
};

export default ResourceDropdownsTab;
