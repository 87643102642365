import css from "./DiscountSummaryTabMobile.module.scss";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import { arrowLeftLargeIcon, arrowRight } from "../../../../../css/icons";
import Icon from "../../../../Icon/Icon";
import { Button } from "@mui/material";
import ModalBase from "../../../../Modals/ModalBase";
import { useEffect, useState } from "react";
import BotMobile from "../../../../BotMobile/BotMobile";
import ResourceService from "../../../../../services/resource.service";
import { getExperienceLabel } from "../../../../../utils/helpers";
import { setName } from "../../../../../features/Resources/resourceSlice";

interface TabProps {
    index: number;
}

interface DiscountCard {
    title: string;
    percent: number;
    saving: number;
    tabIndex: number;
}

const resourceService = new ResourceService();

const DiscountSummaryTabMobile: React.FC<TabProps> = ({ index }) => {
    const [open, setOpen] = useState(false);
    const [allDiscountsOpen, setAllDiscountsOpen] = useState(false);
    const [discount, setDiscount] = useState<DiscountCard>();
    const [discountArray, setDiscountArray] = useState<DiscountCard[]>([]);
    const { total_discount, discounts, discountPayload } = useAppSelector(
        (state) => state.discounts
    );
    const {
        resources,
        budget,
        currency,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const dispatch = useAppDispatch();

    const onGoBack = () => {
        dispatch(setCurrentTab(15));
    };

    const onNext = () => {
        // dispatch(setCurrentTab(17));
        // resourceService
        //     .addResource(
        //         currency || "USD",
        //         budget || 10000,
        //         resources.map((res) => ({
        //             role: res.role,
        //             number: res.count,
        //             experience: getExperienceLabel(res.experience),
        //             duration: res.timeline.endWeek - res.timeline.startWeek,
        //             workload: res.weeklyWorkload,
        //             start_week: res.timeline.startWeek,
        //             end_week: res.timeline.endWeek,
        //         })),
        //         discountPayload.credit_card,
        //         discountPayload.credit_card_duration,
        //         discountPayload.frame_agreement,
        //         discountPayload.upfront_payment,
        //         discountPayload.credit_card_payment_percent_per_month
        //     )
        //     .then((res) => {
        //         dispatch(setName(res.data.message.name));
        //     });
    };

    useEffect(() => {
        setDiscountArray([
            {
                //payment_duration_availed_percentage
                //payment_duration_percentage_can_avail
                title: "Pay Bi-monthly on Credit Card",
                percent: discounts?.payment_duration_percentage_can_avail,
                saving:
                    (discounts?.payment_duration_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                tabIndex: 11,
            },
            {
                //credit_card_percentage_can_avail
                //credit_card_availed_percentage
                title: "75% Payment via Credit Card monthly",
                percent: discounts?.credit_card_percentage_can_avail,
                saving:
                    (discounts?.credit_card_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                tabIndex: 12,
            },
            {
                //invoice_payment_terms_availed_percentage
                //invoice_payment_terms_percentage_can_avail
                title: "30 day Invoice Payment Terms",
                percent: discounts?.invoice_payment_terms_percentage_can_avail,
                saving:
                    (discounts?.invoice_payment_terms_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                tabIndex: 13,
            },
            {
                //upfront_payment_availed_percentage
                //upfront_payment_percentage_can_avail
                title: "Pay 20% amount in first 10% of the project",
                percent: discounts?.upfront_payment_percentage_can_avail,
                saving:
                    (discounts?.upfront_payment_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                tabIndex: 14,
            },
            {
                //frame_agreement_availed_percentage
                //frame_agreement_percentage_can_avail
                title: "Provide + 3yrs agreement",
                percent: discounts?.frame_agreement_percentage_can_avail,
                saving:
                    (discounts?.frame_agreement_percentage_can_avail *
                        (projectCost || 10000)) /
                    100,
                tabIndex: 15,
            },
        ]);
    }, [discounts]);

    return (
        <TabPanel index={18} value={index}>
            <ModalBase
                open={open}
                title="Save More!"
                onClose={() => setOpen(false)}
                footer={
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() =>
                            dispatch(setCurrentTab(discount?.tabIndex))
                        }
                    >
                        Reconsider your Discount
                    </Button>
                }
            >
                <div className={css.modalContent}>
                    <p>
                        You can save up-to{" "}
                        {Number(discount?.percent).toLocaleString(undefined, {
                            maximumFractionDigits: 1,
                        })}
                        % by selecting the following
                    </p>
                    <div className={css.modalDiscountBox}>
                        {discount?.title}
                    </div>
                </div>
            </ModalBase>
            <ModalBase
                open={allDiscountsOpen}
                title="Save More!"
                onClose={() => setAllDiscountsOpen(false)}
            >
                <div className={css.modalContent}>
                    <p>
                        You can save up-to{" "}
                        {Number(discount?.percent).toLocaleString(undefined, {
                            maximumFractionDigits: 1,
                        })}
                        % by selecting the following
                    </p>
                    <div className={css.modalDiscountContainer}>
                        {discountArray.map((discount) => {
                            return (
                                <div
                                    className={css.modalDiscountBox}
                                    key={
                                        discount.percent +
                                        discount.saving +
                                        discount.title +
                                        index
                                    }
                                >
                                    {discount?.title}
                                    <button
                                        className={css.unstyledBtn}
                                        onClick={() =>
                                            dispatch(
                                                setCurrentTab(discount.tabIndex)
                                            )
                                        }
                                    >
                                        <Icon path={arrowRight} />
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ModalBase>
            <div className={css.wrapper}>
                <div className={css.header}>
                    <div>
                        <button
                            type="button"
                            className={css.prevbutton}
                            onClick={onGoBack}
                        >
                            <Icon path={arrowLeftLargeIcon} />
                        </button>
                        <p className={css.titleOpacity}>Discount Summary</p>
                    </div>
                    <Button color="info" onClick={() => {}}>
                        Cancel
                    </Button>
                </div>
                <div className={css.content}>
                    <div className={css.result}>
                        <p className={css.resultSubtext}>You're saving</p>
                        <p>
                            <span className={css.resultBlue}>
                                ${" "}
                                {Number(total_discount).toLocaleString(
                                    undefined,
                                    {
                                        maximumFractionDigits: 1,
                                    }
                                )}{" "}
                            </span>
                            <span className={css.resultSubtext}> /month</span>
                        </p>
                    </div>
                    <div className={css.cardContainer}>
                        {discountArray.map((discount, index) => {
                            return (
                                <div
                                    className={css.card}
                                    key={
                                        discount.percent +
                                        discount.saving +
                                        discount.title +
                                        index
                                    }
                                    onClick={() => {
                                        setOpen(true);
                                        setDiscount(discount);
                                    }}
                                >
                                    <p>{discount.title}</p>
                                    <p className={css.cardGreen}>
                                        $
                                        {discount.saving.toLocaleString(
                                            undefined,
                                            {
                                                maximumFractionDigits: 1,
                                            }
                                        )}
                                    </p>
                                    <p className={css.cardBold}>
                                        Can save{" "}
                                        {Number(
                                            discount.percent
                                        ).toLocaleString(undefined, {
                                            maximumFractionDigits: 1,
                                        })}
                                        % more
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <p
                        className={css.orangeText}
                        onClick={() => setAllDiscountsOpen(true)}
                    >
                        You can save $
                        {Number(
                            discounts?.total_discount_can_avail
                        ).toLocaleString(undefined, {
                            maximumFractionDigits: 1,
                        })}{" "}
                        more!
                    </p>
                    <div className={css.flexSB}>
                        <span className={css.subtext}>New Cost</span>
                        <span className={css.resultBold}>
                            {Number(
                                projectCost - total_discount
                            ).toLocaleString(undefined, {
                                maximumFractionDigits: 1,
                            })}{" "}
                            <span className={css.subtext}>/project</span>
                        </span>
                    </div>
                    <div className={css.btnContainer}>
                        <Button
                            color="primary"
                            sx={{
                                padding: "12px",
                            }}
                            fullWidth
                            onClick={onNext}
                        >
                            Save Discounts
                        </Button>
                    </div>
                    <div className={css.botMobileContainer}>
                        <BotMobile />
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default DiscountSummaryTabMobile;
