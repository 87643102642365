import { crossIcon } from "../../css/icons";
import Icon from "../Icon/Icon";
import css from "./ModalBase.module.scss";

interface ModalBaseProps {
    title: string;
    onClose: () => void;
    footer?: React.ReactNode;
    children: React.ReactNode;
    open: boolean;
    hasCloseButton?: boolean;
}

const ModalBase: React.FC<ModalBaseProps> = ({
    onClose,
    title,
    footer,
    children,
    open,
    hasCloseButton = true,
}) => {
    if (!open) return null;

    return (
        <>
            <div className={css.backdrop} onClick={onClose}></div>
            <div className={css.modal}>
                {title.length > 0 && (
                    <div className={css.header}>
                        <p className={css.title}>{title}</p>
                        {hasCloseButton && (
                            <>
                                {onClose && (
                                    <button
                                        onClick={onClose}
                                        className={css.closeBtn}
                                    >
                                        <Icon path={crossIcon} />
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                )}
                <div className={css.content}>{children}</div>
                {footer && <div className={css.footer}>{footer}</div>}
            </div>
        </>
    );
};

export default ModalBase;
