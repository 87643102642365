import React from "react";
import css from "./MultiStep.module.scss";
import { useAppSelector } from "../../../app/hooks";

type StepProps = {
    label: string;
    status: "active" | "completed" | "inactive";
};

const Step: React.FC<StepProps> = ({ label, status }) => (
    <div className={`${css.step} ${css[status]}`}>
        <p>{label}</p>
        <div className={css.circle} />
    </div>
);

const MultiStep: React.FC = () => {
    const { currentTab } = useAppSelector((state) => state.tabs);

    const getStepStatus = (
        stepIndex: number
    ): "active" | "completed" | "inactive" => {
        switch (stepIndex) {
            case 0:
                return currentTab >= 1 && currentTab <= 3
                    ? "active"
                    : currentTab > 3
                    ? "completed"
                    : "inactive";
            case 1:
                return currentTab >= 4 && currentTab <= 7
                    ? "active"
                    : currentTab > 7
                    ? "completed"
                    : "inactive";
            case 2:
                return currentTab >= 8 && currentTab <= 10
                    ? "active"
                    : currentTab > 10
                    ? "completed"
                    : "inactive";
            case 3:
                return currentTab >= 11 && currentTab <= 16
                    ? "active"
                    : currentTab > 16
                    ? "completed"
                    : "inactive";
            case 4:
                return currentTab === 17 ? "active" : "inactive";
            default:
                return "inactive";
        }
    };

    return (
        <div className={css.multiStep}>
            <Step label="Project Setup" status={getStepStatus(0)} />
            <Step label="Configuring Resources" status={getStepStatus(1)} />
            <Step label="Timeline" status={getStepStatus(2)} />
            <Step label="Discounts" status={getStepStatus(3)} />
            <Step label="Overview" status={getStepStatus(4)} />
        </div>
    );
};

export default MultiStep;
