interface IconProps {
    path: string;
    className?: string;
}

const Icon: React.FC<IconProps> = ({ path, className }) => {
    return <img src={path} alt="" className={className} />;
};

export default Icon;
