import { useState, useEffect, useMemo } from "react";
import css from "./MobileExperienceTab.module.scss";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TabPanel from "../../TabPanel";
import { Button, Slider } from "@mui/material";
import {
    setExperienceById,
    setTabIndexById,
} from "../../../../features/Resources/resourceSlice";

interface TabProps {
    index: number;
    id: string;
}

const MobileExperienceTab: React.FC<TabProps> = ({ index, id }) => {
    const [experienceSliderValue, setExperienceSliderValue] = useState(1);
    const [activeButton, setActiveButton] = useState(1);
    const { currentResources } = useAppSelector((state) => state.resources);

    const currentResource = useMemo(() => {
        return currentResources.find((res) => res.id === id);
    }, [id, currentResources]);
    const dispatch = useAppDispatch();

    const onNext = () => {
        /* 
        disabling dispatch bcause we are not changing tab views 
        dispatch(
            setTabIndexById({
                id,
                tabIndex: 5,
            })
        ); */
        dispatch(
            setExperienceById({
                id,
                experience: experienceSliderValue,
            })
        );
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources.find((res) => res.id === id),
                        experience: experienceSliderValue,
                    },
                },
            })
        );
    }, [experienceSliderValue]);

    useEffect(() => {
        let current_resource = currentResources.find((res) => res.id === id);
        setExperienceSliderValue(current_resource?.experience || 1);

        let val = current_resource?.experience || 1;

        if (val >= 0 && val < 3) {
            setActiveButton(1);
        } else if (val < 6) {
            setActiveButton(2);
        } else if (val <= 7) {
            setActiveButton(3);
        } else {
            setActiveButton(4);
        }
    }, [experienceSliderValue]);

    //function to set expericne in local stage, its a common one previously its set function
    function onChangeExperienceSlider(value: number) {
        setExperienceSliderValue(value);

        dispatch(
            setExperienceById({
                id,
                experience: value,
            })
        );
    }

    return (
        <TabPanel index={4} value={index}>
            <div className={css.blockWrap}>
                <h1 className={css.title}>
                    How much experience should they have?
                </h1>
                <div className={css.experiencePriceBlock}>
                    <p className={css.title}>
                        <span className={css.titleAmount}>
                            ${" "}
                            {Number(
                                currentResource?.computedPrice
                            ).toLocaleString(undefined, {
                                maximumFractionDigits: 1,
                            })}
                        </span>
                        <span className={css.subtext}>/ month</span>
                    </p>
                    <p className={css.experienceRow}>
                        <span className={css.orange}>
                            {experienceSliderValue <= 7
                                ? experienceSliderValue
                                : "7+"}{" "}
                            {experienceSliderValue > 1 ? "years" : "year"}
                        </span>{" "}
                        of experience
                    </p>
                </div>
                <div className={css.sliderContainer}>
                    <span className={cn(css.sliderLimit, css.sliderLimitLeft)}>
                        1
                    </span>
                    <Slider
                        sx={{
                            display: "block",
                            margin: "0 auto",
                        }}
                        min={1}
                        max={8}
                        value={experienceSliderValue}
                        onChange={(e: any) => {
                            const val = e.target.value;

                            onChangeExperienceSlider(val);
                            if (val >= 0 && val < 3) {
                                setActiveButton(1);
                            } else if (val < 6) {
                                setActiveButton(2);
                            } else {
                                setActiveButton(3);
                            }
                        }}
                    />
                    <span className={cn(css.sliderLimit, css.sliderLimitRight)}>
                        7+
                    </span>
                </div>
                <div className={css.optionContainer}>
                    <div
                        className={cn(css.option, {
                            [css.activeOption]: activeButton === 1,
                        })}
                        onClick={() => {
                            setActiveButton(1);
                            onChangeExperienceSlider(1);
                        }}
                    >
                        Junior
                    </div>
                    <div
                        className={cn(css.option, {
                            [css.activeOption]: activeButton === 2,
                        })}
                        onClick={() => {
                            setActiveButton(2);
                            onChangeExperienceSlider(4);
                        }}
                    >
                        Intermediate
                    </div>
                    <div
                        className={cn(css.option, {
                            [css.activeOption]: activeButton === 3,
                        })}
                        onClick={() => {
                            setActiveButton(3);
                            onChangeExperienceSlider(6);
                        }}
                    >
                        Senior
                    </div>
                    <div
                        className={cn(css.option, {
                            [css.activeOption]: activeButton === 4,
                        })}
                        onClick={() => {
                            setActiveButton(4);
                            onChangeExperienceSlider(8);
                        }}
                    >
                        Expert
                    </div>
                </div>
                {/* <Button
                    color="primary"
                    onClick={onNext}
                    sx={{
                        padding: "8px 32px",
                        display: "block",
                        marginLeft: "auto",
                    }}
                >
                    Next
                </Button> */}
            </div>
        </TabPanel>
    );
};

export default MobileExperienceTab;
