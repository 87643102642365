import { useAppDispatch } from "../../app/hooks";
import { botImg } from "../../css/icons";
import { setIsBotMobileVisible } from "../../features/Tabs/tabSlice";
import Icon from "../Icon/Icon";
import css from "./BotMobile.module.scss";

const BotMobile: React.FC = () => {
    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(setIsBotMobileVisible(true));
    };

    return (
        <button onClick={onClick} className={css.button}>
            <Icon path={botImg} />
        </button>
    );
};

export default BotMobile;
