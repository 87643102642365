import React, { useState } from "react";
import Box from "@mui/material/Box";
import css from "./SelectedResources.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Icon from "../../../../Icon/Icon";
import {
    arrowLeftIcon,
    chevronIcon,
    vectorIcon,
    plusIcon,
} from "../../../../../css/icons";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";

import {
    setCurrentResourceIndex,
    setCurrentTab,
} from "../../../../../features/Tabs/tabSlice";
import {
    calculateWeeks,
    calculateWeeksDifference,
    formatAmountDifferenceWithSign,
    getExperienceLabel,
} from "../../../../../utils/helpers";
import { Resource } from "../../../../../features/Resources/resourceSlice";

import AddResourceModal from "../../../../AddResourceModal/AddResourceModal";

interface SelectedResourcesProps {
    resources: Resource[];
    onCloseDrawer: any;
}

type ResourceObject = {
    computedPrice?: number;
    count: number;
    price?: number;
    timeline?: {
        startWeek: Date;
        endWeek: Date;
    };
    weeklyWorkload?: number;
};

const SelectedResources: React.FC<SelectedResourcesProps> = ({
    resources,
    onCloseDrawer,
}) => {
    const dispatch = useAppDispatch();
    const [openNewResources, setOpenNewResources] = useState(false);

    //get budget
    const buget = useAppSelector((state) =>
        state.resources.budget?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
        })
    );

    //get calculated costs
    const calculateTotalCost = (resources: ResourceObject[]): string => {
        let totalCost = 0;
        const weeks = calculateWeeks();

        resources.forEach((resource) => {
            let cost = 0;

            if (resource.computedPrice) {
                cost = resource.computedPrice * resource.count;
            } else {
                const startWeek =
                    resource.timeline?.startWeek || weeks?.startWeek;
                const endWeek = resource.timeline?.endWeek || weeks?.endWeek;
                const weeklyWorkload = resource.weeklyWorkload || 0;
                const price = resource.price || 0;
                const count = resource.count;

                cost =
                    count *
                    price *
                    calculateWeeksDifference(startWeek, endWeek) *
                    weeklyWorkload *
                    8;
            }

            totalCost += cost;
        });

        return Number(totalCost).toLocaleString(undefined, {
            maximumFractionDigits: 0,
        });
    };

    //on back button pressed
    const onPrev = () => {
        onCloseDrawer("e");
        /* if (tabNumber === 0) {
            dispatch(setCurrentTab(0));
        } else {
            dispatch(setCurrentTab(currentTab - 1));
        } */
    };

    //get current resource index
    const currentResourceIndex = useAppSelector(
        (state) => state.tabs.currentResourceIndex
    );

    //this will toggle another drwer to add new resources
    const toggleDrawer =
        (open: boolean) =>
        (
            event: React.KeyboardEvent | React.MouseEvent,
            reason?: "backdropClick" | "escapeKeyDown"
        ) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setOpenNewResources(open); // Directly set the state
        };

    return (
        <>
            <Box sx={{ width: 360 }} role="presentation">
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                    <Box sx={{ display: "flex" }} className={css.container}>
                        <Box>
                            <button className={css.prevbutton} onClick={onPrev}>
                                <Icon path={arrowLeftIcon} />
                            </button>
                        </Box>
                        <Box>
                            <div className={css.mobileTitle}>
                                Selected Resources
                            </div>
                        </Box>
                        <Box></Box>
                    </Box>
                </Box>
                <div className={css.resourceTabContainer}>
                    <div className={css.budgetContainer}>
                        <p>
                            ${calculateTotalCost(resources)}
                            <span> / ${buget}</span>
                        </p>
                        <small>
                            {resources.length > 1 ? (
                                <>{resources.length} resources selected</>
                            ) : (
                                <>{resources.length} resource selected</>
                            )}
                        </small>
                    </div>
                    {resources.map((resource, index) => {
                        return (
                            <Accordion
                                key={`resource-tab-${resource?.title}-${index}`}
                                className={cn(css.accordion, {
                                    [css.selectedAccordion]:
                                        currentResourceIndex === index,
                                })}
                                sx={{
                                    "&.Mui-expanded": {
                                        m: "0 !important", // Remove margin-top when other Accordions are expanded
                                    },
                                }}
                                onClick={() => {
                                    dispatch(setCurrentResourceIndex(index));
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<Icon path={chevronIcon} />}
                                    aria-controls="panel1a-content"
                                    id={`resource-tab-${resource?.title}-${index}`}
                                    sx={{
                                        margin: "0 !important", // Remove margin
                                        "& .Mui-expanded": {
                                            margin: "0 !important", // Remove margin when expanded
                                        },
                                    }}
                                >
                                    <div className={css.resourceTabWrapper}>
                                        <div className={css.resourceTabLeft}>
                                            <div className={css.imageContainer}>
                                                <span
                                                    className={cn(
                                                        css.circle,
                                                        {
                                                            [css.done]:
                                                                resource?.costDetails,
                                                        },
                                                        {
                                                            [css.selected]:
                                                                !resource?.costDetails &&
                                                                currentResourceIndex ===
                                                                    index,
                                                        }
                                                    )}
                                                >
                                                    <div
                                                        className={
                                                            css.checkmark
                                                        }
                                                    >
                                                        ✔
                                                    </div>
                                                </span>
                                                <Icon path={vectorIcon} />
                                            </div>
                                            <div>
                                                <p
                                                    className={
                                                        css.resourceTitle
                                                    }
                                                >
                                                    {resource?.title}
                                                </p>
                                                <small
                                                    className={
                                                        css.resourceSubtitle
                                                    }
                                                >
                                                    {resource?.count}{" "}
                                                    {resource?.count > 1
                                                        ? "resources"
                                                        : "resource"}
                                                </small>
                                            </div>
                                        </div>
                                        <div className={css.resourceTabRight}>
                                            {resource?.costDetails && (
                                                <span
                                                    className={
                                                        css.resourceExperience
                                                    }
                                                >
                                                    {getExperienceLabel(
                                                        resource?.experience
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className={css.details}>
                                    {resource?.costDetails && (
                                        <div className={css.infoContainer}>
                                            <div className={css.info}>
                                                <p>
                                                    {getExperienceLabel(
                                                        resource?.experience
                                                    )}
                                                </p>
                                                <small
                                                    className={cn(css.money, {
                                                        [css.increase]:
                                                            Math.abs(
                                                                resources[
                                                                    currentResourceIndex
                                                                ]?.costDetails
                                                                    ?.experience_cost
                                                            ) > 0,
                                                    })}
                                                >
                                                    {formatAmountDifferenceWithSign(
                                                        resources[
                                                            currentResourceIndex
                                                        ]?.costDetails
                                                            ?.experience_cost
                                                    )}{" "}
                                                    <span>/month</span>
                                                </small>
                                            </div>
                                            {resource?.workFormat && (
                                                <div className={css.info}>
                                                    <p>
                                                        {resource?.workFormat}
                                                    </p>
                                                    <small
                                                        className={cn(
                                                            css.money,
                                                            {
                                                                [css.increase]:
                                                                    Math.abs(
                                                                        resources[
                                                                            currentResourceIndex
                                                                        ]
                                                                            ?.costDetails
                                                                            ?.work_format_cost
                                                                    ) > 0,
                                                            }
                                                        )}
                                                    >
                                                        {formatAmountDifferenceWithSign(
                                                            resources[
                                                                currentResourceIndex
                                                            ]?.costDetails
                                                                ?.work_format_cost
                                                        )}{" "}
                                                        <span>/month</span>
                                                    </small>
                                                </div>
                                            )}
                                            <div className={css.info}>
                                                <p>
                                                    {resource?.weeklyWorkload *
                                                        8}
                                                    hr/week
                                                </p>
                                                <small
                                                    className={cn(css.money, {
                                                        [css.increase]:
                                                            Math.abs(
                                                                resources[
                                                                    currentResourceIndex
                                                                ]?.costDetails
                                                                    ?.days_per_week_cost
                                                            ) > 0,
                                                    })}
                                                >
                                                    {formatAmountDifferenceWithSign(
                                                        Math.abs(
                                                            resources[
                                                                currentResourceIndex
                                                            ]?.costDetails
                                                                ?.days_per_week_cost
                                                        )
                                                    )}{" "}
                                                    <span>/month</span>
                                                </small>
                                            </div>
                                            {resource?.region && (
                                                <div className={css.info}>
                                                    <p>{resource?.region}</p>
                                                    <small
                                                        className={cn(
                                                            css.money,
                                                            {
                                                                [css.increase]:
                                                                    Math.abs(
                                                                        resources[
                                                                            currentResourceIndex
                                                                        ]
                                                                            ?.costDetails
                                                                            ?.resource_region_cost
                                                                    ) > 0,
                                                            }
                                                        )}
                                                    >
                                                        {formatAmountDifferenceWithSign(
                                                            resources[
                                                                currentResourceIndex
                                                            ]?.costDetails
                                                                ?.resource_region_cost
                                                        )}{" "}
                                                        <span>/month</span>
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>

                <button
                    className={css.addResource}
                    onClick={() => setOpenNewResources(true)}
                >
                    <Icon path={plusIcon} />
                    Add another resource
                </button>
            </Box>
            <AddResourceModal
                openNewResources={openNewResources}
                toggleDrawer={toggleDrawer}
            />
        </>
    );
};

export default SelectedResources;
