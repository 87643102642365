import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Box } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ENVIROMENT } from "../../constants";
import {
    calendarIcon,
    chevronIcon,
    coinIcon,
    copyIcon,
    dotsLargeIcon,
    arrowLeftIcon,
} from "../../css/icons";
import Icon from "../Icon/Icon";
import MultiStep from "./MultiStep/MultiStep";
import css from "./TopNavigationBar.module.scss";
import CloseButton from "../CloseButton/CloseButton";
import ModalBase from "../Modals/ModalBase";
import CustomInput from "../../components/Input/Input";
import { Button, Select, Input } from "@mui/material";
import ResourceService from "../../services/resource.service";
import {
    completeResources,
    setIsSaveModalOpen,
    setName,
    setProjectBudget,
    setProjectDuration,
} from "../../features/Resources/resourceSlice";
import {
    calculateWeeksDifference,
    formatDate,
    getExperienceLabel,
} from "../../utils/helpers";
import { setCurrentTab } from "../../features/Tabs/tabSlice";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 12,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const resourceService = new ResourceService();

interface TopNavigationProps {
    title?: string;
    tabNumber?: number;
}

const TopNavigationBar: React.FC<TopNavigationProps> = ({
    title,
    tabNumber,
}) => {
    const dispatch = useAppDispatch();
    const { currentTab } = useAppSelector((state) => state.tabs);
    const [copied, setCopied] = useState(false);
    const { name, currency, budget, duration, currentResources } =
        useAppSelector((state) => state.resources);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [editIsOpen, setEditIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [quotaURL, setQuotaURL] = useState("");
    const [emailVal, setEmailVal] = useState("");

    const [budgetValue, setBudgetValue] = useState<number | string>(
        budget ? budget : 0
    );
    const [selectCurrency, setSelectCurrency] = useState(currency);

    const [durationValue, setDurationValue] = useState<number>(
        duration ? duration : 0
    );
    const [durationUnit, setDurationUnit] = useState<"months" | "years">(
        "months"
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onPrev = () => {
        if (tabNumber) {
            dispatch(setCurrentTab(tabNumber));
        } else {
            dispatch(setCurrentTab(currentTab - 1));
        }
    };

    const onEdit = () => {
        if (budgetValue === undefined) return;

        if (budgetValue !== undefined) {
            dispatch(setProjectBudget(budgetValue));
        }

        if (durationValue !== undefined) {
            dispatch(
                setProjectDuration(
                    durationValue * (durationUnit === "years" ? 12 : 1)
                )
            );
        }
        setEditIsOpen(false);
    };

    const onSaveAsQuota = async () => {
        if (name && emailVal.length > 0) {
            await resourceService.sendUrlToEmail(name, emailVal, quotaURL);
            setIsOpen(false);
            setEmailVal("");
        }

        setIsOpen(false);
    };

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove commas and parse the input as a number
        const inputValue = event.target.value.replace(/,/g, "");
        const parsedValue = parseFloat(inputValue);

        // Check if parsing was successful and update the state accordingly
        if (!isNaN(parsedValue)) {
            setBudgetValue(parsedValue); // Set as a number
        } else {
            setBudgetValue(0); // Set to 0 for invalid input
        }
    };

    useEffect(() => {
        if (name && isOpen) {
            resourceService.saveAsQuota(name).then((res) => {
                setQuotaURL(
                    res.data.message[
                        ENVIROMENT === "STAGE"
                            ? "staging_url"
                            : "production_url"
                    ]
                );
            });
        }
    }, [isOpen]);

    const [path, setPath] = useState("");
    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    const onSave = () => {
        handleClose();
        setIsOpen(true);
        const resource_id =
            name && name.length > 0
                ? name
                : path && path.length > 0
                ? path
                : "";
        resourceService
            .addResourceAsDraft(
                resource_id,
                currency || "USD",
                budget || 10000,
                duration || 8,
                currentTab,
                currentResources.map((res) => ({
                    role: res.role,
                    number: res.count,
                    experience: getExperienceLabel(res.experience),
                    duration: calculateWeeksDifference(
                        res.timeline?.startWeek,
                        res.timeline?.endWeek
                    ),
                    workload: res.weeklyWorkload,
                    start_week: 0,
                    end_week: 4,

                    title: res.title,
                    work_format: res.workFormat,
                    work_location: res.location,
                    resource_location: res.region,
                    start_week_date: formatDate(res.timeline?.startWeek),
                    end_week_date: formatDate(res.timeline?.endWeek),
                })),
                "No",
                "Monthly",
                "No",
                "0%",
                "0%"
            )
            .then((res) => {
                dispatch(completeResources());
                dispatch(setName(res.data.message));
                dispatch(setIsSaveModalOpen(true));
                resourceService.saveAsQuota(res.data.message).then((res) => {
                    setQuotaURL(
                        res.data.message[
                            ENVIROMENT === "STAGE"
                                ? "staging_url"
                                : "production_url"
                        ]
                    );
                });
            });
    };

    return (
        <>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <div className={css.container}>
                    <ModalBase
                        open={editIsOpen}
                        onClose={() => setEditIsOpen(false)}
                        title="Edit Project"
                        footer={
                            <div className={css.footer}>
                                <Button
                                    color="secondary"
                                    onClick={() => setEditIsOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={onEdit}>
                                    Save Changes
                                </Button>
                            </div>
                        }
                    >
                        <div className={css.modalContent}>
                            <div className={css.questionContainer}>
                                <h1 className={css.title}>Project Budget</h1>
                                <div className={css.inputContainer}>
                                    <Icon path={coinIcon} />
                                    <Input
                                        sx={{ width: "325px" }}
                                        value={budgetValue.toLocaleString()} // Display with commas
                                        onChange={handleAmountChange}
                                        placeholder="Enter an amount"
                                        disableUnderline
                                    />
                                    <Select
                                        variant="standard"
                                        sx={{ marginLeft: "30px" }}
                                        disableUnderline
                                        defaultValue="USD"
                                        IconComponent={(props) => (
                                            <Icon
                                                path={chevronIcon}
                                                {...props}
                                            />
                                        )}
                                        value={selectCurrency}
                                        onChange={
                                            (e) =>
                                                setSelectCurrency(
                                                    e.target.value
                                                )
                                            // dispatch(setCurrency(e.target.value))
                                        }
                                    >
                                        <MenuItem value="USD">USD</MenuItem>
                                        <MenuItem value="EUR">EUR</MenuItem>
                                        <MenuItem value="RUB">RUB</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <div className={css.questionContainer}>
                                <h1 className={css.title}>Project Duration</h1>
                                <div className={css.inputContainer}>
                                    <Icon path={calendarIcon} />
                                    <Input
                                        sx={{ width: "300px" }}
                                        value={durationValue}
                                        onChange={(e) =>
                                            setDurationValue(+e.target.value)
                                        }
                                        placeholder="Enter duration"
                                        disableUnderline
                                    />
                                    <Select
                                        variant="standard"
                                        value={durationUnit}
                                        sx={{ marginLeft: "30px" }}
                                        disableUnderline
                                        defaultValue={"months"}
                                        onChange={(e) => {
                                            setDurationUnit(
                                                e.target.value as
                                                    | "months"
                                                    | "years"
                                            );
                                        }}
                                        IconComponent={(props) => (
                                            <Icon
                                                path={chevronIcon}
                                                {...props}
                                            />
                                        )}
                                    >
                                        <MenuItem value={"months"}>
                                            months
                                        </MenuItem>
                                        <MenuItem value={"years"}>
                                            years
                                        </MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </ModalBase>
                    <ModalBase
                        open={isOpen}
                        onClose={() => {
                            setIsOpen(false);
                        }}
                        title="Save as Quota"
                        footer={
                            <div className={css.footerBtns}>
                                <Button
                                    color="secondary"
                                    sx={{
                                        margin: "16px 0",
                                    }}
                                    onClick={() => setIsOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    sx={{
                                        margin: "16px 0",
                                    }}
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                >
                                    Done
                                </Button>
                            </div>
                        }
                    >
                        <div className={css.modalContent}>
                            <div className={css.sendMail}>
                                <CustomInput
                                    fieldName="email"
                                    placeholder="Enter your email"
                                    label="Enter your email to send it"
                                    type="text"
                                    value={emailVal}
                                    onChange={(e: any) => {
                                        setEmailVal(e.target.value);
                                    }}
                                />
                                <Button
                                    color="primary"
                                    sx={{
                                        width: 144,
                                        height: 48,
                                    }}
                                    onClick={onSaveAsQuota}
                                >
                                    Send Now
                                </Button>
                            </div>
                            <p className={css.modalOr}></p>
                            <CustomInput
                                fieldName="link"
                                placeholder=""
                                label="Save as Link"
                                type="text"
                                value={quotaURL}
                                disabled
                                postfix={
                                    copied ? (
                                        <span className={css.copied}>
                                            copied
                                        </span>
                                    ) : (
                                        <button
                                            type="button"
                                            className={css.unstyledBtn}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    quotaURL
                                                );
                                                setCopied(true);
                                                setTimeout(() => {
                                                    setCopied(false);
                                                }, 2000);
                                            }}
                                        >
                                            <Icon path={copyIcon} />
                                        </button>
                                    )
                                }
                            />
                        </div>
                    </ModalBase>

                    <CloseButton />
                    <MultiStep />

                    <button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        className={cn(css.closeBtn, {
                            [css["hidden"]]: currentTab === 1,
                        })}
                    >
                        <Icon path={dotsLargeIcon} />
                    </button>
                    <StyledMenu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setEditIsOpen(true);
                            }}
                        >
                            Edit Project
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onSave();
                            }}
                        >
                            Save Draft
                        </MenuItem>
                    </StyledMenu>
                </div>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <Box sx={{ display: "flex" }} className={css.container}>
                    <Box>
                        <button className={css.prevbutton} onClick={onPrev}>
                            <Icon path={arrowLeftIcon} />
                        </button>
                    </Box>
                    <Box>
                        <div className={css.mobileTitle}>{title}</div>
                    </Box>
                    <Box></Box>
                </Box>
            </Box>
        </>
    );
};

export default TopNavigationBar;
