import css from "./FrameAgreementTabMobile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { setCurrentTab } from "../../../../../../features/Tabs/tabSlice";
import { Button } from "@mui/material";
import cn from "classnames";
import { useEffect, useState } from "react";
import { setFrameAgreement } from "../../../../../../features/Discounts/discountSlice";

interface TabProps {
    index: number;
}

const FrameAgreementTabMobile: React.FC<TabProps> = ({ index }) => {
    const dispatch = useAppDispatch();
    const [frameAgreementVal, setFrameAgreementVal] = useState(false);
    const {
        budget,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const {
        total_discount,
        discounts: { frame_agreement_percentage_can_avail },
        discountPayload,
    } = useAppSelector((state) => state.discounts);

    const onGoBack = () => {
        dispatch(setCurrentTab(14));
    };

    const onNext = () => {
        dispatch(setCurrentTab(16));
    };

    const getMaxDiscount = () => {
        setFrameAgreementVal(true);
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_discount", {
                detail: {
                    total_amount: projectCost,
                    discount: {
                        ...discountPayload,
                        frame_agreement: frameAgreementVal ? "Yes" : "No",
                    },
                },
            })
        );
        dispatch(setFrameAgreement(frameAgreementVal ? "Yes" : "No"));
    }, [frameAgreementVal]);

    return (
        <>
            <div className={css.wrapper}>
                <div className={css.content}>
                    <h1 className={css.title}>
                        Can you offer us a frame agreement reaching over the
                        next 3 years?
                    </h1>
                    <div className={css.cardFlex}>
                        <div
                            className={cn(css.card, css.cardYes, {
                                [css.selectedCard]: frameAgreementVal,
                            })}
                            onClick={() => setFrameAgreementVal(true)}
                        >
                            <p>Yes</p>
                            <p className={css.greenSubtext}>Save up to 2%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardNo, {
                                [css.selectedCard]: !frameAgreementVal,
                            })}
                            onClick={() => setFrameAgreementVal(false)}
                        >
                            <p>No</p>
                            <p className={css.redSubtext}>
                                I don’t need a discount
                            </p>
                        </div>
                    </div>
                    <div className={css.result}>
                        <p className={css.resultSubtext}>You will save</p>
                        <p>
                            <span className={css.resultGreen}>
                                {" "}
                                ${" "}
                                {Number(total_discount).toLocaleString(
                                    undefined,
                                    {
                                        maximumFractionDigits: 1,
                                    }
                                )}{" "}
                            </span>
                            <span className={css.resultSubtext}> /month</span>
                        </p>
                    </div>
                </div>
                {/* <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            padding: "12px",
                        }}
                        fullWidth
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </div>
                <div className={css.botMobileContainer}>
                    <BotMobile />
                </div> */}
            </div>
        </>
    );
};

export default FrameAgreementTabMobile;
