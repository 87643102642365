import { useState, useEffect } from "react";
import css from "./WeeklyWorkloadTab.module.scss";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import TabPanel from "../../TabPanel";
import Icon from "../../../Icon/Icon";
import {
    chevronLeftIcon,
    chevronRightIcon,
    youpalLogo,
} from "../../../../css/icons";
import { Button, Slider } from "@mui/material";
import { Grey } from "../../../../mui/colors";
import ResourcesCarousel from "../../../ResourcesCarousel/ResourcesCarousel";
import CloseButton from "../../../CloseButton/CloseButton";
import {
    computeProjectTotals,
    setCurrentResources,
    setResourceTabIndex,
    setWeeklyWorkload,
} from "../../../../features/Resources/resourceSlice";
import TopNavigationBar from "../../../TopNavigationBar/TopNavigationBar";
import PropertiesCarousel from "../../../PropertiesCarousel/PropertiesCarousel";
import ModalBase from "../../../Modals/ModalBase";
import { colorSet } from "../../../../utils/helpers";

interface TabProps {
    index: number;
}

const WeeklyWorkloadTab: React.FC<TabProps> = ({ index }) => {
    const [open, setOpen] = useState(false);
    const [workloadSliderValue, setWorkloadSliderValue] = useState(40);

    const { currentResources } = useAppSelector((state) => state.resources);
    const { currentResourceIndex, currentTab } = useAppSelector(
        (state) => state.tabs
    );

    const dispatch = useAppDispatch();

    const onNext = () => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: 7,
            })
        );
        dispatch(setCurrentTab(7));
        // dispatch(
        //     setWeeklyWorkload({
        //         index: currentResourceIndex,
        //         weeklyWorkload: workloadSliderValue / 8,
        //     })
        // );
    };

    const onPrevious = () => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: 5,
            })
        );
        dispatch(setCurrentTab(5));
    };

    const onDone = () => {
        const isAllResourcesDone = currentResources.every(
            (res) => res?.costDetails
        );

        if (isAllResourcesDone) {
            addColor();
            dispatch(setCurrentTab(8));
            // dispatch(completeResources());
            dispatch(computeProjectTotals());
        } else {
            setOpen(true);
        }
    };

    const addColor = () => {
        for (let resource of currentResources) {
            document.dispatchEvent(
                new CustomEvent("update_price_details", {
                    detail: {
                        resource: {
                            ...resource,
                            timeline: {
                                startWeek: resource.timeline.startWeek,
                                endWeek: resource.timeline.endWeek,
                            },
                        },
                    },
                })
            );
        }
        setTimeout(() => {
            dispatch(setCurrentTab(8));
        }, 1000);
        dispatch(
            setCurrentResources([
                ...currentResources.map((role, index) => {
                    return {
                        ...role,
                        color: colorSet[index % colorSet.length],
                    };
                }),
            ])
        );
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        weeklyWorkload: workloadSliderValue / 8,
                    },
                },
            })
        );
        document.dispatchEvent(
            new CustomEvent("update_price_details", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        weeklyWorkload: workloadSliderValue / 8,
                    },
                },
            })
        );
        if (currentResources && currentResourceIndex >= 0) {
            dispatch(
                setWeeklyWorkload({
                    index: currentResourceIndex,
                    weeklyWorkload: workloadSliderValue / 8,
                })
            );
        }
    }, [workloadSliderValue]);

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        weeklyWorkload: workloadSliderValue,
                    },
                },
            })
        );
    }, []);

    useEffect(() => {
        if (currentResources.length > 0 && currentResourceIndex >= 0) {
            const weeklyWorkload =
                currentResources[currentResourceIndex]?.weeklyWorkload;
            setWorkloadSliderValue(weeklyWorkload * 8);
        }
    }, [currentResourceIndex, currentTab]);

    return (
        <TabPanel index={6} value={index}>
            <ModalBase
                onClose={() => setOpen(false)}
                open={open}
                title="Are you sure you want to proceed?"
            >
                <p>You haven't added details for other talents.</p>
                <div className={cn(css.flexSB, css.modalBtns)}>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => setOpen(false)}
                    >
                        Add Details
                    </Button>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => {
                            addColor();
                            // dispatch(setCurrentTab(8));
                            setOpen(false);
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </ModalBase>
            <TopNavigationBar />

            <div className={css.wrapper}>
                {/* <div className={css.header}>
                    <div className={css.logoFlex}>
                        <CloseButton />
                        <Icon path={youpalLogo} />
                    </div>
                    <Button
                        color="primary"
                        onClick={onDone}
                        sx={{
                            padding: "6px 32px",
                            borderRadius: "6px",
                            backgroundColor: Grey[900],
                            "&:hover": {
                                backgroundColor: Grey[900],
                            },
                        }}
                    >
                        Done
                    </Button>
                </div> */}
                <div className={css.resourceCarouselWrapper}>
                    <ResourcesCarousel resources={currentResources} />
                </div>
                <div className={css.mainConatiner}>
                    <PropertiesCarousel resources={currentResources} />
                    <div className={css.innerConatiner}>
                        <div className={css.leftButton}>
                            <button
                                className={css.nextIcon}
                                onClick={onPrevious}
                            >
                                <Icon path={chevronLeftIcon} />
                            </button>
                        </div>

                        <div>
                            <h1 className={css.title}>
                                How much work do you expect from them weekly?
                            </h1>
                            <div className={css.experiencePriceBlock}>
                                <p className={css.title}>
                                    ${" "}
                                    {Number(
                                        currentResources[currentResourceIndex]
                                            ?.computedPrice
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                    <span className={css.subtext}>/ month</span>
                                </p>
                                <p className={css.experienceRow}>
                                    <span className={css.orange}>
                                        {workloadSliderValue} hours
                                    </span>{" "}
                                    a week
                                </p>
                            </div>
                            <div className={css.sliderContainer}>
                                <span
                                    className={cn(
                                        css.sliderLimit,
                                        css.sliderLimitLeft
                                    )}
                                >
                                    8 hours
                                </span>
                                <Slider
                                    sx={{
                                        display: "block",
                                        margin: "0 auto",
                                    }}
                                    min={8}
                                    max={40}
                                    step={8}
                                    value={workloadSliderValue}
                                    onChange={(e: any) =>
                                        setWorkloadSliderValue(e.target.value)
                                    }
                                />
                                <span
                                    className={cn(
                                        css.sliderLimit,
                                        css.sliderLimitRight
                                    )}
                                >
                                    40 hours
                                </span>
                            </div>
                            <div className={css.optionContainer}>
                                {new Array(5).fill(0).map((num, index) => {
                                    return (
                                        <div
                                            key={`desktop-workload${num}${index}`}
                                            className={cn(css.option, {
                                                [css.selectedOption]:
                                                    (index + 1) * 8 ===
                                                    workloadSliderValue,
                                            })}
                                            onClick={() => {
                                                setWorkloadSliderValue(
                                                    (index + 1) * 8
                                                );
                                            }}
                                        >
                                            {index + 1} day/week
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className={css.rightButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronRightIcon} />
                            </button>
                        </div>
                    </div>
                    <div className={css.doneButton}>
                        <Button
                            color="primary"
                            onClick={onDone}
                            sx={{
                                padding: "12px 24px",
                            }}
                        >
                            Finish Resource Configuration
                        </Button>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default WeeklyWorkloadTab;
