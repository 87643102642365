import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import ContactSupportTabDesktop from "./ContactSupportTabDesktop/ContactSupportTabDesktop";
import ContactSupportTabMobile from "./ContactSupportTabMobile/ContactSupportTabMobile";

interface TabProps {
    index: number;
}

const ContactSupportTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <ContactSupportTabDesktop index={index} />
    ) : (
        <ContactSupportTabMobile index={index} />
    );
};

export default ContactSupportTab;
