import css from "./DiscountDashboardTabDesktop.module.scss";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import { whitePlusIcon, youpalLogo } from "../../../../../css/icons";
import Icon from "../../../../Icon/Icon";
import RoundedSlider from "../../../../RoundedSlider/RoundedSlider";
import ResourceDropdown from "../../../../ResourceDropdown/ResourceDropdown";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { computeProjectTotals } from "../../../../../features/Resources/resourceSlice";
import ProjectPrice from "../../../../ProjectPrice/ProjectPrice";

interface TabProps {
    index: number;
}

const colors = [
    ["#039855", "#09824c"],
    ["#2E90FA", "#1F7BDF"],
    ["#1F7BDF", "#196bc2"],
];

const DiscountDashboardTabDesktop: React.FC<TabProps> = ({ index }) => {
    const dispatch = useAppDispatch();
    const {
        resources,
        budget,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);

    const onShowDiscounts = () => {
        dispatch(setCurrentTab(11));
        dispatch(computeProjectTotals());
    };

    const onAddResource = () => {
        dispatch(setCurrentTab(3));
    };

    useEffect(() => {
        if (resources.some((res) => !res.costDetails)) {
            for (let resource of resources) {
                document.dispatchEvent(
                    new CustomEvent("update_price_details_saved", {
                        detail: {
                            resource,
                        },
                    })
                );
            }
        }
    }, [resources]);

    return (
        <TabPanel index={10} value={index}>
            <div className={css.wrapper}>
                <div className={css.header}>
                    <Icon path={youpalLogo} />
                </div>
                <p className={css.titleOpacity}>Get Your Talent</p>
                <div className={css.roundedSliderContainer}>
                    {budget ? (
                        <RoundedSlider current={projectCost} total={budget} />
                    ) : (
                        <ProjectPrice />
                    )}
                </div>
                <div className={css.subheader}>
                    Talent
                    <button
                        className={css.addResourceBtn}
                        onClick={onAddResource}
                    >
                        <Icon path={whitePlusIcon} />
                        Add Resource
                    </button>
                </div>
                <div className={css.resourceDropdownFlex}>
                    {resources.map((res, index) => {
                        const colorPair = colors[index % colors.length];
                        return (
                            <ResourceDropdown
                                key={index}
                                count={res.count}
                                title={res.title}
                                resources={new Array(res.count).fill(null)}
                                budgetUsed={
                                    budget
                                        ? (res.count *
                                              res.computedPrice *
                                              100) /
                                          budget
                                        : (res.count *
                                              res.computedPrice *
                                              100) /
                                          projectCost
                                }
                                total={res.computedPrice}
                                id={res.id}
                                colors={colorPair}
                            />
                        );
                    })}
                </div>
                <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            padding: "10px 24px",
                        }}
                        onClick={onShowDiscounts}
                    >
                        Show me the Discounts!
                    </Button>
                </div>
            </div>
        </TabPanel>
    );
};

export default DiscountDashboardTabDesktop;
