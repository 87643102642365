export const Grey = {
    50: "#F9FAFB",
    200: "#E4E7EC",
    300: "#D0D5DD",
    500: "#667085",
    700: "#344054",
    900: "#101828",
};

export const Orange = {
    50: "#FFF6ED",
    200: "#FDDCAB",
    400: "#FD853A",
};

export const Indigo = {
    50: "#EEF4FF",
    200: "#C7D7FE",
    500: "#6172F3",
};

export const Warning = {
    200: "#FEDF89",
    400: "#FDB022",
    500: "#F79009",
};

export const Error = {
    600: "#D92D20",
};
