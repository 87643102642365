import { useState, useEffect } from "react";
import css from "./AUseCreditCardPage.module.scss";
import TabPanel from "../../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { setCurrentTab } from "../../../../../../features/Tabs/tabSlice";
import { Button } from "@mui/material";
import cn from "classnames";
import DiscountsMobileCommon from "../../../../../DiscountsMobileCommon/DiscountsMobileCommon";
import BotMobile from "../../../../../BotMobile/BotMobile";
import {
    setCreditCard,
    setCreditCardDuration,
} from "../../../../../../features/Discounts/discountSlice";

interface TabProps {
    index: number;
}

const AUseCreditCardPage: React.FC<TabProps> = ({ index }) => {
    const {
        discounts: { credit_card_percentage_can_avail },
        total_discount,
        discountPayload,
    } = useAppSelector((state) => state.discounts);
    const {
        budget,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const dispatch = useAppDispatch();
    const [yes, setYes] = useState(false);
    const [period, setPeriod] = useState<"Weekly" | "Bi-weekly" | "Monthly">(
        "Bi-weekly"
    );
    const onGoBack = () => {
        dispatch(setCurrentTab(8));
    };

    const onNext = () => {
        dispatch(setCurrentTab(12));
    };

    const getMaxDiscount = () => {
        setYes(true);
        setPeriod("Weekly");
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_discount", {
                detail: {
                    total_amount: projectCost,
                    discount: {
                        ...discountPayload,
                        credit_card: yes ? "Yes" : "No",
                        credit_card_duration: period,
                    },
                },
            })
        );
        dispatch(setCreditCard(yes ? "Yes" : "No"));
        dispatch(setCreditCardDuration(period));
    }, [yes, period]);

    return (
        <>
            <div className={css.wrapper}>
                {/* <DiscountsMobileCommon
                    current={projectCost}
                    total={budget}
                    onCancel={() => {}}
                    onGoBack={onGoBack}
                    saving={
                        (credit_card_percentage_can_avail * projectCost) / 100
                    }
                    onGetDiscount={getMaxDiscount}
                /> */}
                <div className={css.content}>
                    <h1 className={css.title}>
                        Do you want to use your credit card for payments?
                    </h1>
                    <div className={css.cardFlex}>
                        <div
                            className={cn(css.card, css.cardYes, {
                                [css.selectedCard]: yes,
                            })}
                            onClick={() => setYes(true)}
                        >
                            <p>Yes</p>
                            <p className={css.greenSubtext}>Save up to 3%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardNo, {
                                [css.selectedCard]: !yes,
                            })}
                            onClick={() => setYes(false)}
                        >
                            <p>No</p>
                            <p className={css.redSubtext}>
                                I don’t need a discount
                            </p>
                        </div>
                    </div>
                    {yes && (
                        <>
                            <h3 className={css.subtitle}>
                                How often do you want to pay via card?
                            </h3>
                            <div className={css.cardFlex}>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                period === "Weekly",
                                        }
                                    )}
                                    onClick={() => setPeriod("Weekly")}
                                >
                                    <p>Weekly</p>
                                    <p className={css.greenSubtext}>
                                        Save 2.0%
                                    </p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                period === "Bi-weekly",
                                        }
                                    )}
                                    onClick={() => setPeriod("Bi-weekly")}
                                >
                                    <p>Bi-weekly</p>
                                    <p className={css.yellowSubtext}>
                                        Save 1.0%
                                    </p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                period === "Monthly",
                                        }
                                    )}
                                    onClick={() => setPeriod("Monthly")}
                                >
                                    <p>Monthly</p>
                                    <p className={css.greySubtext}>Save 0.0%</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={css.result}>
                    <p className={css.resultSubtext}>You will save</p>
                    <p>
                        <span className={css.resultGreen}>
                            {" "}
                            ${" "}
                            {Number(total_discount).toLocaleString(undefined, {
                                maximumFractionDigits: 1,
                            })}{" "}
                        </span>
                        <span className={css.resultSubtext}> /month</span>
                    </p>
                </div>
                {/* <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            padding: "12px",
                        }}
                        onClick={onNext}
                        fullWidth
                    >
                        Next
                    </Button>
                </div> */}
                <div className={css.botMobileContainer}>
                    <BotMobile />
                </div>
            </div>
        </>
    );
};

export default AUseCreditCardPage;
