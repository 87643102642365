import { useEffect, useMemo, useState } from "react";
import {
    LocationOnOutlined,
    StarBorderOutlined,
    Computer,
    AccessTime,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import css from "./PropertiesCarousel.module.scss";
import cn from "classnames";
import Icon from "../Icon/Icon";
import { trashIcon } from "../../css/icons";
import {
    Resource,
    setCurrentResources,
    setResourceTabIndex,
} from "../../features/Resources/resourceSlice";
import { setCurrentTab } from "../../features/Tabs/tabSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCurrentResourceIndex } from "../../features/Tabs/tabSlice";
import {
    formatAmountDifferenceWithSign,
    getExperienceLabel,
} from "../../utils/helpers";
import ModalBase from "../Modals/ModalBase";

interface ResourcesCarouselProps {
    resources: Resource[];
}

const PropertiesCarousel: React.FC<ResourcesCarouselProps> = ({
    resources,
}) => {
    const [open, setOpen] = useState(false);
    const [resourceList, setResourceList] = useState([...resources]);

    const currentResourceIndex = useAppSelector(
        (state) => state.tabs.currentResourceIndex
    );
    const dispatch = useAppDispatch();

    const handleDelete = () => {
        const newResourceList = [...resources];
        newResourceList.splice(currentResourceIndex, 1);
        setResourceList(newResourceList);
        dispatch(setCurrentResources(newResourceList));
        dispatch(
            newResourceList.length !== 0
                ? currentResourceIndex !== 0
                    ? setCurrentResourceIndex(currentResourceIndex - 1)
                    : setCurrentResourceIndex(0)
                : setCurrentTab(3)
        );

        setOpen(false);
    };

    const onDelete = () => {
        handleDelete();
    };

    const onClose = () => {
        setOpen(false);
    };

    const onTabClick = (index: number) => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: index,
            })
        );
        dispatch(setCurrentTab(index));
    };

    return (
        <div className={cn(css.wrapper, "resource-tab-container")}>
            <ModalBase
                title="Delete Resource?"
                onClose={() => setOpen(false)}
                footer={
                    <div className={css.modalButtons}>
                        <Button color="secondary" fullWidth onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            className={css.deleteButton}
                            fullWidth
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    </div>
                }
                open={open}
                hasCloseButton={false}
            >
                <p className={css.modalText}>
                    Are you sure you want to delete sss
                    <b>{resources[currentResourceIndex]?.title}</b> resource?
                </p>
            </ModalBase>
            <div className={css.resourceTitleContainer}>
                <div className={css.resourceLeft}>
                    <h2>{resources[currentResourceIndex]?.title}</h2>
                    <p>{resources[currentResourceIndex]?.count} resource</p>
                </div>
                <div className={css.resourceRight}>
                    <h1>
                        ${" "}
                        {Number(
                            resources[currentResourceIndex]?.count *
                                resources[currentResourceIndex]?.computedPrice
                        ).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                        <span>/month</span>
                    </h1>
                    <button onClick={() => setOpen(true)}>
                        <Icon path={trashIcon} />
                    </button>
                </div>
            </div>
            <div className={css.resourceTabWrapper}>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]:
                            resources[currentResourceIndex]?.tabIndex === 4,
                    })}
                    onClick={() => onTabClick(4)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]:
                                        resources[currentResourceIndex]
                                            ?.tabIndex === 4,
                                })}
                            >
                                <StarBorderOutlined />
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Experience</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {getExperienceLabel(
                                        resources[currentResourceIndex]
                                            ?.experience
                                    )}
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                resources[currentResourceIndex]
                                                    ?.costDetails
                                                    ?.experience_cost
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        resources[currentResourceIndex]
                                            ?.costDetails?.experience_cost
                                    )}{" "}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]:
                            resources[currentResourceIndex]?.tabIndex === 5,
                    })}
                    onClick={() => onTabClick(5)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]:
                                        resources[currentResourceIndex]
                                            ?.tabIndex === 5,
                                })}
                            >
                                <Computer />
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Work Type</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {resources[currentResourceIndex]
                                        ?.workFormat === "remote"
                                        ? "Remote"
                                        : "On Site"}
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                resources[currentResourceIndex]
                                                    ?.costDetails
                                                    ?.work_format_cost
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        resources[currentResourceIndex]
                                            ?.costDetails?.work_format_cost
                                    )}{" "}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]:
                            resources[currentResourceIndex]?.tabIndex === 6,
                    })}
                    onClick={() => onTabClick(6)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]:
                                        resources[currentResourceIndex]
                                            ?.tabIndex === 6,
                                })}
                            >
                                <AccessTime />
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Work Load</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {resources[currentResourceIndex]
                                        ?.weeklyWorkload * 8}
                                    hr/week
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                resources[currentResourceIndex]
                                                    ?.costDetails
                                                    ?.days_per_week_cost
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        Math.abs(
                                            resources[currentResourceIndex]
                                                ?.costDetails
                                                ?.days_per_week_cost
                                        )
                                    )}{" "}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]:
                            resources[currentResourceIndex]?.tabIndex === 7,
                    })}
                    onClick={() => onTabClick(7)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]:
                                        resources[currentResourceIndex]
                                            ?.tabIndex === 7,
                                })}
                            >
                                <LocationOnOutlined />
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Location</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {resources[currentResourceIndex]?.region
                                        ? resources[currentResourceIndex]
                                              ?.region
                                        : "Remote"}
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                resources[currentResourceIndex]
                                                    ?.costDetails
                                                    ?.resource_region_cost
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        resources[currentResourceIndex]
                                            ?.costDetails?.resource_region_cost
                                    )}{" "}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertiesCarousel;
