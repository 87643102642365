import { useAppSelector } from "../../app/hooks";
import css from "./ProjectPrice.module.scss";

const ProjectPrice = () => {
    const {
        totals: { monthlyCost },
    } = useAppSelector((state) => state.resources);
    return (
        <div className={css.projectCostContainer}>
            <p className={css.projectCost}>
                $
                {Number(monthlyCost).toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                })}
            </p>
            <p className={css.uppercaseText}>total monthly cost</p>
        </div>
    );
};

export default ProjectPrice;
