import React, { useEffect, useRef } from "react";
import Gantt from "frappe-gantt";
import UserCard from "./UserCard/UserCard";
import { useAppSelector } from "../../../../app/hooks";
import { colorSet } from "../../../../utils/helpers";

import css from "./GanttComponent.module.scss";

interface Task {
    id: string;
    name: string;
    start: string;
    end: string;
    progress: number;
    dependencies: string;
    color: string;
}

interface GanttComponentProps {
    tasks: Task[];
    handleDateChange: any;
}

const GanttComponent: React.FC<GanttComponentProps> = ({
    tasks,
    handleDateChange,
}) => {
    const { currentTab } = useAppSelector((state) => state.tabs);
    const ganttRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ganttRef.current) {
            new Gantt(ganttRef.current, tasks, {
                view_mode: "Month",
                language: "en",
                bar_height: 28,
                bar_corner_radius: 8,
                padding: 47,
                on_date_change: function (task, start, end) {
                    handleDateChange(task, start, end);
                },
            });

            // Query the bar-progress elements from the DOM
            const bars = ganttRef.current.querySelectorAll(
                ".bar-wrapper .bar-progress"
            );

            // Assign color to each bar-progress element from the color set cyclically
            bars.forEach((bar: Element, index: number) => {
                const svg = bar.closest("svg");
                if (svg) {
                    const def = document.createElementNS(
                        "http://www.w3.org/2000/svg",
                        "defs"
                    );
                    const filter = document.createElementNS(
                        "http://www.w3.org/2000/svg",
                        "filter"
                    );
                    filter.setAttribute("id", "f1");
                    filter.innerHTML = `
            <feDropShadow dx="0" dy="1" stdDeviation="1" flood-color="rgba(16, 24, 40, 0.06)"/>
            <feDropShadow dx="0" dy="1" stdDeviation="1" flood-color="rgba(16, 24, 40, 0.10)"/>
          `;
                    def.appendChild(filter);
                    svg.appendChild(def);

                    (bar as SVGPathElement).style.filter = "url(#f1)";
                    (bar as SVGPathElement).style.fill = tasks[index].color;
                }
            });
        }
    }, [tasks]);

    return (
        <>
            <div style={{ display: "flex", flex: 1, width: "100%" }}>
                <div
                    className={
                        currentTab !== 17 ? css.userCards : css.userCardsNarrow
                    }
                >
                    {tasks.map((task, index) => (
                        <UserCard
                            key={task.id}
                            id={task.id}
                            resourceIndex={index}
                        />
                    ))}
                </div>
                <div ref={ganttRef} style={{ overflow: "hidden", flex: 10 }} />
            </div>
            {/* <div ref={ganttRef} /> */}
        </>
    );
};
export default GanttComponent;
