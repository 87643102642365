import { useEffect, useState, useRef } from "react";
import { Button, MenuItem, Select, debounce } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import cn from "classnames";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import Icon from "../../../../Icon/Icon";
import SelectedRoleDesktop from "../../../../SelectedRoleDesktop/SelectedRoleDesktop";
import {
    frontendIcon,
    searchIcon,
    youpalLogo,
    vectorIcon,
    usersIcon,
    closeIcon,
    chevronIcon,
} from "../../../../../css/icons";
import css from "./AddResourceTabDesktop.module.scss";
import { setCurrentResources } from "../../../../../features/Resources/resourceSlice";
import { v4 as uuidv4 } from "uuid";
import ResourceService from "../../../../../services/resource.service";
import TopNavigationBar from "../../../../TopNavigationBar/TopNavigationBar";
import { calculateWeeks, scrollToSection } from "../../../../../utils/helpers";

interface TabProps {
    index: number;
}

interface Role {
    title: string;
    count: number;
    icon: React.ReactNode;
    price: number;
    role: string;
}

const resourceService = new ResourceService();

const AddResourceTabDesktop: React.FC<TabProps> = ({ index }) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [resources, setResources] = useState([]);
    const [search, setSearch] = useState("");
    const [categories, setCategories] = useState([]);
    const [seleteCategories, setSeleteCategories] = useState("All");
    const [loading, setLoading] = useState(true);

    const dispatch = useAppDispatch();
    const currentResources = useAppSelector(
        (state) => state.resources.currentResources
    );
    const [selectedRoles, setSelectedRoles] = useState<Role[]>(
        currentResources || []
    );
    const [numberSelectedRoles, setNumberSelectedRoles] = useState<number>(0);

    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer =
        (open: boolean) =>
        (
            event: React.KeyboardEvent | React.MouseEvent,
            reason?: "backdropClick" | "escapeKeyDown"
        ) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setIsOpen(open); // Directly set the state
        };

    const onCancel = () => {
        dispatch(setCurrentTab(0));
        setSearch("");
    };

    const fetchResourcePrice = async (title: string) => {
        const res = await resourceService.getResourceBasicPrice(
            title,
            "Junior"
        );

        return res.data.message.resource_cost;
    };

    const weeks = calculateWeeks();

    const onAddSelected = () => {
        dispatch(
            setCurrentResources([
                ...selectedRoles.map((role) => {
                    const computedPrice = 4 * 5 * 8 * role.price;
                    return {
                        ...role,
                        isUnique: [],
                        tabIndex: 4,
                        id: uuidv4(),
                        timeline: {
                            startWeek: weeks?.startWeek,
                            endWeek: weeks?.endWeek,
                        },
                        computedPrice,
                        weeklyWorkload: 5,
                        experience: 1,
                        workFormat: "remote",
                        location: "",
                        region: "",
                    };
                }),
            ])
        );

        dispatch(setCurrentTab(4));
    };

    const fetchCategories = async () => {
        const res = await resourceService.getResourcesCategories();
        setCategories(res.data.message);
    };

    const fetchResources = debounce(async () => {
        setLoading(true);
        const res = await resourceService.getResourceRoles(
            search.length > 0 ? search : undefined,
            seleteCategories
        );
        setResources(
            res.data.message.map((resource: any) => ({
                title: resource.resource_pools,
                icon: frontendIcon,
                role: resource.name,
                cost: resource.cost,
            }))
        );
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, 1000);

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        fetchResources();
    }, [search, seleteCategories]);

    useEffect(() => {
        scrollToSection("AddResourceTab");
        setSelectedRoles(currentResources);
    }, [index]);

    useEffect(() => {
        setNumberSelectedRoles(
            selectedRoles.length > 0
                ? selectedRoles.reduce((acc, cur) => acc + cur.count, 0)
                : 0
        );
    }, [selectedRoles]);

    return (
        <TabPanel index={3} value={index} id={"AddResourceTab"}>
            <TopNavigationBar title="Project Setup" tabNumber={1} />
            <div className={css.wrapper}>
                {/* <div className={css.header}>
                    <Icon path={youpalLogo} />
                    <Button color="info" onClick={onCancel}>
                        Cancel
                    </Button>
                </div> */}
                <div>
                    <h1 className={css.title}>Select the resources you need</h1>
                    <div className={css.searchContainer}>
                        <Icon path={searchIcon} className={css.searchIcon} />
                        <input
                            type="text"
                            className={css.search}
                            placeholder="Search resources"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                        <div
                            className="inputContainerWrap"
                            style={{ position: "relative" }}
                        >
                            <div className={css.inputContainer}>
                                <Select
                                    variant="standard"
                                    sx={{
                                        marginLeft: "30px",
                                        ".MuiSelect-icon": {
                                            right: 10,
                                        },
                                    }}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            padding: "14px",
                                        },
                                    }}
                                    disableUnderline
                                    defaultValue="All"
                                    IconComponent={(props) => (
                                        <Icon path={chevronIcon} {...props} />
                                    )}
                                    value={seleteCategories}
                                    onChange={(e) => {
                                        setSeleteCategories(e.target.value);
                                    }}
                                >
                                    {categories?.map((category: any, index) => {
                                        return (
                                            <MenuItem
                                                value={category}
                                                key={index}
                                            >
                                                {category}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={css.roleFlex}>
                        {resources.map(({ title, icon, role, cost }) => {
                            const selected = selectedRoles.find(
                                (role) => role.title === title
                            );

                            const index = selected
                                ? selectedRoles.findIndex(
                                      (role) => role.title === title
                                  )
                                : -1;

                            return loading ? (
                                <div
                                    key={title}
                                    className={cn(
                                        css.roleContainer,
                                        css.skeleton
                                    )}
                                >
                                    <div className={css.placeholder}></div>
                                    <div className={css.longLine}></div>
                                    <div className={css.line}></div>
                                    <button
                                        className={css.selectButton}
                                        onClick={() => {}}
                                    >
                                        Select
                                    </button>
                                </div>
                            ) : (
                                <div
                                    key={title}
                                    className={cn(css.roleContainer, {
                                        [css.selected]: selected,
                                    })}
                                >
                                    <div className={css.vector}>
                                        <Icon path={vectorIcon} />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <h6>{title}</h6>
                                        <p>
                                            ${4 * 5 * 8 * cost}{" "}
                                            <span>/month</span>
                                        </p>
                                    </div>
                                    {!selected ? (
                                        <button
                                            className={css.selectButton}
                                            onClick={async () => {
                                                const price =
                                                    await fetchResourcePrice(
                                                        title
                                                    );

                                                setSelectedRoles([
                                                    ...selectedRoles,
                                                    {
                                                        title,
                                                        count: 1,
                                                        price,
                                                        icon: (
                                                            <Icon
                                                                path={icon}
                                                                className={
                                                                    css.selectedRoleIcon
                                                                }
                                                            />
                                                        ),
                                                        role,
                                                    },
                                                ]);
                                            }}
                                        >
                                            Select
                                        </button>
                                    ) : (
                                        <SelectedRoleDesktop
                                            modal={false}
                                            count={selected.count}
                                            setCount={(num) => {
                                                const index =
                                                    selectedRoles.findIndex(
                                                        (role) =>
                                                            role.title ===
                                                            selected.title
                                                    );

                                                if (num === 0) {
                                                    const newSelectedRoles = [
                                                        ...selectedRoles,
                                                    ];
                                                    newSelectedRoles.splice(
                                                        index,
                                                        1
                                                    );
                                                    setSelectedRoles(
                                                        newSelectedRoles
                                                    );
                                                } else {
                                                    const updatedRole = {
                                                        ...selected,
                                                        count: num,
                                                    };
                                                    const newSelectedRoles = [
                                                        ...selectedRoles,
                                                    ];
                                                    newSelectedRoles[index] =
                                                        updatedRole;
                                                    setSelectedRoles(
                                                        newSelectedRoles
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={css.buttonContainer}>
                        <Button
                            color="primary"
                            onClick={onAddSelected}
                            disabled={numberSelectedRoles === 0}
                            className={css.confirmButton}
                        >
                            Confirm Resources
                        </Button>
                        <button
                            // disabled={numberSelectedRoles === 0}
                            className={css.usersButton}
                            onClick={(event) =>
                                toggleDrawer(true)(event as React.MouseEvent)
                            }
                        >
                            {" "}
                            <span className={css.number}>
                                {numberSelectedRoles}
                            </span>
                            <Icon path={usersIcon} />
                        </button>
                    </div>
                </div>
                <Drawer
                    anchor="right"
                    open={isOpen}
                    onClose={(event, reason) =>
                        toggleDrawer(false)(
                            event as React.KeyboardEvent,
                            reason
                        )
                    }
                >
                    <Box sx={{ width: 360, py: 2 }} role="presentation">
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                borderBottom: "1px solid #e0e0e0",
                                paddingBottom: 1,
                                px: 3,
                            }}
                        >
                            <Typography
                                variant="h6"
                                className={css.drawerTitle}
                            >
                                Your Resources
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={toggleDrawer(false)}
                                aria-label="close"
                            >
                                <Icon path={closeIcon} />
                            </IconButton>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{
                                paddingTop: "12px",
                                px: 3,
                                minHeight: "91vh",
                            }}
                        >
                            <p className={css.drawerTotal}>
                                Total Resources:{" "}
                                <span>{numberSelectedRoles}</span>
                            </p>

                            <div style={{ flex: 1 }}>
                                {selectedRoles.map((role, index) => {
                                    return (
                                        <div
                                            className={
                                                css.selectedRoleConatiner
                                            }
                                            key={role?.title + index}
                                        >
                                            <SelectedRoleDesktop
                                                modal={true}
                                                count={role?.count}
                                                title={role?.title}
                                                cost={4 * 5 * 8 * role?.price}
                                                setCount={(num) => {
                                                    const newSelectedRoles = [
                                                        ...selectedRoles,
                                                    ];

                                                    if (num === 0) {
                                                        newSelectedRoles.splice(
                                                            index,
                                                            1
                                                        );
                                                    } else {
                                                        const updatedRole = {
                                                            ...newSelectedRoles[
                                                                index
                                                            ],
                                                            count: num,
                                                        };
                                                        newSelectedRoles[
                                                            index
                                                        ] = updatedRole;
                                                    }
                                                    setSelectedRoles(
                                                        newSelectedRoles
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <Button
                                color="primary"
                                onClick={(event) => {
                                    onAddSelected();
                                    toggleDrawer(false)(event);
                                }}
                                disabled={numberSelectedRoles === 0}
                                className={`${css.confirmButton} ${css.confirmButtonDrawer}`}
                                fullWidth
                            >
                                Finish Resource Configuration
                            </Button>
                        </Box>
                    </Box>
                </Drawer>
            </div>
        </TabPanel>
    );
};

export default AddResourceTabDesktop;
