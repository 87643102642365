import css from "./DiscountsCarousel.module.scss";
import cn from "classnames";
import { setCurrentTab } from "../../features/Tabs/tabSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { formatAmountDifferenceWithSign } from "../../utils/helpers";
import Icon from "../Icon/Icon";
import {
    AgrementIcon,
    AgrementWhiteIcon,
    CreditCardIcon,
    CreditCardWhiteIcon,
    IntialPaymentIcon,
    IntialPaymentWhiteIcon,
    InvoiceTermsIcon,
    InvoiceTermsWhiteIcon,
    PaymentIcon,
    PaymentWhiteIcon,
    VolumeIcon,
    VolumeWhiteIcon,
} from "../../css/icons";

interface ResourcesCarouselProps {}

const DiscountsCarousel: React.FC<ResourcesCarouselProps> = () => {
    const { currentTab } = useAppSelector((state) => state.tabs);
    const { budget } = useAppSelector((state) => state.resources);
    const { projectCost, totalHours } = useAppSelector(
        (state) => state.resources.totals
    );
    const dispatch = useAppDispatch();

    const onTabClick = (index: number) => {
        dispatch(setCurrentTab(index));
    };

    const {
        discounts: {
            total_discount_saving,
            more_discount_can_save,
            credit_card_availed_percentage,
            credit_card_discount_per_month,
            payment_duration_availed_percentage,
            payment_duration_discount_per_month,
            frame_agreement_availed_percentage,
            frame_agreement_discount_per_month,
            upfront_payment_availed_percentage,
            upfront_payment_discount_per_month,
            invoice_payment_terms_availed_percentage,
            invoice_payment_terms_discount_per_month,
        },
    } = useAppSelector((state) => state.discounts);

    return (
        <div className={cn(css.wrapper)}>
            <div className={css.resourceTitleContainer}>
                <div className={css.resourceLeft}>
                    <h2>Discounts</h2>
                    <p>
                        You can save upto{" "}
                        <span className={css.green}>
                            {Number(
                                Math.abs(more_discount_can_save)
                            ).toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                            })}
                        </span>
                        /month more!
                    </p>
                </div>
                <div className={css.resourceRight}>
                    <p>Total Cost / Your Budget</p>
                    <h6>
                        {total_discount_saving === 0 ? (
                            <>
                                $
                                {Number(projectCost).toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                })}
                            </>
                        ) : (
                            <div>
                                <span className={css.after}>
                                    $
                                    {Number(
                                        projectCost - total_discount_saving
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </span>
                                <span className={css.before}>
                                    $
                                    {Number(projectCost).toLocaleString(
                                        undefined,
                                        {
                                            maximumFractionDigits: 0,
                                        }
                                    )}
                                </span>
                            </div>
                        )}

                        {budget && (
                            <span>
                                / $
                                {Number(budget).toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                })}
                            </span>
                        )}
                    </h6>
                </div>
            </div>
            <div className={css.resourceTabWrapper}>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]: currentTab === 11,
                    })}
                    onClick={() => onTabClick(11)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]: currentTab === 11,
                                })}
                            >
                                {currentTab === 11 ? (
                                    <Icon path={CreditCardWhiteIcon} />
                                ) : (
                                    <Icon path={CreditCardIcon} />
                                )}
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Use Credit Card</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {credit_card_availed_percentage.toFixed(1)}%
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                credit_card_availed_percentage
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        credit_card_discount_per_month
                                    )}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]: currentTab === 12,
                    })}
                    onClick={() => onTabClick(12)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]: currentTab === 12,
                                })}
                            >
                                {currentTab === 12 ? (
                                    <Icon path={PaymentWhiteIcon} />
                                ) : (
                                    <Icon path={PaymentIcon} />
                                )}
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>% Payment</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {payment_duration_availed_percentage.toFixed(
                                        1
                                    )}
                                    %
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                payment_duration_availed_percentage
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        payment_duration_discount_per_month
                                    )}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]: currentTab === 13,
                    })}
                    onClick={() => onTabClick(13)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]: currentTab === 13,
                                })}
                            >
                                {currentTab === 13 ? (
                                    <Icon path={InvoiceTermsWhiteIcon} />
                                ) : (
                                    <Icon path={InvoiceTermsIcon} />
                                )}
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Invoice Terms</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {invoice_payment_terms_availed_percentage.toFixed(
                                        1
                                    )}
                                    %
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                invoice_payment_terms_availed_percentage
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        invoice_payment_terms_discount_per_month
                                    )}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]: currentTab === 14,
                    })}
                    onClick={() => onTabClick(14)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]: currentTab === 14,
                                })}
                            >
                                {currentTab === 14 ? (
                                    <Icon path={IntialPaymentWhiteIcon} />
                                ) : (
                                    <Icon path={IntialPaymentIcon} />
                                )}
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Initial Payments</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {upfront_payment_availed_percentage.toFixed(
                                        1
                                    )}
                                    %
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                upfront_payment_availed_percentage
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        upfront_payment_discount_per_month
                                    )}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]: currentTab === 15,
                    })}
                    onClick={() => onTabClick(15)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]: currentTab === 15,
                                })}
                            >
                                {currentTab === 15 ? (
                                    <Icon path={AgrementWhiteIcon} />
                                ) : (
                                    <Icon path={AgrementIcon} />
                                )}
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>3yr Agreement</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {frame_agreement_availed_percentage.toFixed(
                                        1
                                    )}
                                    %
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]:
                                            Math.abs(
                                                frame_agreement_availed_percentage
                                            ) > 0,
                                    })}
                                >
                                    {formatAmountDifferenceWithSign(
                                        frame_agreement_discount_per_month
                                    )}
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(css.resourceTab, {
                        [css["resourceTab--selected"]]: currentTab === 16,
                    })}
                    onClick={() => onTabClick(16)}
                >
                    <div className={css.info}>
                        <div>
                            <div
                                className={cn(css.icon, {
                                    [css.selected]: currentTab === 16,
                                })}
                            >
                                {currentTab === 16 ? (
                                    <Icon path={VolumeWhiteIcon} />
                                ) : (
                                    <Icon path={VolumeIcon} />
                                )}
                            </div>
                        </div>
                        <div>
                            <p className={css.title}>Volume Resources</p>
                            <div className={css.detailsConatiner}>
                                <small className={css.details}>
                                    {totalHours < 4000 ? "1.0%" : "2.0%"}
                                </small>
                                <small
                                    className={cn(css.money, {
                                        [css.increase]: false,
                                    })}
                                >
                                    $0
                                    <span>/month</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscountsCarousel;
