import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import UseCreditCardTabMobile from "./UseCreditCardTabMobile/UseCreditCardTabMobile";
import UseCreditCardTabDesktop from "./UseCreditCardTabDesktop/UseCreditCardTabDesktop";

import DiscountTabsMobileNew from "../DiscountTabsMobileNew/DiscountTabsMobileNew";

interface TabProps {
    index: number;
}

const UseCreditCardTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <UseCreditCardTabDesktop index={index} />
    ) : (
        <DiscountTabsMobileNew index={index} />
    );
};

export default UseCreditCardTab;
