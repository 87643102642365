import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Resource } from "../../../../../../features/Resources/resourceSlice";
import css from "./DoughnutChart.module.scss";
import { Grey } from "../../../../../../mui/colors";
import { colorSet } from "../../../../../../utils/helpers";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ResourcesCarouselProps {
    resources: Resource[];
    projectCost: number;
    budget: number | undefined;
}

const PropertiesCarousel: React.FC<ResourcesCarouselProps> = ({
    resources,
    projectCost,
    budget,
}) => {
    const [data, setData] = useState<any>();
    const [labels, setLabels] = useState<string[]>([]);
    const options = {
        cutout: 80,
        tooltips: {
            enabled: false, // Disable tooltips
        },
        onRender: function (chart: any) {
            const width = chart.chart.width;
            const height = chart.chart.height;
            const ctx = chart.chart.ctx;

            // Draw text in the center
            const text = "Center Text";
            const fontSize = 24;

            ctx.fillStyle = "#000"; // Text color
            ctx.font = `${fontSize}px Arial`;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(text, width / 2, height / 2);
        },
    };
    useEffect(() => {
        let labels: string[] = [];
        let data: number[] = [];
        let backgroundColor: string[] = [];
        let borderColor: string[] = [];
        console.log("Daughnut Chart ", resources);
        resources.forEach((resource, index) => {
            labels.push(resource.title);
            data.push(resource.costDetails.total_project_cost);
            backgroundColor.push(resource.color);
            borderColor.push(resource.color);
        });
        if (budget && budget > projectCost) {
            data.push(budget - projectCost);
            backgroundColor.push(Grey[50]);
            borderColor.push(Grey[200]);
        }
        setLabels(labels);
        setData({
            datasets: [
                {
                    data,
                    backgroundColor,
                    borderColor,
                    borderWidth: 1,
                },
            ],
        });
    }, [resources, projectCost, budget]);

    const formatNumber = (number: number) => {
        if (number < 1e3)
            return Number(Math.abs(number)).toLocaleString(undefined, {
                maximumFractionDigits: 0,
            }); // If less than 1,000
        if (number >= 1e3 && number < 1e6)
            return `${(number / 1e3).toFixed(1)}K`; // If between 1,000 and 999,999
        // You can continue with M for million, B for billion, etc. if needed
    };

    return !data ? (
        <></>
    ) : (
        <div>
            <div className={css.doughnut}>
                <Doughnut data={data} options={options} />
                <div className={css.centerText}>
                    <p>
                        ${formatNumber(projectCost)}
                        <span>
                            {budget && (
                                <>
                                    / $
                                    {Number(Math.abs(budget)).toLocaleString(
                                        undefined,
                                        {
                                            maximumFractionDigits: 0,
                                        }
                                    )}
                                </>
                            )}
                        </span>
                    </p>
                </div>
            </div>
            <div className={css.labelsContainer}>
                {labels.map((label, index) => {
                    return (
                        <div className={css.label} key={index}>
                            <span
                                style={{
                                    backgroundColor: resources[index].color,
                                }}
                            ></span>
                            <small>{label}</small>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PropertiesCarousel;
