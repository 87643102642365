import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import AddResourceTabDesktop from "./AddResourceTabDesktop/AddResourceTabDesktop";
import AddResourceTabMobile from "./AddResourceTabMobile/AddResourceTabMobile";

interface TabProps {
    index: number;
}

const AddResourceTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    /* return width > MOBILE_SCREEN_WIDTH ? (
        <AddResourceTabDesktop index={index} />
    ) : (
        <AddResourceTabMobile index={index} />
    ); */

    return <AddResourceTabDesktop index={index} />;
};

export default AddResourceTab;
