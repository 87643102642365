import cn from "classnames";
import css from "./SelectedRoleDesktop.module.scss";
import Icon from "../Icon/Icon";
import { minusIcon, plusIcon, checkIcon } from "../../css/icons";

interface SelectedRoleProps {
    modal: boolean;
    title?: string;
    cost?: number;
    onlyInput?: boolean;
    count: number;
    setCount: (val: number) => void;
}

const SelectedRoleDesktop: React.FC<SelectedRoleProps> = ({
    modal,
    title,
    cost,
    count,
    setCount,
    onlyInput,
}) => {
    return (
        <div
            className={cn(css.selectedRole, {
                [css["center"]]: onlyInput,
            })}
        >
            {!onlyInput && (
                <div className={css.selectedRoleLeft}>
                    {modal ? (
                        <div className={css.selectedRoleInfo}>
                            <p>{title}</p>
                            <small>
                                ${cost} <span>/month</span>
                            </small>
                        </div>
                    ) : (
                        <button
                            onClick={() => {
                                setCount(0);
                            }}
                        >
                            <Icon path={checkIcon} />
                        </button>
                    )}
                </div>
            )}
            <div className={css.selectedRoleControls}>
                <button
                    type="button"
                    onClick={() => {
                        setCount(count > 0 ? count - 1 : 0);
                    }}
                >
                    <Icon path={minusIcon} />
                </button>
                <input
                    className={css.selectedRowCount}
                    value={count}
                    onChange={(e) => {
                        if (
                            Number.isNaN(+e.target.value) ||
                            +e.target.value < 0
                        ) {
                            setCount(0);
                        } else {
                            setCount(+e.target.value);
                        }
                    }}
                />
                <button
                    type="button"
                    onClick={() => {
                        setCount(count + 1);
                    }}
                >
                    <Icon path={plusIcon} />
                </button>
            </div>
        </div>
    );
};

export default SelectedRoleDesktop;
