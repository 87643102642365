import { useState, useEffect } from "react";
import css from "./FirstTenPercentTabMobile.module.scss";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import { Button, Slider, SliderThumb } from "@mui/material";
import cn from "classnames";
import DiscountsMobileCommon from "../../../../DiscountsMobileCommon/DiscountsMobileCommon";
import BotMobile from "../../../../BotMobile/BotMobile";
import { setUpfrontPayment } from "../../../../../features/Discounts/discountSlice";

interface TabProps {
    index: number;
}

const ThumbComponent = (props: any) => {
    const { children, className, ...other } = props;

    let fiftyClassName = "";
    if (props.ownerState.value[0] === 50) {
        // if 50, 50+
        fiftyClassName =
            other["data-index"] === 0 ? "first-thumb" : "second-thumb";
    } else {
        // if 50-, 50
        fiftyClassName =
            other["data-index"] === 0 ? "second-thumb" : "first-thumb";
    }

    return (
        <SliderThumb {...other} className={cn(className, fiftyClassName)}>
            {children}
        </SliderThumb>
    );
};

const FirstTenPercentTabMobile: React.FC<TabProps> = ({ index }) => {
    const {
        budget,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const {
        total_discount,
        discounts: { upfront_payment_percentage_can_avail },
        discountPayload,
    } = useAppSelector((state) => state.discounts);
    const [percent, setPercent] = useState([50, 50]);
    const dispatch = useAppDispatch();

    const onGoBack = () => {
        dispatch(setCurrentTab(13));
    };

    const onNext = () => {
        dispatch(setCurrentTab(15));
    };

    const getMaxDiscount = () => {
        setPercent([50, 100]);
    };

    useEffect(() => {
        const perMonth = Number(percent.find((p) => p !== 50) ?? 50);
        document.dispatchEvent(
            new CustomEvent("update_discount", {
                detail: {
                    total_amount: projectCost,
                    discount: {
                        ...discountPayload,
                        upfront_payment: perMonth + "%",
                    },
                },
            })
        );
        dispatch(setUpfrontPayment(perMonth + "%"));
    }, [percent]);

    return (
        <TabPanel index={14} value={index}>
            <div className={css.wrapper}>
                <DiscountsMobileCommon
                    current={projectCost}
                    total={budget}
                    onCancel={() => {}}
                    onGoBack={onGoBack}
                    saving={
                        (upfront_payment_percentage_can_avail * projectCost) /
                        100
                    }
                    onGetDiscount={getMaxDiscount}
                />

                <div className={css.content}>
                    <h1 className={css.title}>
                        How much can you pay in first 10% of the project?
                    </h1>
                    <div className={css.sliderContainer}>
                        <Slider
                            min={0}
                            max={100}
                            step={10}
                            value={percent}
                            components={{
                                Thumb: ThumbComponent,
                            }}
                            sx={{
                                track: {
                                    backgroundColor: "green !important",
                                },
                            }}
                            color="secondary"
                            onChange={(e: any) => {
                                const pair = e.target.value;

                                if (
                                    pair[0] < 50 &&
                                    pair[1] > 50 &&
                                    pair[1] === percent[1]
                                ) {
                                    setPercent([pair[0], 50]);
                                    return;
                                } else if (
                                    pair[0] < 50 &&
                                    pair[1] > 50 &&
                                    pair[1] !== percent[1]
                                ) {
                                    setPercent([50, pair[1]]);
                                    return;
                                }

                                if (pair[1] > 50) {
                                    setPercent([50, pair[1]]);
                                } else {
                                    setPercent([pair[0], 50]);
                                }
                            }}
                        />
                    </div>
                    <div className={css.setPercentBtnContainer}>
                        <div
                            onClick={() => setPercent([10, 50])}
                            className={cn(css.setPercentBtn, {
                                [css.selectedOption]: percent[0] === 10,
                            })}
                        >
                            10%
                        </div>
                        <div
                            onClick={() => setPercent([30, 50])}
                            className={cn(css.setPercentBtn, {
                                [css.selectedOption]: percent[0] === 30,
                            })}
                        >
                            30%
                        </div>
                        <div
                            onClick={() => setPercent([50, 50])}
                            className={cn(css.setPercentBtn, {
                                [css.selectedOption]:
                                    percent[0] === 50 && percent[1] === 50,
                            })}
                        >
                            50%
                        </div>
                        <div
                            onClick={() => setPercent([50, 80])}
                            className={cn(css.setPercentBtn, {
                                [css.selectedOption]: percent[1] === 80,
                            })}
                        >
                            80%
                        </div>
                    </div>
                    <div className={css.result}>
                        <p className={css.resultSubtext}>You will save</p>
                        <p>
                            <span className={css.resultGreen}>
                                ${" "}
                                {Number(total_discount).toLocaleString(
                                    undefined,
                                    {
                                        maximumFractionDigits: 1,
                                    }
                                )}{" "}
                            </span>
                            <span className={css.resultSubtext}> /month</span>
                        </p>
                    </div>
                </div>
                <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            padding: "12px",
                        }}
                        fullWidth
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </div>
                <div className={css.botMobileContainer}>
                    <BotMobile />
                </div>
            </div>
        </TabPanel>
    );
};

export default FirstTenPercentTabMobile;
