import axios from "axios";
import { calculateWeeksDifference } from "../utils/helpers";

const instance = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
});

class ResourceService {
    private baseURL = process.env.REACT_APP_BASE_URL;
    private baseUrlPostfix =
        "api/method/erpnext.clockify_project.doctype.resource_calculator.resource_calculator.";

    async getLocationList() {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_all_resource_locations`
        );
    }

    async getRegionList() {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_all_resource_regions`
        );
    }

    async getResourcesCategories() {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_all_resource_categories`
        );
    }

    async getResourceRoles(search?: string, category?: string) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_all_resource_roles`,
            {
                params: {
                    resource_name: search,
                    category,
                },
            }
        );
    }
    async getResourceDetail(id?: string) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_resource_detail`,
            {
                params: {
                    resource_id: id,
                },
            }
        );
    }

    async sendEmail(id: string | number, email: string, message: string) {
        return await instance.post(
            `${this.baseURL}${this.baseUrlPostfix}add_resource_email_and_message`,
            {},
            {
                params: {
                    id,
                    email,
                    message,
                },
            }
        );
    }

    async getResourceDiscounts(
        total_amount: number,
        credit_card: string,
        credit_card_duration: string,
        credit_card_payment_percent_per_month: string,
        invoice_payment_terms: string,
        upfront_payment: string,
        frame_agreement: string
    ) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_resource_discounts`,
            {
                params: {
                    total_amount,
                    credit_card,
                    credit_card_duration,
                    credit_card_payment_percent_per_month,
                    invoice_payment_terms,
                    upfront_payment,
                    frame_agreement,
                },
            }
        );
    }

    async getResourceBasicPrice(title: string, experience?: string) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_resource_basic_cost`,
            {
                params: {
                    title,
                    experience: experience || "Intermediate",
                },
            }
        );
    }

    async getResourcePriceByLocation(
        title: string,
        experience: string,
        work_format: "Onsite" | "Remote",
        location: string
    ) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_resource_cost_based_on_work_format`,
            {
                params: {
                    title,
                    experience,
                    work_format,
                    location,
                },
            }
        );
    }
    async getResourcePriceByRegion(
        title: string,
        experience: string,
        region: string
    ) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_resource_cost_based_on_region`,
            {
                params: {
                    title,
                    experience,
                    region,
                },
            }
        );
    }

    async getResourcePrice(
        title: string,
        experience: string,
        work_format: "Onsite" | "Remote",
        location: string,
        region: string
    ) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_resource_cost_price`,
            {
                params: {
                    title,
                    experience,
                    work_format,
                    location,
                    region,
                },
            }
        );
    }

    async getResourcePriceDetails(
        title: string,
        experience: string,
        work_format: "Onsite" | "Remote",
        location: string,
        region: string,
        weeklyWorkload: number,
        timeline: {
            endWeek: Date;
            startWeek: Date;
        }
    ) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}get_resource_cost_detail`,
            {
                params: {
                    resource_name: title,
                    experience,
                    work_format,
                    location,
                    region,
                    no_of_days: weeklyWorkload || 5,
                    no_of_weeks: calculateWeeksDifference(
                        timeline.startWeek,
                        timeline.endWeek
                    ),
                },
            }
        );
    }

    async addResource(
        resource_id: string,
        currency: string,
        budget: number,
        duration: number,
        current_tab: number,
        resource_requirements: {
            role: string;
            number: number;
            experience: string;
            duration: number;
            workload: number;
            start_week: number;
            end_week: number;

            title: string;
            work_format: string;
            work_location: string;
            resource_location: string;
            start_week_date: string;
            end_week_date: string;
        }[],
        credit_card_discount: string,
        credit_card_duration_discount: string,
        frame_agreement_discount: string,
        upfront_payment_discount: string,
        credit_card_each_month: string
    ) {
        return await instance.post(
            `${this.baseURL}${this.baseUrlPostfix}add_new_resource`,
            {
                resource_id,
                currency,
                budget,
                current_tab,
                project_duration: duration,
                resource_requirements,
                credit_card_discount,
                credit_card_duration_discount,
                frame_agreement_discount,
                upfront_payment_discount,
                credit_card_each_month,
            }
        );
    }
    async addResourceAsDraft(
        resource_id: string,
        currency: string,
        budget: number,
        duration: number,
        current_tab: number,
        resource_requirements: {
            role: string;
            number: number;
            experience: string;
            duration: number;
            workload: number;
            start_week: number;
            end_week: number;

            title: string;
            work_format: string;
            work_location: string;
            resource_location: string;
            start_week_date: string;
            end_week_date: string;
        }[],
        credit_card_discount: string,
        credit_card_duration_discount: string,
        frame_agreement_discount: string,
        upfront_payment_discount: string,
        credit_card_each_month: string
    ) {
        return await instance.post(
            `${this.baseURL}${this.baseUrlPostfix}save_resource_as_draft`,
            {
                resource_id,
                currency,
                budget,
                current_tab,
                project_duration: duration,
                resource_requirements,
                credit_card_discount,
                credit_card_duration_discount,
                frame_agreement_discount,
                upfront_payment_discount,
                credit_card_each_month,
            }
        );
    }

    async saveAsQuota(id: string) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}save_as_quota`,
            {
                params: {
                    resource_id: id,
                },
            }
        );
    }

    async sendUrlToEmail(id: string, email: string, url: string) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}send_url_email_or_copy`,
            {
                params: {
                    resource_id: id,
                    resource_url: url,
                    email_id: email,
                    copy: 0,
                    email: 1,
                },
            }
        );
    }

    async sendMessageToSupport(
        id: string,
        email: string,
        message: string,
        userName?: string,
        companyName?: string
    ) {
        return await instance.get(
            `${this.baseURL}${this.baseUrlPostfix}add_resource_email_and_message`,
            {
                params: {
                    id,
                    message,
                    email,
                    user_name: userName,
                    company_name: companyName,
                },
            }
        );
    }
}

export default ResourceService;
