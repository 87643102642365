import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import CongratulationsTabDesktop from "./CongratulationsTabDesktop/CongratulationsTabDesktop";
import CongratulationsTabMobile from "./CongratulationsTabMobile/CongratulationsTabMobile";

interface TabProps {
    index: number;
}

const CongratulationsTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <CongratulationsTabDesktop index={index} />
    ) : (
        <CongratulationsTabMobile index={index} />
    );
};

export default CongratulationsTab;
