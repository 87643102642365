import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    setLocationById,
    setTabIndexById,
    setWorkFormatById,
} from "../../../../features/Resources/resourceSlice";
import css from "./MobileWorkFormatTab.module.scss";
import cn from "classnames";
import Icon from "../../../Icon/Icon";
import { chevronIcon, onSiteIcon, remoteIcon } from "../../../../css/icons";
import { Button, MenuItem, Select } from "@mui/material";
import TabPanel from "../../TabPanel";
import ResourceService from "../../../../services/resource.service";

interface TabProps {
    index: number;
    id: string;
}

const resourceService = new ResourceService();

const MobileWorkFormatTab: React.FC<TabProps> = ({ index, id }) => {
    const [isRemote, setIsRemote] = useState(true);
    const [locationOptions, setLocationOptions] = useState([]);
    const [locationValue, setLocationValue] = useState("");
    const { currentResources } = useAppSelector((state) => state.resources);

    const currentResource = useMemo(() => {
        return currentResources.find((res) => res.id === id);
    }, [id, currentResources]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        let currentResource = currentResources.find((res) => res.id === id);
        setIsRemote(currentResource?.workFormat === "remote" ? true : false);
        setLocationValue(
            currentResource?.workFormat === "on-site"
                ? currentResource?.location
                : ""
        );
    }, []);

    const onNext = () => {
        dispatch(
            setWorkFormatById({
                id,
                workFormat:
                    isRemote || locationValue === "" ? "remote" : "on-site",
            })
        );
        dispatch(
            setLocationById({
                id,
                location: locationValue,
            })
        );
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResource,
                    },
                },
            })
        );
    };

    const fetchLocations = async () => {
        const res = await resourceService.getLocationList();
        setLocationOptions(
            res.data.message.filter(
                (loc: { location: string }) => loc.location !== "Remote"
            )
        );
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources.find((res) => res.id === id),
                        location: locationValue,
                        workFormat:
                            isRemote || locationValue === ""
                                ? "remote"
                                : "on-site",
                    },
                },
            })
        );
    }, [isRemote, locationValue]);

    useEffect(() => {
        fetchLocations();
    }, []);

    //on change options overall only for mobile design
    const onChangeIsRemote = (isRemoteOption: boolean) => {
        setIsRemote(isRemoteOption);
        dispatch(
            setWorkFormatById({
                id,
                workFormat: isRemoteOption ? "remote" : "on-site",
            })
        );
    };

    const onChangeLocationValue = (location: string) => {
        setLocationValue(location);
        dispatch(
            setLocationById({
                id,
                location: location,
            })
        );
    };

    return (
        <TabPanel index={5} value={index}>
            <div className={css.blockWrap}>
                <div>
                    <h1 className={css.title}>How do you want them to work?</h1>
                    <div className={css.cardContainer}>
                        <div
                            className={cn(css.card, {
                                [css.selectedCard]: isRemote,
                            })}
                            onClick={() => onChangeIsRemote(true)}
                        >
                            <Icon path={remoteIcon} />
                            Remote
                        </div>
                        <div
                            className={cn(css.card, {
                                [css.selectedCard]: !isRemote,
                            })}
                            onClick={() => onChangeIsRemote(false)}
                        >
                            <Icon path={onSiteIcon} />
                            On-site
                        </div>
                    </div>
                    <div className={css.selectContainer}>
                        {!isRemote && (
                            <>
                                <p className={css.subtitle}>
                                    Where do you want them to work from?
                                </p>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    defaultValue=""
                                    IconComponent={(props) => (
                                        <Icon path={chevronIcon} {...props} />
                                    )}
                                    typeof="select"
                                    value={locationValue}
                                    onChange={(e) => {
                                        onChangeLocationValue(e.target.value);
                                    }}
                                >
                                    {locationOptions.map(
                                        (option: {
                                            location: string;
                                            cost: number;
                                        }) => {
                                            return (
                                                <MenuItem
                                                    value={option.location}
                                                    key={`location-option-${option.location}`}
                                                >
                                                    {option.location}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </>
                        )}
                    </div>
                    <div className={css.experiencePriceBlock}>
                        <p className={css.title}>
                            ${" "}
                            {Number(
                                currentResource?.computedPrice
                            ).toLocaleString(undefined, {
                                maximumFractionDigits: 1,
                            })}
                            <span className={css.subtext}>/ month</span>
                            {!isRemote && (
                                <>
                                    <br />
                                    <span className={css.subtext}>
                                        Accommodation cost
                                    </span>
                                </>
                            )}
                        </p>
                    </div>
                    {/* <div className={css.buttonFlex}>
                        <Button
                            color="secondary"
                            onClick={onPrevious}
                            sx={{
                                padding: "8px 34px",
                                display: "block",
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            color="primary"
                            onClick={onNext}
                            sx={{
                                padding: "8px 34px",
                                display: "block",
                            }}
                            disabled={!isRemote && locationValue.length === 0}
                        >
                            Next
                        </Button>
                    </div> */}
                </div>
            </div>
        </TabPanel>
    );
};

export default MobileWorkFormatTab;
