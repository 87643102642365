export const getExperienceLabel = (exp: number) => {
    if (!exp || exp < 3) return "Junior";
    if (exp < 5) return "Intermediate";
    if (exp < 7) return "Senior";
    return "Expert";
};

export const getExperienceNumber = (label: string) => {
    switch (label.toLowerCase()) {
        case "junior":
            return 2;
        case "intermediate":
            return 4;
        case "senior":
            return 6;
        case "expert":
            return 8;
        default:
            return 1;
    }
};

export const formatAmountDifferenceWithSign = (num: number) => {
    if (num === undefined || isNaN(num)) return "+$0";

    return `${num > 0 ? "+" : num < 0 ? "-" : ""} $${Math.abs(
        num
    ).toLocaleString(undefined, {
        maximumFractionDigits: 0,
    })}`;
};

export const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: "smooth", block: "center" });
};

export const calculateWeeks = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1); // next month
    currentDate.setDate(1); // first day of the next month
    const minStartDate = new Date(currentDate); // Minimum allowed start date

    currentDate.setMonth(currentDate.getMonth() + 1); // one month after the next month
    const end = new Date(currentDate);

    return {
        startWeek: minStartDate,
        endWeek: end,
    };
};

export function calculateWeeksDifference(
    startDate: Date,
    endDate: Date
): number {
    const getDayOfYear4Weeks = (date: Date): number => {
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date");
        }

        // Calculate the total days from the months
        let daysFromMonths = date.getMonth() * 28;

        // Add the days of the current month
        daysFromMonths += date.getDate();

        return daysFromMonths;
    };

    const getWeekDifference4Weeks = (start: Date, end: Date): number => {
        if (
            !(start instanceof Date) ||
            isNaN(start.getTime()) ||
            !(end instanceof Date) ||
            isNaN(end.getTime())
        ) {
            return 0;
        }

        // Get the day difference considering every month has 28 days
        const daysStart = getDayOfYear4Weeks(start);
        const daysEnd =
            getDayOfYear4Weeks(end) +
            12 * 28 * (end.getFullYear() - start.getFullYear());

        // Standard rounding instead of always rounding up
        return Math.round((daysEnd - daysStart) / 7);
    };

    return getWeekDifference4Weeks(startDate, endDate);
}

export function formatDate(date: Date): string {
    const year: number = date.getFullYear();
    let month: number = date.getMonth() + 1; // getMonth() is zero-based
    let day: number = date.getDate();
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    let seconds: number = date.getSeconds();

    // Pad single digit day, month, hour, minute and second values
    const monthFormatted: string = month < 10 ? `0${month}` : month.toString();
    const dayFormatted: string = day < 10 ? `0${day}` : day.toString();
    const hoursFormatted: string = hours < 10 ? `0${hours}` : hours.toString();
    const minutesFormatted: string =
        minutes < 10 ? `0${minutes}` : minutes.toString();
    const secondsFormatted: string =
        seconds < 10 ? `0${seconds}` : seconds.toString();

    // Return the formatted date
    return `${year}-${monthFormatted}-${dayFormatted} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
}

export function parseCustomDateString(dateString: string): Date {
    // Regular expression to extract date components
    const dateRegex: RegExp = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
    const match: RegExpExecArray | null = dateRegex.exec(dateString);

    if (!match) {
        // The input string didn't match the expected format
        console.error("Invalid date format. Please use 'YYYY-MM-DD HH:MM:SS'.");
        return new Date();
    }

    // Extract components from the matched result
    const year: number = parseInt(match[1], 10);
    const month: number = parseInt(match[2], 10) - 1; // month is 0-based in JavaScript Date
    const day: number = parseInt(match[3], 10);
    const hour: number = parseInt(match[4], 10);
    const minute: number = parseInt(match[5], 10);
    const second: number = parseInt(match[6], 10);

    // Create a new Date object using the extracted components
    // This assumes the date string is in local time. If it's in UTC, use the Date.UTC() method instead.
    return new Date(year, month, day, hour, minute, second);
}

export const colorSet: string[] = [
    "#1570EF",
    "#A4BCFD",
    "#12B76A",
    "#065986",
    "#9E77ED",
    "#06ecca",
    "#ffa724",
    "#c8d047",
    "#c5aba3",
    "#c35294",
    "#8b4606",
    "#c6f5d6",
    "#5a19e3",
    "#8b2de5",
    "#34fff1",
];
