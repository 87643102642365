import { useEffect, useState } from "react";
import { Button, debounce } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCurrentTab } from "../../features/Tabs/tabSlice";
import Icon from "../Icon/Icon";
import SelectedRoleDesktop from "../SelectedRoleDesktop/SelectedRoleDesktop";
import { frontendIcon, searchIcon, closeIcon } from "../../css/icons";
import css from "./AddResourceModal.module.scss";
import {
    Resource,
    setCurrentResources,
} from "../../features/Resources/resourceSlice";
import { v4 as uuidv4 } from "uuid";
import ResourceService from "../../services/resource.service";
import SelectedResourceCard from "../SelectedResourceCard/SelectedResourceCard";
import { calculateWeeks } from "../../utils/helpers";

interface TabProps {
    openNewResources: boolean;
    toggleDrawer: (
        open: boolean
    ) => (
        event: React.KeyboardEvent | React.MouseEvent,
        reason?: "backdropClick" | "escapeKeyDown"
    ) => void;
}

interface Role {
    title: string;
    count: number;
    icon: React.ReactNode;
    price: number;
    role: string;
}

const resourceService = new ResourceService();

const AddResourceModal: React.FC<TabProps> = ({
    openNewResources,
    toggleDrawer,
}) => {
    const [resources, setResources] = useState([]);
    const [search, setSearch] = useState("");

    const dispatch = useAppDispatch();
    const currentResources = useAppSelector(
        (state) => state.resources.currentResources
    );
    const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);

    const weeks = calculateWeeks();

    const onAddSelected = () => {
        dispatch(
            setCurrentResources([
                ...currentResources,
                ...selectedRoles.map((role) => {
                    const computedPrice = 4 * 5 * 8 * role.price;
                    return {
                        ...role,
                        isUnique: [],
                        tabIndex: 4,
                        id: uuidv4(),
                        timeline: {
                            startWeek: weeks?.startWeek,
                            endWeek: weeks?.endWeek,
                        },
                        computedPrice,
                        weeklyWorkload: 5,
                        experience: 1,
                        workFormat: "remote",
                        location: "",
                        region: "",
                    };
                }),
            ])
        );
        setSelectedRoles([]);
    };

    const fetchResources = debounce(async () => {
        const res = await resourceService.getResourceRoles(
            search.length > 0 ? search : undefined
        );
        // const filteredResources = res.data.message.filter((resource: any) => {
        //     return (
        //         currentResources.findIndex(
        //             (currentResource: Resource) =>
        //                 currentResource.title === resource.resource_pools
        //         ) === -1
        //     ); // -1 means currentResource is not found in resources
        // });

        setResources(
            res.data.message.map((resource: any) => ({
                title: resource.resource_pools,
                icon: frontendIcon,
                role: resource.name,
                cost: resource.cost,
            }))
        );
    }, 1000);

    useEffect(() => {
        openNewResources && fetchResources();
    }, [search, openNewResources]);

    return (
        <Drawer
            anchor="right"
            open={openNewResources}
            onClose={(event, reason) => {
                toggleDrawer(false)(event as React.KeyboardEvent, reason);
                setSearch("");
                setSelectedRoles([]);
            }}
        >
            <Box
                className={css.drawerContainer}
                sx={{ overflowY: "scroll" }}
                role="presentation"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        borderBottom: "1px solid #e0e0e0",
                        paddingBottom: 1,
                        px: 3,
                    }}
                >
                    <Typography variant="h6" className={css.drawerTitle}>
                        Add Resources
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={(event) => {
                            toggleDrawer(false)(event);
                            setSearch("");
                            setSelectedRoles([]);
                        }}
                        aria-label="close"
                    >
                        <Icon path={closeIcon} />
                    </IconButton>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        paddingTop: "12px",
                        paddingBottom: "35px",
                        px: 3,
                        minHeight: "91vh",
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <div className={css.searchContainer}>
                            <Icon
                                path={searchIcon}
                                className={css.searchIcon}
                            />
                            <input
                                type="text"
                                className={css.search}
                                placeholder="Search resources"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                        </div>
                        {resources.map(({ title, icon, role, cost }) => {
                            const selected = selectedRoles.find(
                                (role) => role.title === title
                            );

                            const index = selected
                                ? selectedRoles.findIndex(
                                      (role) => role.title === title
                                  )
                                : -1;

                            return (
                                <SelectedResourceCard
                                    key={`${title}`}
                                    title={title}
                                    cost={4 * 5 * 8 * cost}
                                    selected={selected ? true : false}
                                    selectModal={true}
                                    handleButton={async () => {
                                        if (selected) {
                                            selectedRoles.splice(index, 1);
                                            setSelectedRoles(selectedRoles);
                                            setResources([...resources]);
                                        } else {
                                            setSelectedRoles([
                                                ...selectedRoles,
                                                {
                                                    title,
                                                    count: 1,
                                                    price: cost,
                                                    icon: (
                                                        <Icon
                                                            path={icon}
                                                            className={
                                                                css.selectedRoleIcon
                                                            }
                                                        />
                                                    ),
                                                    role,
                                                },
                                            ]);
                                        }
                                    }}
                                />
                            );
                        })}
                    </div>
                    <Button
                        color="primary"
                        onClick={(event) => {
                            onAddSelected();
                            toggleDrawer(false)(event);
                        }}
                        disabled={selectedRoles.length === 0}
                        className={css.confirmButton}
                        fullWidth
                        style={{
                            position: "fixed",
                            bottom: "10px",
                            width: "385px",
                        }}
                    >
                        Add Resources
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};

export default AddResourceModal;
