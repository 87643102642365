import { useState, useEffect } from "react";
import css from "./MonthlyPercentTabDesktop.module.scss";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import {
    chevronLeftIcon,
    chevronRightIcon,
    youpalLogo,
} from "../../../../../css/icons";
import Icon from "../../../../Icon/Icon";
import RoundedSlider from "../../../../RoundedSlider/RoundedSlider";
import { Button, Slider, SliderThumb } from "@mui/material";
import cn from "classnames";
import { setCreditCardPayment } from "../../../../../features/Discounts/discountSlice";
import ProjectPrice from "../../../../ProjectPrice/ProjectPrice";
import TopNavigationBar from "../../../../TopNavigationBar/TopNavigationBar";
import DiscountsCarousel from "../../../../DiscountsCarousel/DiscountsCarousel";
import ResourceService from "../../../../../services/resource.service";
import {
    calculateWeeksDifference,
    formatDate,
    getExperienceLabel,
} from "../../../../../utils/helpers";
import { setName } from "../../../../../features/Resources/resourceSlice";

interface TabProps {
    index: number;
    show?: boolean;
    done?: boolean;
    setIsDiscountOpen?: any;
}

const ThumbComponent = (props: any) => {
    const { children, className, ...other } = props;

    let fiftyClassName = "";
    if (props.ownerState.value[0] === 50) {
        // if 50, 50+
        fiftyClassName =
            other["data-index"] === 0 ? "first-thumb" : "second-thumb";
    } else {
        // if 50-, 50
        fiftyClassName =
            other["data-index"] === 0 ? "second-thumb" : "first-thumb";
    }

    return (
        <SliderThumb {...other} className={cn(className, fiftyClassName)}>
            {children}
        </SliderThumb>
    );
};
const resourceService = new ResourceService();

const MonthlyPercentTabDesktop: React.FC<TabProps> = ({
    index,
    show,
    done,
    setIsDiscountOpen,
}) => {
    const { currentTab } = useAppSelector((state) => state.tabs);
    const {
        name,
        resources,
        budget,
        duration,
        currency,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const {
        total_discount,
        discounts: { payment_duration_percentage_can_avail },
        discountPayload,
        discountPayload: { credit_card_payment_percent_per_month },
    } = useAppSelector((state) => state.discounts);

    const storedValue = parseFloat(credit_card_payment_percent_per_month);
    const arrayValue =
        storedValue > 50
            ? [50, storedValue]
            : storedValue < 50
            ? [storedValue, 50]
            : [50, 50];
    const [percent, setPercent] = useState(arrayValue);
    const dispatch = useAppDispatch();

    const onGoBack = () => {
        dispatch(setCurrentTab(11));
    };

    const onNext = () => {
        dispatch(setCurrentTab(13));
    };
    const [path, setPath] = useState("");
    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    const onDone = () => {
        dispatch(setCurrentTab(17));
        const resource_id =
            name && name.length > 0
                ? name
                : path && path.length > 0
                ? path
                : "";
        resourceService
            .addResource(
                resource_id,
                currency || "USD",
                budget || 10000,
                duration || 8,
                17,
                resources.map((res) => ({
                    role: res.role,
                    number: res.count,
                    experience: getExperienceLabel(res.experience),
                    duration: calculateWeeksDifference(
                        res.timeline?.startWeek,
                        res.timeline?.endWeek
                    ),
                    workload: res.weeklyWorkload,
                    start_week: 0,
                    end_week: 4,

                    title: res.title,
                    work_format: res.workFormat,
                    work_location: res.location,
                    resource_location: res.region,
                    start_week_date: formatDate(res.timeline?.startWeek),
                    end_week_date: formatDate(res.timeline?.endWeek),
                })),
                discountPayload.credit_card,
                discountPayload.credit_card_duration,
                discountPayload.frame_agreement,
                discountPayload.upfront_payment,
                discountPayload.credit_card_payment_percent_per_month
            )
            .then((res) => {
                dispatch(setName(res.data.message.name));
            });
    };

    const getMaxDiscount = () => {
        setPercent([50, 100]);
    };

    useEffect(() => {
        if (!show) {
            const perMonth = Number(percent.find((p) => p !== 50) ?? 50);
            document.dispatchEvent(
                new CustomEvent("update_discount", {
                    detail: {
                        total_amount: projectCost,
                        discount: {
                            ...discountPayload,
                            credit_card_payment_percent_per_month:
                                perMonth + "%",
                        },
                    },
                })
            );
            dispatch(setCreditCardPayment(perMonth + "%"));
        }
        if (done) {
            const perMonth = Number(percent.find((p) => p !== 50) ?? 50);
            document.dispatchEvent(
                new CustomEvent("update_discount", {
                    detail: {
                        total_amount: projectCost,
                        discount: {
                            ...discountPayload,
                            credit_card_payment_percent_per_month:
                                perMonth + "%",
                        },
                    },
                })
            );
            dispatch(setCreditCardPayment(perMonth + "%"));
            setIsDiscountOpen(false);
        }
    }, [percent, done]);

    return (
        <>
            <TabPanel index={12} value={index}>
                <TopNavigationBar />
                <DiscountsCarousel />
                <div className={css.wrapper}>
                    <div className={css.innerContainer}>
                        <div className={css.leftButton}>
                            <button className={css.nextIcon} onClick={onGoBack}>
                                <Icon path={chevronLeftIcon} />
                            </button>
                        </div>
                        <div className={css.content}>
                            <h1 className={css.title}>
                                What % of the payment can you do through credit
                                card every month?
                            </h1>

                            <div className={css.result}>
                                <p className={css.resultSubtext}>
                                    You will save
                                </p>
                                <p>
                                    <span className={css.resultGreen}>
                                        ${" "}
                                        {Number(total_discount).toLocaleString(
                                            undefined,
                                            {
                                                maximumFractionDigits: 0,
                                            }
                                        )}
                                    </span>
                                    <span className={css.resultSubtext}>
                                        {" "}
                                        /month
                                    </span>
                                </p>
                                <p>
                                    by paying{" "}
                                    <span className={css.orange}>
                                        {Number(
                                            percent.find((p) => p !== 50)
                                        ) || 50}{" "}
                                        %
                                    </span>{" "}
                                    of via credit card
                                </p>
                            </div>
                            <div className={css.sliderContainer}>
                                <Slider
                                    min={0}
                                    max={100}
                                    step={10}
                                    value={percent}
                                    components={{
                                        Thumb: ThumbComponent,
                                    }}
                                    sx={{
                                        track: {
                                            backgroundColor: "green !important",
                                        },
                                    }}
                                    color="secondary"
                                    onChange={(e: any) => {
                                        const pair = e.target.value;

                                        if (
                                            pair[0] < 50 &&
                                            pair[1] > 50 &&
                                            pair[1] === percent[1]
                                        ) {
                                            setPercent([pair[0], 50]);
                                            return;
                                        } else if (
                                            pair[0] < 50 &&
                                            pair[1] > 50 &&
                                            pair[1] !== percent[1]
                                        ) {
                                            setPercent([50, pair[1]]);
                                            return;
                                        }

                                        if (pair[1] > 50) {
                                            setPercent([50, pair[1]]);
                                        } else {
                                            setPercent([pair[0], 50]);
                                        }
                                    }}
                                />
                            </div>
                            <div className={css.cardFlex}>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                percent[0] === 50 &&
                                                percent[1] === 50,
                                        }
                                    )}
                                    onClick={() => setPercent([50, 50])}
                                >
                                    <p>50%</p>
                                    <p className={css.yellowSubtext}>
                                        Save 2.0%
                                    </p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                percent[0] === 50 &&
                                                percent[1] === 70,
                                        }
                                    )}
                                    onClick={() => setPercent([50, 70])}
                                >
                                    <p>70%</p>
                                    <p className={css.yellowSubtext}>
                                        Save 1.0%
                                    </p>
                                </div>
                                <div
                                    className={cn(
                                        css.card,
                                        css.cardSM,
                                        css.cardNo,
                                        {
                                            [css.selectedCard]:
                                                percent[0] === 50 &&
                                                percent[1] === 100,
                                        }
                                    )}
                                    onClick={() => setPercent([50, 100])}
                                >
                                    <p>100%</p>
                                    <p className={css.greenSubtext}>
                                        Save 3.4%
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={css.rightButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronRightIcon} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            width: "180px",
                        }}
                        onClick={onDone}
                    >
                        Applying Discount
                    </Button>
                </div>
            </TabPanel>
            {show && (
                <div className={css.innerContainer}>
                    <div className={css.content}>
                        <h1 className={css.title}>
                            What % of the payment can you do through credit card
                            every month?
                        </h1>

                        <div className={css.result}>
                            <p className={css.resultSubtext}>You will save</p>
                            <p>
                                <span className={css.resultGreen}>
                                    ${" "}
                                    {Number(total_discount).toLocaleString(
                                        undefined,
                                        {
                                            maximumFractionDigits: 0,
                                        }
                                    )}
                                </span>
                                <span className={css.resultSubtext}>
                                    {" "}
                                    /month
                                </span>
                            </p>
                            <p>
                                by paying{" "}
                                <span className={css.orange}>
                                    {Number(percent.find((p) => p !== 50)) ||
                                        50}{" "}
                                    %
                                </span>{" "}
                                of via credit card
                            </p>
                        </div>
                        <div className={css.sliderContainer}>
                            <Slider
                                min={0}
                                max={100}
                                step={10}
                                value={percent}
                                components={{
                                    Thumb: ThumbComponent,
                                }}
                                sx={{
                                    track: {
                                        backgroundColor: "green !important",
                                    },
                                }}
                                color="secondary"
                                onChange={(e: any) => {
                                    const pair = e.target.value;

                                    if (
                                        pair[0] < 50 &&
                                        pair[1] > 50 &&
                                        pair[1] === percent[1]
                                    ) {
                                        setPercent([pair[0], 50]);
                                        return;
                                    } else if (
                                        pair[0] < 50 &&
                                        pair[1] > 50 &&
                                        pair[1] !== percent[1]
                                    ) {
                                        setPercent([50, pair[1]]);
                                        return;
                                    }

                                    if (pair[1] > 50) {
                                        setPercent([50, pair[1]]);
                                    } else {
                                        setPercent([pair[0], 50]);
                                    }
                                }}
                            />
                        </div>
                        <div className={css.cardFlex}>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            percent[0] === 50 &&
                                            percent[1] === 50,
                                    }
                                )}
                                onClick={() => setPercent([50, 50])}
                            >
                                <p>50%</p>
                                <p className={css.yellowSubtext}>Save 2.0%</p>
                            </div>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            percent[0] === 50 &&
                                            percent[1] === 70,
                                    }
                                )}
                                onClick={() => setPercent([50, 70])}
                            >
                                <p>70%</p>
                                <p className={css.yellowSubtext}>Save 1.0%</p>
                            </div>
                            <div
                                className={cn(
                                    css.card,
                                    css.cardSM,
                                    css.cardNo,
                                    {
                                        [css.selectedCard]:
                                            percent[0] === 50 &&
                                            percent[1] === 100,
                                    }
                                )}
                                onClick={() => setPercent([50, 100])}
                            >
                                <p>100%</p>
                                <p className={css.greenSubtext}>Save 3.4%</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MonthlyPercentTabDesktop;
