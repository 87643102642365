import { useState, useEffect } from "react";
import css from "./InvoiceTermsTabMobile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { setCurrentTab } from "../../../../../../features/Tabs/tabSlice";
import { Button } from "@mui/material";
import cn from "classnames";
import { setInvoicePaymentTerms } from "../../../../../../features/Discounts/discountSlice";

interface TabProps {
    index: number;
}

const InvoiceTermsTabMobile: React.FC<TabProps> = ({ index }) => {
    const {
        discounts: { invoice_payment_terms_percentage_can_avail },
        total_discount,
        discountPayload,
    } = useAppSelector((state) => state.discounts);
    const {
        budget,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const dispatch = useAppDispatch();
    const [invoiceDurationVal, setInvoiceDurationVal] = useState("30 days");

    const onGoBack = () => {
        dispatch(setCurrentTab(12));
    };

    const onNext = () => {
        dispatch(setCurrentTab(14));
    };

    const getMaxDiscount = () => {
        setInvoiceDurationVal("60 days");
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_discount", {
                detail: {
                    total_amount: projectCost,
                    discount: {
                        ...discountPayload,
                        invoice_payment_terms: invoiceDurationVal,
                    },
                },
            })
        );
        dispatch(setInvoicePaymentTerms(invoiceDurationVal));
    }, [invoiceDurationVal]);

    return (
        <>
            <div className={css.wrapper}>
                <div className={css.content}>
                    <h1 className={css.title}>
                        What invoice payment terms can you offer us?
                    </h1>

                    <div className={css.cardFlex}>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    invoiceDurationVal === "60 days",
                            })}
                            onClick={() => setInvoiceDurationVal("60 days")}
                        >
                            <p>60 days</p>
                            <p className={css.greenSubtext}>Save 10%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    invoiceDurationVal === "45 days",
                            })}
                            onClick={() => setInvoiceDurationVal("45 days")}
                        >
                            <p>45 days</p>
                            <p className={css.greenSubtext}>Save 10%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    invoiceDurationVal === "30 days",
                            })}
                            onClick={() => setInvoiceDurationVal("30 days")}
                        >
                            <p>30 days</p>
                            <p className={css.greenSubtext}>Save 0%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    invoiceDurationVal === "15 days",
                            })}
                            onClick={() => setInvoiceDurationVal("15 days")}
                        >
                            <p>15 days</p>
                            <p className={css.greenSubtext}>Save 0%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    invoiceDurationVal === "7 days",
                            })}
                            onClick={() => setInvoiceDurationVal("7 days")}
                        >
                            <p>7 days</p>
                            <p className={css.greenSubtext}>Save 0%</p>
                        </div>
                    </div>

                    <div className={css.result}>
                        <p className={css.resultSubtext}>You will save</p>
                        <p>
                            <span className={css.resultGreen}>
                                ${" "}
                                {Number(total_discount).toLocaleString(
                                    undefined,
                                    {
                                        maximumFractionDigits: 1,
                                    }
                                )}{" "}
                            </span>
                            <span className={css.resultSubtext}> /month</span>
                        </p>
                    </div>
                </div>
                {/* 
                <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            padding: "12px",
                        }}
                        fullWidth
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </div>
                <div className={css.botMobileContainer}>
                    <BotMobile />
                </div> */}
            </div>
        </>
    );
};

export default InvoiceTermsTabMobile;
