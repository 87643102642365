import { useMemo, useState } from "react";
import {
    budgetUsedIcon,
    burgerDotsIcon,
    chevronDownWhiteIcon,
} from "../../css/icons";
import Icon from "../Icon/Icon";
import css from "./ResourceDropdown.module.scss";
import cn from "classnames";
import ContextMenu from "./ContextMenu/ContextMenu";

interface ResourceDropdownProps {
    count: number;
    budgetUsed: number;
    title: string;
    total: number;
    resources: any[];
    id: string;
    colors: string[];
}

const ResourceDropdown: React.FC<ResourceDropdownProps> = ({
    count,
    title,
    total,
    budgetUsed,
    resources,
    id,
    colors,
}) => {
    const [open, setOpen] = useState(false);
    const [contextOpen, setContextOpen] = useState<number | null>(null);
    const colorOptions = useMemo(
        () => [
            ["#039855", "#09824c"],
            ["2E90FA", "#1F7BDF"],
            ["#1F7BDF", "#196bc2"],
        ],
        []
    );

    const onBurgerClick = (index: number) => {
        setContextOpen(index);
    };

    return (
        <div className={css.dropdownWrapper}>
            {contextOpen !== null && (
                <div
                    className={css.backdrop}
                    onClick={() => setContextOpen(null)}
                ></div>
            )}
            <div className={css.header} style={{ backgroundColor: colors[0] }}>
                <div>
                    {count}x <span className={css.title}>{title}</span>
                </div>
                <button
                    type="button"
                    className={css.unstyledBtn}
                    onClick={() => setOpen(!open)}
                >
                    <Icon
                        path={chevronDownWhiteIcon}
                        className={cn({ [css.rotated]: open })}
                    />
                </button>
            </div>
            {open && (
                <div
                    className={css.content}
                    style={{ backgroundColor: colors[1] }}
                >
                    {resources.map((resource, index) => {
                        return (
                            <div
                                key={Math.random() + " " + index}
                                className={css.resourceRow}
                            >
                                <p>
                                    {title} #{index + 1}
                                </p>
                                <p className={css.rowTotal}>
                                    <span>
                                        $
                                        {total.toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}{" "}
                                        <span className={css.rowOpacity}>
                                            /month
                                        </span>
                                    </span>
                                    <button
                                        type="button"
                                        className={css.unstyledBtn}
                                        onClick={() => onBurgerClick(index)}
                                    >
                                        <Icon path={burgerDotsIcon} />
                                    </button>
                                    {contextOpen === index && (
                                        <ContextMenu id={id} count={count} />
                                    )}
                                </p>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className={css.footer} style={{ backgroundColor: colors[0] }}>
                <div className={css.budgetUsedBox}>
                    <Icon path={budgetUsedIcon} />
                    <span>
                        {Number(budgetUsed).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                        % <span className={css.footerOpacity}>budget used</span>
                    </span>
                </div>
                <div className={css.footerRight}>
                    $
                    {(total * count).toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                    })}{" "}
                    <span className={css.footerOpacity}>/month</span>
                </div>
            </div>
        </div>
    );
};

export default ResourceDropdown;
