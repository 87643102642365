import { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { debounce } from "lodash";
import css from "./TimelineTab.module.scss";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import TabPanel from "../../TabPanel";
import {
    completeResources,
    computeProjectTotals,
    setTimeline,
    setTimelineById,
} from "../../../../features/Resources/resourceSlice";
import TopNavigationBar from "../../../TopNavigationBar/TopNavigationBar";
import GanttComponent from "./GanttComponent";
import {
    calculateWeeks,
    calculateWeeksDifference,
} from "../../../../utils/helpers";

interface TabProps {
    index: number;
}
type ResourceObject = {
    id: string;
    computedPrice?: number;
    costDetails: { total_project_cost: number };
    count: number;
    price?: number;
    timeline?: {
        startWeek: Date;
        endWeek: Date;
    };
    weeklyWorkload?: number;
};

const TimelineTab: React.FC<TabProps> = ({ index }) => {
    const dispatch = useAppDispatch();
    const [showDurationWarning, setShowDurationWarning] = useState(false);
    const { budget, duration } = useAppSelector((state) => state.resources);
    const { currentTab } = useAppSelector((state) => state.tabs);
    const currentResources = useAppSelector(
        (state) => state.resources.currentResources
    );

    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1); // next month
    currentDate.setDate(1); // first day of the next month
    const minStartDate = new Date(currentDate); // Minimum allowed start date

    currentDate.setMonth(currentDate.getMonth() + 1); // one month after the next month
    const end = new Date(currentDate);

    const [tasks, setTasks] = useState<any[]>([]);

    const prevResourceRef = useRef<ResourceObject | null>(null);
    const handleDateChange = (task: any, start: any, end: any) => {
        const existingTask = tasks.find((t) => t.id === task.id);
        if (existingTask) {
            // Convert strings to Dates for comparison
            const newStartDate = new Date(start);
            const newEndDate = new Date(end);
            const existingStartDate = new Date(existingTask.start);
            const existingEndDate = new Date(existingTask.end);

            // If incoming values are same as existing ones, do nothing
            if (
                existingStartDate.getTime() === newStartDate.getTime() &&
                existingEndDate.getTime() === newEndDate.getTime()
            ) {
                return; // Exit the function, don't apply any changes
            }

            let startDate = new Date(start);
            let endDate = new Date(end);

            // Assuming minStartDate and duration are available in this scope
            if (startDate < new Date(minStartDate)) {
                startDate = new Date(minStartDate);
            }

            // Ensure the end date is always at least one week after the start date
            if (endDate <= startDate) {
                endDate = new Date(startDate);
                endDate.setDate(endDate.getDate() + 7); // At least one week after start date
            }
            //  else {
            //     const dayDifference =
            //         (endDate.getTime() - startDate.getTime()) /
            //         (1000 * 60 * 60 * 24);
            //     const weeksDifference = Math.ceil(dayDifference / 7); // Round up to the nearest whole number
            //     endDate = new Date(startDate);
            //     endDate.setDate(startDate.getDate() + weeksDifference * 7); // Adjust end date to the nearest week
            // }

            // Convert dates to strings for storage/update
            const updatedStart = startDate.toISOString().split("T")[0];
            const updatedEnd = endDate.toISOString().split("T")[0];

            // Assuming dispatch and setTimeline are available in this scope
            dispatch(
                setTimelineById({
                    id: existingTask.id, // Changed from index to the task itself since we're not using findIndex()
                    timeline: {
                        startWeek: startDate,
                        endWeek: endDate,
                    },
                })
            );

            // Assuming setTasks is available and correctly implemented in this scope
            setTasks((currentTasks) =>
                currentTasks.map((taskItem) => {
                    if (taskItem.id === task.id) {
                        // Deep copy object to ensure immutability
                        const updatedTask = {
                            ...taskItem,
                            start: updatedStart,
                            end: updatedEnd,
                        };
                        return updatedTask;
                    }
                    // Return all other items unchanged
                    return taskItem;
                })
            );
            const currentResource = currentResources.find(
                (r) => r.id === task.id
            );
            if (currentResource) {
                const isNewResource = !prevResourceRef.current;
                const isDifferentResource =
                    prevResourceRef.current?.id !== currentResource.id;
                const isStartWeekChanged =
                    prevResourceRef.current?.timeline?.startWeek.getTime() !==
                    currentResource.timeline?.startWeek.getTime();
                const isEndWeekChanged =
                    prevResourceRef.current?.timeline?.endWeek.getTime() !==
                    currentResource.timeline?.endWeek.getTime();

                if (
                    isNewResource ||
                    isDifferentResource ||
                    isStartWeekChanged ||
                    isEndWeekChanged
                ) {
                    fetchResourceCost(currentResource, {
                        startWeek: new Date(start),
                        endWeek: new Date(end),
                    });
                    prevResourceRef.current = currentResource; // Update the ref with the current resource
                }
            }
        }
    };

    const fetchResourceCost = debounce((resource, timeline) => {
        document.dispatchEvent(
            new CustomEvent("update_price_details", {
                detail: {
                    resource: {
                        ...resource,
                        timeline,
                    },
                },
            })
        );
    }, 500);

    const calculateCost = (
        resources: ResourceObject[],
        month: boolean
    ): string => {
        let totalCost = 0;
        const weeks = calculateWeeks();
        resources.forEach((resource) => {
            let cost = 0;
            // if (resource.computedPrice) {
            //     cost = resource.computedPrice * resource.count;
            // } else {

            // }
            const startWeek = resource.timeline?.startWeek || weeks?.startWeek;
            const endWeek = resource.timeline?.endWeek || weeks?.endWeek;
            const weeklyWorkload = resource.weeklyWorkload || 0;
            const price = resource.price || 0;
            const count = resource.count;
            cost = month
                ? count * price * 4 * weeklyWorkload * 8
                : resource?.costDetails?.total_project_cost;
            totalCost += cost;
        });

        return Number(totalCost).toLocaleString(undefined, {
            maximumFractionDigits: 0,
        });
    };

    const [done, setDone] = useState(false);

    useEffect(() => {
        setTasks(
            currentResources.map((resource) => {
                return {
                    id: resource.id,
                    name: resource.title,
                    start: resource.timeline?.startWeek,
                    end: resource.timeline?.endWeek,
                    progress: 100,
                    dependencies: "",
                    color: resource.color,
                };
            })
        );
        if (
            done &&
            currentResources.some((res) => res.costDetails) &&
            currentTab === 8
        ) {
            // dispatch(setCurrentTab(11));
            // dispatch(completeResources());
            setTimeout(() => {
                // dispatch(computeProjectTotals());
                dispatch(setCurrentTab(11));
            }, 1000);
            setDone(false);
        }
        if (duration) {
            const maxEndDate = new Date(); // Initialize with the current date
            maxEndDate.setMonth(maxEndDate.getMonth() + 1 + duration); // Assuming duration is in months

            const exceedsDuration = tasks.some((task) => {
                const taskEndDate = new Date(task.end);
                return taskEndDate > maxEndDate;
            });

            if (exceedsDuration) {
                setShowDurationWarning(true);
            } else {
                setShowDurationWarning(false);
            }
        }
    }, [currentResources]);

    const onDone = () => {
        for (let resource of currentResources) {
            document.dispatchEvent(
                new CustomEvent("update_price_details", {
                    detail: {
                        resource: {
                            ...resource,
                            timeline: {
                                startWeek: resource.timeline.startWeek,
                                endWeek: resource.timeline.endWeek,
                            },
                        },
                    },
                })
            );
        }

        dispatch(computeProjectTotals());

        setDone(true);
    };
    return (
        <TabPanel index={8} value={index}>
            <div className={css.wrapper}>
                <TopNavigationBar title="Timeline" tabNumber={4} />
                <div className={css.mainConatiner}>
                    <div className={css.header}>
                        <div>
                            <p>Monthly Cost</p>
                            <h6>
                                <span className={css.black}>
                                    ${calculateCost(currentResources, true)}
                                </span>{" "}
                                / month
                            </h6>
                        </div>
                        <div>
                            <p>Total Cost / Your Budget</p>
                            <h6>
                                <span className={css.green}>
                                    ${calculateCost(currentResources, false)}
                                </span>{" "}
                                {budget && (
                                    <>
                                        / $
                                        {Number(budget).toLocaleString(
                                            undefined,
                                            {
                                                maximumFractionDigits: 0,
                                            }
                                        )}
                                    </>
                                )}
                            </h6>
                        </div>
                    </div>

                    {showDurationWarning && (
                        <>
                            <div
                                /* className={cn(css.warning, {
                                    [css["hidden"]]:
                                        showDurationWarning === false,
                                })} */
                                className={cn(css.warning)}
                            >
                                Warning: The selected end date exceeds the
                                expected duration!
                            </div>
                        </>
                    )}

                    {tasks.length > 0 && (
                        <GanttComponent
                            tasks={tasks}
                            handleDateChange={debounce(handleDateChange, 250)}
                        />
                    )}
                    <div className={css.doneButton}>
                        <Button
                            color="primary"
                            onClick={onDone}
                            sx={{
                                padding: "12px 24px",
                            }}
                        >
                            Next Step
                        </Button>
                    </div>
                    <div className={css.doneButtonMobile}>
                        <Button
                            color="primary"
                            onClick={onDone}
                            sx={{
                                padding: "12px 24px",
                                width: "94%",
                                bottom: "20px",
                            }}
                        >
                            Setup Discounts
                        </Button>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default TimelineTab;
