import React, { useState } from "react";
import { Button, Input, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { chevronIcon, coinIcon } from "../../../../../css/icons";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import {
    setCurrency,
    setProjectBudget,
} from "../../../../../features/Resources/resourceSlice";
import Icon from "../../../../Icon/Icon";
import TabPanel from "../../../TabPanel";
import css from "./BudgetTabDesktop.module.scss";
import { scrollToSection } from "../../../../../utils/helpers";
import TopNavigationBar from "../../../../TopNavigationBar/TopNavigationBar";

interface TabProps {
    index: number;
}

const BudgetTabDesktop: React.FC<TabProps> = ({ index }) => {
    const [budget, setBudget] = useState<number | string>(0); // Initialize as a number
    const dispatch = useAppDispatch();
    const { currency } = useAppSelector((state) => state.resources);

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove commas and parse the input as a number
        const inputValue = event.target.value.replace(/,/g, "");
        const parsedValue = parseFloat(inputValue);

        // Check if parsing was successful and update the state accordingly
        if (!isNaN(parsedValue)) {
            setBudget(parsedValue); // Set as a number
        } else {
            setBudget(0); // Set to 0 for invalid input
        }
    };

    const onNext = () => {
        if (typeof budget === "number") {
            dispatch(setProjectBudget(budget));
            scrollToSection("DurationTab");
            // dispatch(setCurrentTab(2));
        }
    };

    return (
        <TabPanel index={1} value={index} id={"BudgetTab"}>
            <TopNavigationBar title="Project Setup" />
            <div className={css.wrapper}>
                <div className={css.questionContainer}>
                    <h1 className={css.title}>Set a budget for your project</h1>
                    <div className={css.inputContainer}>
                        <Icon path={coinIcon} />
                        <Input
                            sx={{ width: { xs: "200px", sm: "300px" } }}
                            value={budget.toLocaleString()} // Display with commas
                            onChange={handleAmountChange}
                            placeholder="Enter an amount"
                            disableUnderline
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    onNext();
                                }
                            }}
                        />
                        <Select
                            variant="standard"
                            sx={{ marginLeft: "30px" }}
                            disableUnderline
                            defaultValue="USD"
                            IconComponent={(props) => (
                                <Icon path={chevronIcon} {...props} />
                            )}
                            value={currency}
                            onChange={(e) =>
                                dispatch(setCurrency(e.target.value))
                            }
                        >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="EUR">EUR</MenuItem>
                            <MenuItem value="RUB">RUB</MenuItem>
                        </Select>
                    </div>
                    <Button
                        color="primary"
                        className={css.primaryButton}
                        onClick={onNext}
                    >
                        Next Step
                    </Button>
                </div>
            </div>
        </TabPanel>
    );
};

export default BudgetTabDesktop;
