import { useState, useEffect } from "react";
import css from "./WorkFormatTab.module.scss";

import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import TabPanel from "../../TabPanel";
import {
    chevronIcon,
    chevronLeftIcon,
    chevronRightIcon,
    onSiteIcon,
    remoteIcon,
    youpalLogo,
} from "../../../../css/icons";
import Icon from "../../../Icon/Icon";
import { Button, MenuItem, Select } from "@mui/material";
import { Grey } from "../../../../mui/colors";
import ResourcesCarousel from "../../../ResourcesCarousel/ResourcesCarousel";
import CloseButton from "../../../CloseButton/CloseButton";
import {
    computeProjectTotals,
    setCurrentResources,
    setLocation,
    setResourceTabIndex,
    setWorkFormat,
} from "../../../../features/Resources/resourceSlice";
import ResourceService from "../../../../services/resource.service";
import TopNavigationBar from "../../../TopNavigationBar/TopNavigationBar";
import PropertiesCarousel from "../../../PropertiesCarousel/PropertiesCarousel";
import ModalBase from "../../../Modals/ModalBase";
import { colorSet } from "../../../../utils/helpers";

interface TabProps {
    index: number;
}

const resourceService = new ResourceService();

const WorkFormatTab: React.FC<TabProps> = ({ index }) => {
    const [open, setOpen] = useState(false);
    const [isRemote, setIsRemote] = useState(true);
    const [locationValue, setLocaionValue] = useState("");
    const [locationOptions, setLocationOptions] = useState([]);

    const { currentResources } = useAppSelector((state) => state.resources);
    const { currentResourceIndex, currentTab } = useAppSelector(
        (state) => state.tabs
    );

    const dispatch = useAppDispatch();

    const onNext = () => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: 6,
            })
        );
        dispatch(setCurrentTab(6));
        // dispatch(
        //     setWorkFormat({
        //         index: currentResourceIndex,
        //         workFormat:
        //             isRemote || locationValue === "" ? "remote" : "on-site",
        //     })
        // );
        // dispatch(
        //     setLocation({
        //         index: currentResourceIndex,
        //         location: locationValue,
        //     })
        // );
        // document.dispatchEvent(
        //     new CustomEvent("update_price", {
        //         detail: {
        //             resource: {
        //                 ...currentResources[currentResourceIndex],
        //             },
        //         },
        //     })
        // );
    };

    const onPrevious = () => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: 4,
            })
        );
        dispatch(setCurrentTab(4));
    };

    const onDone = () => {
        const isAllResourcesDone = currentResources.every(
            (res) => res?.costDetails
        );

        if (isAllResourcesDone) {
            addColor();
            dispatch(setCurrentTab(8));
            // dispatch(completeResources());
            dispatch(computeProjectTotals());
        } else {
            setOpen(true);
        }
    };

    const addColor = () => {
        for (let resource of currentResources) {
            document.dispatchEvent(
                new CustomEvent("update_price_details", {
                    detail: {
                        resource: {
                            ...resource,
                            timeline: {
                                startWeek: resource.timeline.startWeek,
                                endWeek: resource.timeline.endWeek,
                            },
                        },
                    },
                })
            );
        }
        setTimeout(() => {
            dispatch(setCurrentTab(8));
        }, 1000);
        dispatch(
            setCurrentResources([
                ...currentResources.map((role, index) => {
                    return {
                        ...role,
                        color: colorSet[index % colorSet.length],
                    };
                }),
            ])
        );
    };

    const fetchLocations = async () => {
        const res = await resourceService.getLocationList();

        setLocationOptions(
            res.data.message.filter(
                (loc: { location: string }) => loc.location !== "Remote"
            )
        );
    };

    useEffect(() => {
        dispatch(
            setWorkFormat({
                index: currentResourceIndex,
                workFormat:
                    isRemote || locationValue === "" ? "remote" : "on-site",
            })
        );
        dispatch(
            setLocation({
                index: currentResourceIndex,
                location: isRemote ? "" : locationValue,
            })
        );
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        location: isRemote ? "" : locationValue,
                        workFormat:
                            isRemote || locationValue === ""
                                ? "remote"
                                : "on-site",
                    },
                },
            })
        );
        document.dispatchEvent(
            new CustomEvent("update_price_details", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        location: isRemote ? "" : locationValue,
                        workFormat:
                            isRemote || locationValue === ""
                                ? "remote"
                                : "on-site",
                    },
                },
            })
        );
    }, [isRemote, locationValue]);

    useEffect(() => {
        if (currentResources.length > 0 && currentResourceIndex >= 0) {
            const workFormat =
                currentResources[currentResourceIndex]?.workFormat;
            const location = currentResources[currentResourceIndex]?.location;

            setIsRemote(workFormat === "remote" ? true : false);
            setLocaionValue(location);
        }
    }, [currentResourceIndex, currentTab]);

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <TabPanel index={5} value={index}>
            <ModalBase
                onClose={() => setOpen(false)}
                open={open}
                title="Are you sure you want to proceed?"
            >
                <p>You haven't added details for other talents.</p>
                <div className={cn(css.flexSB, css.modalBtns)}>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => setOpen(false)}
                    >
                        Add Details
                    </Button>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => {
                            addColor();
                            // dispatch(setCurrentTab(8));
                            setOpen(false);
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </ModalBase>
            <TopNavigationBar />
            <div className={css.wrapper}>
                {/* <div className={css.header}>
                    <div className={css.logoFlex}>
                        <CloseButton />
                        <Icon path={youpalLogo} />
                    </div>
                    <Button
                        color="primary"
                        onClick={onDone}
                        sx={{
                            padding: "6px 32px",
                            borderRadius: "6px",
                            backgroundColor: Grey[900],
                            "&:hover": {
                                backgroundColor: Grey[900],
                            },
                            "&:disabled": {
                                opacity: 0.4,
                                color: "#fff",
                            },
                        }}
                        disabled={!isRemote && locationValue.length === 0}
                    >
                        Done
                    </Button>
                </div> */}
                <div className={css.resourceCarouselWrapper}>
                    <ResourcesCarousel resources={currentResources} />
                </div>
                <div className={css.mainConatiner}>
                    <PropertiesCarousel resources={currentResources} />
                    <div className={css.innerConatiner}>
                        <div className={css.leftButton}>
                            <button
                                className={css.nextIcon}
                                onClick={onPrevious}
                            >
                                <Icon path={chevronLeftIcon} />
                            </button>
                        </div>
                        <div>
                            <h1 className={css.title}>
                                How do you want them to work?
                            </h1>
                            <div className={css.cardContainer}>
                                <div
                                    className={cn(css.card, {
                                        [css.selectedCard]: isRemote,
                                    })}
                                    onClick={() => setIsRemote(true)}
                                >
                                    <Icon path={remoteIcon} />
                                    Remote
                                </div>
                                <div
                                    className={cn(css.card, {
                                        [css.selectedCard]: !isRemote,
                                    })}
                                    onClick={() => setIsRemote(false)}
                                >
                                    <Icon path={onSiteIcon} />
                                    On-site
                                </div>
                            </div>
                            {!isRemote && (
                                <div className={css.selectContainer}>
                                    <p className={css.subtitle}>
                                        Where do you want them to work from?
                                    </p>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        IconComponent={(props) => (
                                            <Icon
                                                path={chevronIcon}
                                                {...props}
                                            />
                                        )}
                                        typeof="select"
                                        defaultValue=""
                                        value={locationValue}
                                        onChange={(e) => {
                                            setLocaionValue(e.target.value);
                                        }}
                                    >
                                        {locationOptions.map(
                                            (option: {
                                                location: string;
                                                cost: number;
                                            }) => {
                                                return (
                                                    <MenuItem
                                                        value={option.location}
                                                        key={`location-option-${option.location}`}
                                                    >
                                                        {option.location}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div>
                            )}
                            <div className={css.experiencePriceBlock}>
                                <p className={css.title}>
                                    ${" "}
                                    {Number(
                                        currentResources[currentResourceIndex]
                                            ?.computedPrice
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                    <span className={css.subtext}>/ month</span>
                                    {!isRemote && (
                                        <>
                                            <br />
                                            <span className={css.subtext}>
                                                Accommodation cost
                                            </span>
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className={css.rightButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronRightIcon} />
                            </button>
                        </div>
                    </div>
                    <div className={css.doneButton}>
                        <Button
                            color="primary"
                            onClick={onDone}
                            sx={{
                                padding: "12px 24px",
                            }}
                        >
                            Finish Resource Configuration
                        </Button>
                    </div>
                    {/* <div className={css.buttonFlex}>
                        <Button
                            color="secondary"
                            onClick={onPrevious}
                            sx={{
                                padding: "8px 70px",
                                display: "block",
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            color="primary"
                            onClick={onNext}
                            sx={{
                                padding: "8px 70px",
                                display: "block",
                            }}
                            disabled={!isRemote && locationValue.length === 0}
                        >
                            Next
                        </Button>
                    </div> */}
                </div>
            </div>
        </TabPanel>
    );
};

export default WorkFormatTab;
