import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import FirstTenPercentTabDesktop from "./FirstTenPercentTabDesktop/FirstTenPercentTabDesktop";
import FirstTenPercentTabMobile from "./FirstTenPercentTabMobile/FirstTenPercentTabMobile";

interface TabProps {
    index: number;
}

const FirstTenPercentTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <FirstTenPercentTabDesktop index={index} />
    ) : (
        <FirstTenPercentTabMobile index={index} />
    );
};

export default FirstTenPercentTab;
