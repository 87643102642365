import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface DiscountType {
    credit_card: "Yes" | "No";
    credit_card_duration: "Weekly" | "Bi-monthly" | "Monthly";
    credit_card_payment_percent_per_month: string;
    upfront_payment: string;
    frame_agreement: "Yes" | "No";
    invoice_payment_terms: string;
}

interface InitialStateType {
    discountPayload: DiscountType;
    discounts: {
        credit_card_discount_per_month: number;
        credit_card_availed_percentage: number;
        credit_card_percentage_can_avail: number;
        payment_duration_discount_per_month: number;
        payment_duration_availed_percentage: number;
        payment_duration_percentage_can_avail: number;
        frame_agreement_discount_per_month: number;
        frame_agreement_availed_percentage: number;
        frame_agreement_percentage_can_avail: number;
        upfront_payment_discount_per_month: number;
        upfront_payment_availed_percentage: number;
        upfront_payment_percentage_can_avail: number;
        invoice_payment_terms_discount_per_month: number;
        invoice_payment_terms_availed_percentage: number;
        invoice_payment_terms_percentage_can_avail: number;
        total_discount_can_avail: number;
        total_discount_saving: number;
        more_discount_can_save: number;
    };
    total_discount: number;
}

const initialState: InitialStateType = {
    discountPayload: {
        credit_card: "No",
        credit_card_duration: "Weekly",
        credit_card_payment_percent_per_month: "50%",
        invoice_payment_terms: "30 days",
        upfront_payment: "50%",
        frame_agreement: "No",
    },
    discounts: {
        credit_card_discount_per_month: 0,
        credit_card_availed_percentage: 0,
        credit_card_percentage_can_avail: 0,
        payment_duration_discount_per_month: 0,
        payment_duration_availed_percentage: 0,
        payment_duration_percentage_can_avail: 0,
        frame_agreement_discount_per_month: 0,
        frame_agreement_availed_percentage: 0,
        frame_agreement_percentage_can_avail: 0,
        upfront_payment_discount_per_month: 0,
        upfront_payment_availed_percentage: 0,
        upfront_payment_percentage_can_avail: 0,
        invoice_payment_terms_discount_per_month: 0,
        invoice_payment_terms_availed_percentage: 0,
        invoice_payment_terms_percentage_can_avail: 0,
        total_discount_can_avail: 0,
        total_discount_saving: 0,
        more_discount_can_save: 0,
    },
    total_discount: 0,
};

const discountSlice = createSlice({
    name: "discountSlice",
    initialState,
    reducers: {
        reset(state) {
            state = initialState;
        },
        setCreditCard(state, action) {
            state.discountPayload.credit_card = action.payload;
        },
        setCreditCardDuration(state, action) {
            state.discountPayload.credit_card_duration = action.payload;
        },
        setCreditCardPayment(state, action) {
            state.discountPayload.credit_card_payment_percent_per_month =
                action.payload;
        },
        setInvoicePaymentTerms(state, action) {
            state.discountPayload.invoice_payment_terms = action.payload;
        },
        setUpfrontPayment(state, action) {
            state.discountPayload.upfront_payment = action.payload;
        },
        setFrameAgreement(state, action) {
            state.discountPayload.frame_agreement = action.payload;
        },
        setDiscounts(
            state,
            action: PayloadAction<{
                discounts: {
                    credit_card_discount_per_month: number;
                    credit_card_availed_percentage: number;
                    credit_card_percentage_can_avail: number;
                    payment_duration_discount_per_month: number;
                    payment_duration_availed_percentage: number;
                    payment_duration_percentage_can_avail: number;
                    frame_agreement_discount_per_month: number;
                    frame_agreement_availed_percentage: number;
                    frame_agreement_percentage_can_avail: number;
                    upfront_payment_discount_per_month: number;
                    upfront_payment_availed_percentage: number;
                    upfront_payment_percentage_can_avail: number;
                    invoice_payment_terms_discount_per_month: number;
                    invoice_payment_terms_availed_percentage: number;
                    invoice_payment_terms_percentage_can_avail: number;
                    total_discount_can_avail: number;
                    total_discount_saving: number;
                    more_discount_can_save: number;
                };
            }>
        ) {
            state.discounts = { ...action.payload.discounts };
            state.total_discount =
                action.payload.discounts.credit_card_discount_per_month +
                action.payload.discounts.payment_duration_discount_per_month +
                action.payload.discounts.frame_agreement_discount_per_month +
                action.payload.discounts.upfront_payment_discount_per_month +
                action.payload.discounts
                    .invoice_payment_terms_discount_per_month;
        },

        setStoredDiscounts(state, action) {
            const {
                credit_card,
                credit_card_duration,
                credit_card_amount,
                invoice_payment_terms,
                upfront_payment_discount,
                framework_agreement,

                credit_card_discount_per_month,
                credit_card_discount_percentage,

                payment_terms_discount_per_month,
                payment_terms_discount_percentage,

                frame_agreement_discount_per_month,
                frame_agreement_discount_percentage,

                upfront_payment_discount_per_month,
                upfront_payment_discount_percentage,

                total_discount_percentage,
                save_per_month,
                could_save_per_month,
            } = action.payload;

            state.discountPayload.credit_card = credit_card;
            state.discountPayload.credit_card_duration = credit_card_duration;
            state.discountPayload.credit_card_payment_percent_per_month =
                credit_card_amount;
            state.discountPayload.invoice_payment_terms = invoice_payment_terms;
            state.discountPayload.upfront_payment = upfront_payment_discount;
            state.discountPayload.frame_agreement = framework_agreement;
            state.discounts.credit_card_discount_per_month =
                credit_card_discount_per_month;
            state.discounts.credit_card_availed_percentage =
                credit_card_discount_percentage;
            state.discounts.payment_duration_discount_per_month =
                payment_terms_discount_per_month;
            state.discounts.payment_duration_availed_percentage =
                payment_terms_discount_percentage;
            state.discounts.frame_agreement_discount_per_month =
                frame_agreement_discount_per_month;
            state.discounts.frame_agreement_availed_percentage =
                frame_agreement_discount_percentage;
            state.discounts.upfront_payment_discount_per_month =
                upfront_payment_discount_per_month;
            state.discounts.upfront_payment_availed_percentage =
                upfront_payment_discount_percentage;
            state.discounts.total_discount_can_avail =
                total_discount_percentage;
            state.discounts.total_discount_saving = save_per_month;
            state.discounts.more_discount_can_save = could_save_per_month;
        },
    },
});

export const {
    setCreditCard,
    setCreditCardDuration,
    setCreditCardPayment,
    setFrameAgreement,
    setInvoicePaymentTerms,
    setUpfrontPayment,
    setDiscounts,
    setStoredDiscounts,
    reset,
} = discountSlice.actions;

export default discountSlice;
