import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import DurationTabDesktop from "./DurationTabDesktop/DurationTabDesktop";
import DurationTabMobile from "./DurationTabMobile/DurationTabMobile";

interface TabProps {
    index: number;
}

const DurationTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    /* return width > MOBILE_SCREEN_WIDTH ? (
        <DurationTabDesktop index={index} />
    ) : (
        <DurationTabMobile index={index} />
    ); */

    return <DurationTabDesktop index={index} />;
};

export default DurationTab;
