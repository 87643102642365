interface TabPanelProps {
    value: string | number;
    index: string | number;
    children: React.ReactNode | React.ReactNode[];
    id?: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ index, value, children, id }) => {
    if (value !== index) return null;
    return (
        <div role="tabpanel" id={id}>
            {value === index && children}
        </div>
    );
};

export default TabPanel;
