import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import HomeTabDesktop from "./HomeTabDesktop/HomeTabDesktop";
import HomeTabMobile from "./HomeTabMobile/HomeTabMobile";

interface TabProps {
    index: number;
}

const HomeTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    /* return width > MOBILE_SCREEN_WIDTH ? (
        <HomeTabDesktop index={index} />
    ) : (
        <HomeTabMobile index={index} />
    ); */

    return <HomeTabDesktop index={index} />;
};

export default HomeTab;
