import { Button } from "@mui/material";
import Icon from "../Icon/Icon";
import { Box } from "@mui/material";
import { arrowLeftLargeIcon, arrowLeftIcon } from "../../css/icons";
import RoundedSlider from "../RoundedSlider/RoundedSlider";
import css from "./DiscountsMobileCommon.module.scss";
import ProjectPrice from "../ProjectPrice/ProjectPrice";

interface DiscountsMobileCommonProps {
    onGoBack: () => void;
    onCancel: () => void;
    current: number;
    total?: number;
    saving?: number;
    onGetDiscount?: () => void;
}

const DiscountsMobileCommon: React.FC<DiscountsMobileCommonProps> = ({
    onGoBack,
    onCancel,
    current,
    total,
    saving,
    onGetDiscount,
}) => {
    return (
        <>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <Box sx={{ display: "flex" }} className={css.container}>
                    <Box>
                        <button className={css.prevbutton} onClick={onGoBack}>
                            <Icon path={arrowLeftIcon} />
                        </button>
                    </Box>
                    <Box>
                        <div className={css.mobileTitle}>Discounts</div>
                    </Box>
                    <Box></Box>
                </Box>
            </Box>
            <div className={css.budgetContainer}>
                <small>
                    Save upto $
                    {Number(saving).toLocaleString(undefined, {
                        maximumFractionDigits: 1,
                    })}
                    /month
                </small>
                <p>
                    $
                    {current.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                    })}
                    <span>
                        {" "}
                        / $
                        {total?.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                    </span>
                </p>
            </div>
            {/* <div className={css.header}>
                <div>
                    <button
                        type="button"
                        className={css.prevbutton}
                        onClick={onGoBack}
                    >
                        <Icon path={arrowLeftLargeIcon} />
                    </button>
                    <p className={css.titleOpacity}>Discounts</p>
                </div>
                <Button color="info" onClick={onCancel}>
                    Cancel
                </Button>
            </div> */}
            {/* <div className={css.discountBanner}>
                <div>
                    Save upto{" "}
                    <p>
                        <span className={css.greenBold}>
                            $
                            {Number(saving).toLocaleString(undefined, {
                                maximumFractionDigits: 1,
                            })}
                        </span>{" "}
                        <span className={css.discountBannerOpacity}>
                            {" "}
                            / month
                        </span>
                    </p>
                </div>
                <Button
                    color="primary"
                    sx={{
                        borderRadius: "4px",
                        backgroundColor: "#039855",
                        padding: "8px 16px",
                        transition: "200ms",
                        fontSize: "14px",
                        lineHeight: "20px",
                        "&:hover": {
                            backgroundColor: "#039855",
                            opacity: 0.8,
                        },
                    }}
                    onClick={onGetDiscount}
                >
                    Get Discount
                </Button>
            </div> */}
            {/* {total ? (
                <div className={css.roundedSliderContainer}>
                    <RoundedSlider current={current} total={total} />
                </div>
            ) : (
                <ProjectPrice />
            )} */}
        </>
    );
};

export default DiscountsMobileCommon;
