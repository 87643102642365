import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import DiscountSummaryTabDesktop from "./DiscountSummaryTabDesktop/DiscountSummaryTabDesktop";
import DiscountSummaryTabMobile from "./DiscountSummaryTabMobile/DiscountSummaryTabMobile";

interface TabProps {
    index: number;
}

const FrameAgreementTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <DiscountSummaryTabDesktop index={index} />
    ) : (
        <DiscountSummaryTabMobile index={index} />
    );
};

export default FrameAgreementTab;
