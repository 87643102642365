import cn from "classnames";
import css from "./Input.module.scss";

interface InputProps {
    fieldName: string;
    label: string;
    type: string;
    placeholder: string;
    className?: string;
    error?: any;
    disabled?: boolean;
    value?: string;
    postfix?: React.ReactNode;
    onChange?: (e: any) => void;
}

const Input: React.FC<InputProps> = ({
    fieldName,
    label,
    type,
    placeholder,
    className,
    error,
    disabled = false,
    value,
    postfix,
    onChange,
}) => {
    return (
        <div className={cn(className, css.inputContainer)}>
            <span className={css.label}>{label}</span>
            <div className={css.inputWrapper}>
                <input
                    disabled={disabled}
                    name={fieldName}
                    className={cn(css.input, {
                        [css.hasError]: error,
                    })}
                    type={type}
                    placeholder={placeholder}
                    value={value ?? undefined}
                    onChange={onChange}
                />
                <div className={css.postfix}>{postfix}</div>
            </div>
            <p className={css.inputError}>{error?.message}</p>
        </div>
    );
};

export default Input;
