import { Warning } from "../../mui/colors";
import css from "./RoundedSlider.module.scss";

interface RoundedSliderProps {
    current: number;
    total: number;
}

const RoundedSlider: React.FC<RoundedSliderProps> = ({
    current: currentVal,
    total,
}) => {
    const current = currentVal > total ? total : currentVal;
    const degrees = (current / total) * 180;
    return (
        <div
            className={css.circle}
            style={{
                background:
                    degrees < 90
                        ? `conic-gradient(
                            at 50% 100%,
                            #E5EAFC 270deg,
                            ${Warning[400]} 270deg,
                            ${Warning[400]} ${270 + degrees}deg,
                            #E5EAFC ${270 + degrees}deg
                        )`
                        : `conic-gradient(
                            at 50% 100%,
                            ${Warning[400]} ${Math.abs(90 - degrees)}deg,
                            #E5EAFC ${Math.abs(90 - degrees)}deg,
                            #E5EAFC 90deg,
                            ${Warning[400]} 90deg
                        )`,
            }}
        >
            <div className={css.innerCircle}>
                <p className={css.percent}>
                    {Math.round((current / total) * 100)}%
                </p>
                <p className={css.bottomRow}>
                    <span className={css.orange}>
                        $
                        {currentVal.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}{" "}
                    </span>
                    <span className={css.grey}>
                        / $
                        {total.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                    </span>
                </p>
            </div>
            <div className={css.leftRoundCorner}></div>
            <div className={css.rightRoundCorner}></div>
            <div className={css.middleCircle}>
                <div
                    className={css.mark}
                    style={{
                        left: `max(calc(50% - ${
                            50 * Math.cos((degrees * Math.PI) / 180)
                        }%), 0px)`,
                        bottom: `calc(${
                            100 * Math.sin((degrees * Math.PI) / 180)
                        }%)`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default RoundedSlider;
