import { useState, useEffect } from "react";
import css from "./FrameAgreementTabDesktop.module.scss";
import TabPanel from "../../../TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import {
    chevronLeftIcon,
    chevronRightIcon,
    youpalLogo,
} from "../../../../../css/icons";
import Icon from "../../../../Icon/Icon";
import RoundedSlider from "../../../../RoundedSlider/RoundedSlider";
import { Button } from "@mui/material";
import cn from "classnames";
import { setFrameAgreement } from "../../../../../features/Discounts/discountSlice";
import ProjectPrice from "../../../../ProjectPrice/ProjectPrice";
import DiscountsCarousel from "../../../../DiscountsCarousel/DiscountsCarousel";
import TopNavigationBar from "../../../../TopNavigationBar/TopNavigationBar";
import ResourceService from "../../../../../services/resource.service";
import {
    calculateWeeksDifference,
    formatDate,
    getExperienceLabel,
} from "../../../../../utils/helpers";
import { setName } from "../../../../../features/Resources/resourceSlice";

interface TabProps {
    index: number;
    show?: boolean;
    done?: boolean;
    setIsDiscountOpen?: any;
}
const resourceService = new ResourceService();
const FrameAgreementTabDesktop: React.FC<TabProps> = ({
    index,
    show,
    done,
    setIsDiscountOpen,
}) => {
    const { currentTab } = useAppSelector((state) => state.tabs);
    const {
        name,
        resources,
        budget,
        duration,
        currency,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const {
        total_discount,
        discounts: { frame_agreement_percentage_can_avail },
        discountPayload,
        discountPayload: { frame_agreement },
    } = useAppSelector((state) => state.discounts);

    const [frameAgreementVal, setFrameAgreementVal] = useState(
        frame_agreement === "Yes" ? true : false
    );
    const dispatch = useAppDispatch();

    const onGoBack = () => {
        dispatch(setCurrentTab(14));
    };

    const onNext = () => {
        dispatch(setCurrentTab(16));
    };
    const [path, setPath] = useState("");
    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    const onDone = () => {
        dispatch(setCurrentTab(17));
        const resource_id =
            name && name.length > 0
                ? name
                : path && path.length > 0
                ? path
                : "";
        resourceService
            .addResource(
                resource_id,
                currency || "USD",
                budget || 10000,
                duration || 8,
                17,
                resources.map((res) => ({
                    role: res.role,
                    number: res.count,
                    experience: getExperienceLabel(res.experience),
                    duration: calculateWeeksDifference(
                        res.timeline?.startWeek,
                        res.timeline?.endWeek
                    ),
                    workload: res.weeklyWorkload,
                    start_week: 0,
                    end_week: 4,

                    title: res.title,
                    work_format: res.workFormat,
                    work_location: res.location,
                    resource_location: res.region,
                    start_week_date: formatDate(res.timeline?.startWeek),
                    end_week_date: formatDate(res.timeline?.endWeek),
                })),
                discountPayload.credit_card,
                discountPayload.credit_card_duration,
                discountPayload.frame_agreement,
                discountPayload.upfront_payment,
                discountPayload.credit_card_payment_percent_per_month
            )
            .then((res) => {
                dispatch(setName(res.data.message.name));
            });
    };

    const getMaxDiscount = () => {
        setFrameAgreementVal(true);
    };

    useEffect(() => {
        if (!show) {
            document.dispatchEvent(
                new CustomEvent("update_discount", {
                    detail: {
                        total_amount: projectCost,
                        discount: {
                            ...discountPayload,
                            frame_agreement: frameAgreementVal ? "Yes" : "No",
                        },
                    },
                })
            );
            dispatch(setFrameAgreement(frameAgreementVal ? "Yes" : "No"));
        }
        if (done) {
            document.dispatchEvent(
                new CustomEvent("update_discount", {
                    detail: {
                        total_amount: projectCost,
                        discount: {
                            ...discountPayload,
                            frame_agreement: frameAgreementVal ? "Yes" : "No",
                        },
                    },
                })
            );
            dispatch(setFrameAgreement(frameAgreementVal ? "Yes" : "No"));
            setIsDiscountOpen(false);
        }
    }, [frameAgreementVal, done]);

    return (
        <>
            <TabPanel index={15} value={index}>
                <TopNavigationBar />
                <DiscountsCarousel />
                <div className={css.wrapper}>
                    <div className={css.innerContainer}>
                        <div className={css.leftButton}>
                            <button className={css.nextIcon} onClick={onGoBack}>
                                <Icon path={chevronLeftIcon} />
                            </button>
                        </div>

                        <div className={css.content}>
                            <h1 className={css.title}>
                                Can you offer us a frame agreement reaching over
                                the next 3 years?
                            </h1>
                            <div className={css.cardFlex}>
                                <div
                                    className={cn(css.card, css.cardYes, {
                                        [css.selectedCard]: frameAgreementVal,
                                    })}
                                    onClick={() => setFrameAgreementVal(true)}
                                >
                                    <p>Yes</p>
                                    <p className={css.greenSubtext}>
                                        Save up to 2%
                                    </p>
                                </div>
                                <div
                                    className={cn(css.card, css.cardNo, {
                                        [css.selectedCard]: !frameAgreementVal,
                                    })}
                                    onClick={() => setFrameAgreementVal(false)}
                                >
                                    <p>No</p>
                                    <p className={css.redSubtext}>
                                        I don’t need a discount
                                    </p>
                                </div>
                            </div>
                            <div className={css.result}>
                                <p className={css.resultSubtext}>
                                    You will save
                                </p>
                                <p>
                                    <span className={css.resultGreen}>
                                        ${" "}
                                        {Number(total_discount).toLocaleString(
                                            undefined,
                                            {
                                                maximumFractionDigits: 0,
                                            }
                                        )}{" "}
                                    </span>
                                    <span className={css.resultSubtext}>
                                        {" "}
                                        /month
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className={css.rightButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronRightIcon} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            width: "180px",
                        }}
                        onClick={onDone}
                    >
                        Applying Discount
                    </Button>
                </div>
            </TabPanel>

            {show && (
                <div className={css.innerContainer}>
                    <div className={css.content}>
                        <h1 className={css.title}>
                            Can you offer us a frame agreement reaching over the
                            next 3 years?
                        </h1>
                        <div className={css.cardFlex}>
                            <div
                                className={cn(css.card, css.cardYes, {
                                    [css.selectedCard]: frameAgreementVal,
                                })}
                                onClick={() => setFrameAgreementVal(true)}
                            >
                                <p>Yes</p>
                                <p className={css.greenSubtext}>
                                    Save up to 2%
                                </p>
                            </div>
                            <div
                                className={cn(css.card, css.cardNo, {
                                    [css.selectedCard]: !frameAgreementVal,
                                })}
                                onClick={() => setFrameAgreementVal(false)}
                            >
                                <p>No</p>
                                <p className={css.redSubtext}>
                                    I don’t need a discount
                                </p>
                            </div>
                        </div>
                        <div className={css.result}>
                            <p className={css.resultSubtext}>You will save</p>
                            <p>
                                <span className={css.resultGreen}>
                                    ${" "}
                                    {Number(total_discount).toLocaleString(
                                        undefined,
                                        {
                                            maximumFractionDigits: 0,
                                        }
                                    )}{" "}
                                </span>
                                <span className={css.resultSubtext}>
                                    {" "}
                                    /month
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FrameAgreementTabDesktop;
