import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import FrameAgreementTabDesktop from "./FrameAgreementTabDesktop/FrameAgreementTabDesktop";
import FrameAgreementTabMobile from "./FrameAgreementTabMobile/FrameAgreementTabMobile";

interface TabProps {
    index: number;
}

const FrameAgreementTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <FrameAgreementTabDesktop index={index} />
    ) : (
        <FrameAgreementTabMobile index={index} />
    );
};

export default FrameAgreementTab;
