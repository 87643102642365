import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { chevronLeftIcon } from "../../css/icons";
import {
    setCurrentTab,
    reset as resetTabs,
} from "../../features/Tabs/tabSlice";
import Icon from "../Icon/Icon";
import css from "./ClsoeButton.module.scss";
import ModalBase from "../Modals/ModalBase";
import { Button } from "@mui/material";
import {
    completeResources,
    deleteAllResources,
    setIsSaveModalOpen,
    setName,
} from "../../features/Resources/resourceSlice";
import { reset as resetResources } from "../../features/Resources/resourceSlice";
import { reset as resetDiscounts } from "../../features/Discounts/discountSlice";
import ResourceService from "../../services/resource.service";
import {
    calculateWeeksDifference,
    formatDate,
    getExperienceLabel,
} from "../../utils/helpers";

const resourceService = new ResourceService();

const CloseButton = () => {
    const dispatch = useAppDispatch();
    const { currentTab } = useAppSelector((state) => state.tabs);
    const { currentResources, budget, duration, currency } = useAppSelector(
        (state) => state.resources
    );
    const [open, setOpen] = useState(false);

    const onClick = () => {
        if (currentTab >= 1 && currentTab <= 3) {
            dispatch(setCurrentTab(0));
        } else if (currentTab >= 4 && currentTab <= 7) {
            dispatch(setCurrentTab(1));
        } else if (currentTab >= 8 && currentTab <= 10) {
            dispatch(setCurrentTab(4));
        } else if (currentTab >= 11 && currentTab <= 16) {
            dispatch(setCurrentTab(8));
        } else if (currentTab > 16) {
            dispatch(setCurrentTab(11));
        }
        // dispatch(
        //     setCurrentTab(
        //         currentTab === 3
        //             ? currentTab - 2
        //             : currentTab === 11
        //             ? currentTab - 3
        //             : currentTab - 1
        //     )
        // );
        // setOpen(true);
    };
    const [path, setPath] = useState("");
    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    const onSave = () => {
        const resource_id = path && path.length > 0 ? path : "";
        resourceService
            .addResourceAsDraft(
                resource_id,
                currency || "USD",
                budget || 10000,
                duration || 8,
                currentTab,
                currentResources.map((res) => ({
                    role: res.role,
                    number: res.count,
                    experience: getExperienceLabel(res.experience),
                    duration: calculateWeeksDifference(
                        res.timeline?.startWeek,
                        res.timeline?.endWeek
                    ),
                    workload: res.weeklyWorkload,
                    start_week: 0,
                    end_week: 4,

                    title: res.title,
                    work_format: res.workFormat,
                    work_location: res.location,
                    resource_location: res.region,
                    start_week_date: formatDate(res.timeline?.startWeek),
                    end_week_date: formatDate(res.timeline?.endWeek),
                })),
                "No",
                "Monthly",
                "No",
                "0%",
                "0%"
            )
            .then((res) => {
                dispatch(completeResources());
                dispatch(setName(res.data.message.name));
                onClose();
                dispatch(setIsSaveModalOpen(true));
            });
    };

    const onClose = () => {
        dispatch(deleteAllResources());
        dispatch(resetDiscounts());
        dispatch(resetResources());
        dispatch(resetTabs());
        dispatch(setCurrentTab(0));
    };

    return (
        <div>
            <ModalBase
                title=""
                onClose={() => setOpen(false)}
                open={open}
                // hasCloseButton={false}
            >
                <div className={css.customModal}>
                    <h2>Exit Now?</h2>
                    <p className={css.modalText}>
                        Are you sure you want to exit? All your resources and
                        data will be lost
                    </p>
                    <div className={css.modalButtons}>
                        <Button color="secondary" fullWidth onClick={onClose}>
                            Exit
                        </Button>
                        <Button color="primary" fullWidth onClick={onSave}>
                            Save Now
                        </Button>
                    </div>
                </div>
            </ModalBase>
            <button type="button" className={css.closeBtn} onClick={onClick}>
                <Icon path={chevronLeftIcon} />
                <span>Back</span>
            </button>
        </div>
    );
};

export default CloseButton;
