import { useState } from "react";
import { Button, Input, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import TabPanel from "../../../TabPanel";
import {
    chevronIcon,
    calendarIcon,
    arrowLeftIcon,
} from "../../../../../css/icons";
import Icon from "../../../../Icon/Icon";
import css from "./DurationTabDesktop.module.scss";
import { setProjectDuration } from "../../../../../features/Resources/resourceSlice";
import { scrollToSection } from "../../../../../utils/helpers";

interface TabProps {
    index: number;
}

const DurationTabDeskttop: React.FC<TabProps> = ({ index }) => {
    const [duration, setDuration] = useState<number>();
    const [durationUnit, setDurationUnit] = useState<"months" | "years">(
        "months"
    );
    const { budget } = useAppSelector((state) => state.resources);
    const dispatch = useAppDispatch();

    const onPrev = () => {
        // dispatch(setCurrentTab(1));
        scrollToSection("BudgetTab");
    };

    const onNext = () => {
        if (budget === undefined) return;

        if (duration !== undefined) {
            dispatch(
                setProjectDuration(
                    duration * (durationUnit === "years" ? 12 : 1)
                )
            );
            dispatch(setCurrentTab(3));
        }
    };

    return (
        <TabPanel index={1} value={index} id={"DurationTab"}>
            <div className={css.wrapper}>
                <div className={css.questionContainer}>
                    <h1 className={css.title}>What’s the project duration?</h1>
                    <div className={css.inputContainer}>
                        <Icon path={calendarIcon} />
                        <Input
                            sx={{ width: { xs: "200px", sm: "300px" } }}
                            value={duration}
                            onChange={(e) => setDuration(+e.target.value)}
                            placeholder="Enter duration"
                            disableUnderline
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    onNext();
                                }
                            }}
                        />
                        <Select
                            variant="standard"
                            value={durationUnit}
                            disableUnderline
                            defaultValue={"months"}
                            onChange={(e) => {
                                setDurationUnit(
                                    e.target.value as "months" | "years"
                                );
                            }}
                            IconComponent={(props) => (
                                <Icon path={chevronIcon} {...props} />
                            )}
                        >
                            <MenuItem value={"months"}>months</MenuItem>
                            <MenuItem value={"years"}>years</MenuItem>
                        </Select>
                    </div>
                    <div className={css.buttonConatiner}>
                        <button className={css.prevbutton} onClick={onPrev}>
                            <Icon path={arrowLeftIcon} />
                        </button>
                        <Button
                            color="primary"
                            onClick={onNext}
                            className={css.primaryButton}
                        >
                            Next Step
                        </Button>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default DurationTabDeskttop;
