import { useAppDispatch } from "../../../app/hooks";
import { setCount } from "../../../features/Resources/resourceSlice";
import css from "./ContextMenu.module.scss";

interface ContextMenuProps {
    id: string;
    count: number;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ id, count }) => {
    const dispatch = useAppDispatch();

    const onDuplicate = () => {
        dispatch(setCount({ id, value: count + 1 }));
    };

    const onDelete = () => {
        dispatch(setCount({ id, value: count - 1 }));
    };

    const onDeleteAll = () => {
        dispatch(setCount({ id, value: 0 }));
    };

    return (
        <div className={css.wrapper}>
            <div className={css.item} onClick={onDuplicate}>
                Duplicate
            </div>
            <div className={css.item} onClick={onDelete}>
                Delete
            </div>
            {count > 1 && (
                <div className={css.item} onClick={onDeleteAll}>
                    Delete All
                </div>
            )}
        </div>
    );
};

export default ContextMenu;
