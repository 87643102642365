import { useState, useEffect } from "react";
import css from "./MonthlyPercentTabMobile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { setCurrentTab } from "../../../../../../features/Tabs/tabSlice";
import { Button, Slider, SliderThumb } from "@mui/material";
import cn from "classnames";
import DiscountsMobileCommon from "../../../../../DiscountsMobileCommon/DiscountsMobileCommon";
import BotMobile from "../../../../../BotMobile/BotMobile";
import { setCreditCardPayment } from "../../../../../../features/Discounts/discountSlice";

interface TabProps {
    index: number;
}

const ThumbComponent = (props: any) => {
    const { children, className, ...other } = props;

    let fiftyClassName = "";
    if (props.ownerState.value[0] === 50) {
        // if 50, 50+
        fiftyClassName =
            other["data-index"] === 0 ? "first-thumb" : "second-thumb";
    } else {
        // if 50-, 50
        fiftyClassName =
            other["data-index"] === 0 ? "second-thumb" : "first-thumb";
    }

    return (
        <SliderThumb {...other} className={cn(className, fiftyClassName)}>
            {children}
        </SliderThumb>
    );
};

const MonthlyPercentTabMobile: React.FC<TabProps> = ({ index }) => {
    const {
        budget,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const {
        total_discount,
        discounts: { payment_duration_percentage_can_avail },
        discountPayload,
    } = useAppSelector((state) => state.discounts);
    const [percent, setPercent] = useState([50, 50]);
    const dispatch = useAppDispatch();

    const onGoBack = () => {
        dispatch(setCurrentTab(11));
    };

    const onNext = () => {
        dispatch(setCurrentTab(13));
    };

    const getMaxDiscount = () => {
        setPercent([50, 100]);
    };

    useEffect(() => {
        const perMonth = Number(percent.find((p) => p !== 50)) || 50;
        document.dispatchEvent(
            new CustomEvent("update_discount", {
                detail: {
                    total_amount: projectCost,
                    discount: {
                        ...discountPayload,
                        credit_card_payment_percent_per_month: perMonth + "%",
                    },
                },
            })
        );
        dispatch(setCreditCardPayment(perMonth + "%"));
    }, [percent]);

    return (
        <>
            <div className={css.wrapper}>
                <div className={css.content}>
                    <h1 className={css.title}>
                        What % of the payment can you do through credit card
                        every month?
                    </h1>

                    <div className={css.result}>
                        <p className={css.resultSubtext}>You will save</p>
                        <p>
                            <span className={css.resultGreen}>
                                ${" "}
                                {Number(total_discount).toLocaleString(
                                    undefined,
                                    {
                                        maximumFractionDigits: 1,
                                    }
                                )}{" "}
                            </span>
                            <span className={css.resultSubtext}> /month</span>
                        </p>
                        <p>
                            by paying{" "}
                            <span className={css.orange}>
                                {" "}
                                {Number(percent.find((p) => p !== 50)) || 50} %
                            </span>{" "}
                            of via credit card
                        </p>
                    </div>
                    <div className={css.sliderContainer}>
                        <Slider
                            min={0}
                            max={100}
                            step={10}
                            value={percent}
                            components={{
                                Thumb: ThumbComponent,
                            }}
                            sx={{
                                track: {
                                    backgroundColor: "green !important",
                                },
                            }}
                            color="secondary"
                            onChange={(e: any) => {
                                const pair = e.target.value;

                                if (
                                    pair[0] < 50 &&
                                    pair[1] > 50 &&
                                    pair[1] === percent[1]
                                ) {
                                    setPercent([pair[0], 50]);
                                    return;
                                } else if (
                                    pair[0] < 50 &&
                                    pair[1] > 50 &&
                                    pair[1] !== percent[1]
                                ) {
                                    setPercent([50, pair[1]]);
                                    return;
                                }

                                if (pair[1] > 50) {
                                    setPercent([50, pair[1]]);
                                } else {
                                    setPercent([pair[0], 50]);
                                }
                            }}
                        />
                    </div>
                    <div className={css.cardFlex}>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    percent[0] === 50 && percent[1] === 50,
                            })}
                            onClick={() => setPercent([50, 50])}
                        >
                            <p>50%</p>
                            <p className={css.yellowSubtext}>Save 2.0%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    percent[0] === 50 && percent[1] === 70,
                            })}
                            onClick={() => setPercent([50, 70])}
                        >
                            <p>70%</p>
                            <p className={css.yellowSubtext}>Save 1.0%</p>
                        </div>
                        <div
                            className={cn(css.card, css.cardSM, css.cardNo, {
                                [css.selectedCard]:
                                    percent[0] === 50 && percent[1] === 100,
                            })}
                            onClick={() => setPercent([50, 100])}
                        >
                            <p>100%</p>
                            <p className={css.greenSubtext}>Save 3.4%</p>
                        </div>
                    </div>
                </div>
                {/* <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{ padding: "12px" }}
                        fullWidth
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </div>
                <div className={css.botMobileContainer}>
                    <BotMobile />
                </div> */}
            </div>
        </>
    );
};

export default MonthlyPercentTabMobile;
