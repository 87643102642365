import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import css from "./ResourcesCarousel.module.scss";
import cn from "classnames";
import Icon from "../Icon/Icon";
import {
    chevronIcon,
    vectorIcon,
    plusIcon,
    arrowLeftWithBorder,
    arrowRightWithBorder,
    trashWithBorder,
    editWithBorder,
} from "../../css/icons";
import ModalBase from "../Modals/ModalBase";
import { Button } from "@mui/material";
import {
    Resource,
    setCurrentResources,
} from "../../features/Resources/resourceSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { v4 as uuidv4 } from "uuid";
import {
    setCurrentResourceIndex,
    setCurrentTab,
} from "../../features/Tabs/tabSlice";
import {
    calculateWeeks,
    calculateWeeksDifference,
    formatAmountDifferenceWithSign,
    getExperienceLabel,
} from "../../utils/helpers";
import SelectedRoleDesktop from "../SelectedRoleDesktop/SelectedRoleDesktop";
import SelectedResourceCard from "../SelectedResourceCard/SelectedResourceCard";
import AddResourceModal from "../AddResourceModal/AddResourceModal";

import PropertiesCarousel from "../PropertiesCarousel/PropertiesCarousel";

interface ResourcesCarouselProps {
    resources: Resource[];
}

type ResourceObject = {
    computedPrice?: number;
    count: number;
    price?: number;
    timeline?: {
        startWeek: Date;
        endWeek: Date;
    };
    weeklyWorkload?: number;
};

const ResourcesCarousel: React.FC<ResourcesCarouselProps> = ({ resources }) => {
    const [resourceList, setResourceList] = useState([...resources]);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAddResources, setOpenAddResources] = useState(false);
    const [count, setCount] = useState(0);
    const [openEditResources, setOpenEditResources] = useState(false);
    const [selectedResources, setSelectedResources] = useState<any>();
    const [openNewResources, setOpenNewResources] = useState(false);

    const toggleDrawer =
        (open: boolean) =>
        (
            event: React.KeyboardEvent | React.MouseEvent,
            reason?: "backdropClick" | "escapeKeyDown"
        ) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setOpenNewResources(open); // Directly set the state
        };

    const currentResourceIndex = useAppSelector(
        (state) => state.tabs.currentResourceIndex
    );

    const buget = useAppSelector((state) =>
        state.resources.budget?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
        })
    );

    const dispatch = useAppDispatch();

    const onDone = () => {
        const currentResource = resourceList[currentResourceIndex];

        const newResources = new Array(
            resourceList[currentResourceIndex]?.isUnique.length
        )
            .fill(null)
            .map(() => ({
                ...currentResource,
                count: 1,
                isUnique: [],
                id: uuidv4(),
            }));

        const newResourcesList = [
            ...newResources,
            {
                ...currentResource,
                count: currentResource.count - currentResource?.isUnique.length,
                isUnique: [],
            },
            ...resourceList.slice(0, currentResourceIndex),
            ...resourceList.slice(currentResourceIndex + 1),
        ].filter(({ count }) => count !== 0);

        setResourceList(newResourcesList);

        dispatch(setCurrentResources(newResourcesList));

        setOpen(false);
    };

    const setResourceDetails = () => {
        setOpen(true);
    };

    const calculateTotalCost = (resources: ResourceObject[]): string => {
        let totalCost = 0;
        const weeks = calculateWeeks();

        resources.forEach((resource) => {
            let cost = 0;

            if (resource.computedPrice) {
                cost = resource.computedPrice * resource.count;
            } else {
                const startWeek =
                    resource.timeline?.startWeek || weeks?.startWeek;
                const endWeek = resource.timeline?.endWeek || weeks?.endWeek;
                const weeklyWorkload = resource.weeklyWorkload || 0;
                const price = resource.price || 0;
                const count = resource.count;

                cost =
                    count *
                    price *
                    calculateWeeksDifference(startWeek, endWeek) *
                    weeklyWorkload *
                    8;
            }

            totalCost += cost;
        });

        return Number(totalCost).toLocaleString(undefined, {
            maximumFractionDigits: 0,
        });
    };

    const handleDelete = () => {
        const newResourceList = [...resources];
        newResourceList.splice(currentResourceIndex, 1);
        dispatch(setCurrentResources(newResourceList));
        dispatch(
            newResourceList.length !== 0
                ? currentResourceIndex !== 0
                    ? setCurrentResourceIndex(currentResourceIndex - 1)
                    : setCurrentResourceIndex(0)
                : setCurrentTab(3)
        );
        setOpenDelete(false);
    };

    const onDelete = () => {
        handleDelete();
    };

    const onClose = () => {
        setOpenDelete(false);
    };

    const onAdd = () => {
        const copyResourceList = [...resources];

        const newResourceList = copyResourceList.map((item, idx) => {
            if (idx === currentResourceIndex) {
                return { ...item, count: count };
            }
            return item;
        });
        dispatch(setCurrentResources(newResourceList));
        setOpenAddResources(false);
    };

    useEffect(() => {
        setResourceList([...resources]);
    }, [resources]);

    return (
        <div className={cn(css.wrapper, "resource-tab-container")}>
            <ModalBase
                title="Make Resources Unique"
                onClose={() => setOpen(false)}
                footer={
                    <Button color="primary" fullWidth onClick={onDone}>
                        Done
                    </Button>
                }
                open={open}
            >
                <p className={css.modalSubtext}>
                    If you want to give unique properties to specific resource,
                    make them unique
                </p>
                <div className={css.modalResources}>
                    {new Array(resourceList[currentResourceIndex]?.count)
                        .fill(null)
                        .map((res, index, arr) => {
                            const currentResource =
                                resourceList[currentResourceIndex];

                            return (
                                <div
                                    className={css.modalResource}
                                    key={
                                        currentResource?.title +
                                        currentResource?.count +
                                        index
                                    }
                                >
                                    <p>
                                        {
                                            resourceList[currentResourceIndex]
                                                ?.title
                                        }{" "}
                                        #{index + 1}
                                    </p>
                                    <Button
                                        className={cn(css.modalBtn, {
                                            [css.secondaryBtn]:
                                                currentResource?.count > 1 &&
                                                !currentResource?.isUnique.includes(
                                                    index
                                                ),
                                        })}
                                        color={
                                            currentResource?.count > 1
                                                ? currentResource?.isUnique.includes(
                                                      index
                                                  )
                                                    ? "primary"
                                                    : "secondary"
                                                : "primary"
                                        }
                                        onClick={() => {
                                            setResourceList(
                                                resourceList
                                                    .slice(
                                                        0,
                                                        currentResourceIndex
                                                    )
                                                    .concat(
                                                        [
                                                            {
                                                                ...currentResource,
                                                                isUnique:
                                                                    currentResource?.isUnique.includes(
                                                                        index
                                                                    )
                                                                        ? currentResource?.isUnique.filter(
                                                                              (
                                                                                  val
                                                                              ) =>
                                                                                  val !==
                                                                                  index
                                                                          )
                                                                        : currentResource?.isUnique.concat(
                                                                              index
                                                                          ),
                                                            },
                                                        ],
                                                        resourceList.slice(
                                                            currentResourceIndex +
                                                                1
                                                        )
                                                    )
                                            );
                                        }}
                                    >
                                        {currentResource?.count > 1
                                            ? currentResource?.isUnique.includes(
                                                  index
                                              )
                                                ? "Unique"
                                                : "Make Unique"
                                            : "Unique"}
                                    </Button>
                                </div>
                            );
                        })}
                </div>
            </ModalBase>
            <ModalBase
                title={`Add Another ${resources[currentResourceIndex]?.title}`}
                onClose={() => setOpenAddResources(false)}
                footer={
                    <div className={css.modalButtons}>
                        <Button
                            color="secondary"
                            fullWidth
                            onClick={() => {
                                setOpenAddResources(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button color="primary" fullWidth onClick={onAdd}>
                            Done
                        </Button>
                    </div>
                }
                open={openAddResources}
                hasCloseButton={false}
            >
                <p className={css.modalText}>
                    Please select the number of resources you need
                </p>
                <SelectedRoleDesktop
                    modal={true}
                    count={count}
                    onlyInput={true}
                    setCount={(num) => {
                        if (num === 0) {
                            setCount(1);
                        } else {
                            setCount(num);
                        }
                    }}
                />
            </ModalBase>
            <ModalBase
                title={``}
                onClose={() => setOpenEditResources(false)}
                footer={
                    <div className={css.modalButtons}>
                        <Button
                            color="secondary"
                            fullWidth
                            onClick={() => {
                                setOpenEditResources(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            fullWidth
                            onClick={() => {
                                const copyResourceList = [...resources];
                                copyResourceList[currentResourceIndex] =
                                    selectedResources;
                                dispatch(setCurrentResources(copyResourceList));
                                setOpenEditResources(false);
                            }}
                        >
                            Done
                        </Button>
                    </div>
                }
                open={openEditResources}
                hasCloseButton={false}
            >
                <div className={css.editModal}>
                    <p>Edit</p>
                    <span
                        className={css.delete}
                        onClick={() => {
                            setOpenEditResources(false);
                            setOpenDelete(true);
                        }}
                    >
                        {selectedResources?.count > 1 ? "Delete all" : "Delete"}
                    </span>
                </div>
                {new Array(selectedResources?.count)
                    .fill(null)
                    .map((res, index, arr) => {
                        const currentResource =
                            resourceList[currentResourceIndex];

                        return (
                            <SelectedResourceCard
                                key={index}
                                title={currentResource?.title}
                                cost={currentResource?.computedPrice}
                                selectModal={false}
                                handleButton={() => {
                                    if (selectedResources.count !== 1) {
                                        const newSelectedResources = {
                                            ...selectedResources,
                                            count: selectedResources.count - 1,
                                        };
                                        setSelectedResources(
                                            newSelectedResources
                                        );
                                    }
                                }}
                            />
                        );
                    })}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        className={css.addResource}
                        onClick={() => {
                            const newSelectedResources = {
                                ...selectedResources,
                                count: selectedResources.count + 1,
                            };
                            setSelectedResources(newSelectedResources);
                        }}
                    >
                        <Icon path={plusIcon} />
                        Add another one
                    </button>
                </div>
            </ModalBase>
            <ModalBase
                title="Delete Resource?"
                onClose={() => setOpenDelete(false)}
                footer={
                    <div className={css.modalButtons}>
                        <Button color="secondary" fullWidth onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            className={css.deleteButton}
                            fullWidth
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    </div>
                }
                open={openDelete}
                hasCloseButton={false}
            >
                <p className={css.customModalText}>
                    Are you sure you want to delete{" "}
                    <b>{resources[currentResourceIndex]?.title}</b> resource?
                    {resources[currentResourceIndex]?.count > 1 && (
                        <>
                            resource entirely that includes{" "}
                            {resources[currentResourceIndex]?.count} individual
                            resources?
                        </>
                    )}
                </p>
            </ModalBase>
            <AddResourceModal
                openNewResources={openNewResources}
                toggleDrawer={toggleDrawer}
            />
            <div className={css.resourceTabContainer}>
                <div className={css.budgetContainer}>
                    <p>
                        ${calculateTotalCost(resources)}
                        <span> / ${buget}</span>
                    </p>
                    <small>
                        {resources.reduce((acc, cur) => acc + cur.count, 0) ===
                        1 ? (
                            <>
                                {resources.reduce(
                                    (acc, cur) => acc + cur.count,
                                    0
                                )}{" "}
                                resource selected
                            </>
                        ) : (
                            <>
                                {resources.reduce(
                                    (acc, cur) => acc + cur.count,
                                    0
                                )}{" "}
                                resources selected
                            </>
                        )}
                    </small>
                </div>

                {/* <div className={css.singleResourceMobile}>
                    <div className={css.singleResourceHeader}>
                        <div className={css.singleResourceHeaderLeft}>
                            <Icon path={arrowLeftWithBorder} />
                        </div>
                        <div className={css.singleResourceHeaderTitle}>
                            <div>DevOps Engineer</div>
                            <small>1 resource</small>
                        </div>
                        <div className={css.singleResourceHeaderRight}>
                            <Icon path={arrowRightWithBorder} />
                        </div>
                    </div>
                    <div className={css.singleResourceSubheader}>
                        <div className={css.singleResourceAmount}>
                            $ 2,780 <span>/month</span>
                        </div>
                        <div className={css.singleResourceMiscButtons}>
                            <Icon path={trashWithBorder} />
                            <Icon path={editWithBorder} />
                        </div>
                    </div>
                    <PropertiesCarousel resources={resources} />
                </div> */}

                {resources.map((resource, index) => {
                    return (
                        <Accordion
                            key={`resource-tab-${resource?.title}-${index}`}
                            className={cn(css.accordion, {
                                [css.selectedAccordion]:
                                    currentResourceIndex === index,
                            })}
                            sx={{
                                "&.Mui-expanded": {
                                    m: "0 !important", // Remove margin-top when other Accordions are expanded
                                },
                            }}
                            onClick={() => {
                                dispatch(setCurrentResourceIndex(index));
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<Icon path={chevronIcon} />}
                                aria-controls="panel1a-content"
                                id={`resource-tab-${resource?.title}-${index}`}
                                sx={{
                                    margin: "0 !important", // Remove margin
                                    "& .Mui-expanded": {
                                        margin: "0 !important", // Remove margin when expanded
                                    },
                                }}
                            >
                                <div className={css.resourceTabWrapper}>
                                    <div className={css.resourceTabLeft}>
                                        <div className={css.imageContainer}>
                                            <span
                                                className={cn(
                                                    css.circle,
                                                    {
                                                        [css.done]:
                                                            resource?.costDetails,
                                                    },
                                                    {
                                                        [css.selected]:
                                                            !resource?.costDetails &&
                                                            currentResourceIndex ===
                                                                index,
                                                    }
                                                )}
                                            >
                                                <div className={css.checkmark}>
                                                    ✔
                                                </div>
                                            </span>
                                            <Icon path={vectorIcon} />
                                        </div>
                                        <div>
                                            <p className={css.resourceTitle}>
                                                {resource?.title}
                                            </p>
                                            <small
                                                className={css.resourceSubtitle}
                                            >
                                                {resource?.count}{" "}
                                                {resource?.count > 1
                                                    ? "resources"
                                                    : "resource"}
                                            </small>
                                        </div>
                                    </div>
                                    <div className={css.resourceTabRight}>
                                        {resource?.costDetails && (
                                            <span
                                                className={
                                                    css.resourceExperience
                                                }
                                            >
                                                {getExperienceLabel(
                                                    resource?.experience
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={css.details}>
                                <div
                                    className={css.links}
                                    style={{ justifyContent: "flex-end" }}
                                >
                                    <span
                                        onClick={() => {
                                            const selected =
                                                resourceList[index];
                                            setSelectedResources(selected);
                                            setOpenEditResources(true);
                                        }}
                                    >
                                        Edit
                                    </span>
                                </div>
                                {/* {resource?.count === 1 ? (
                                    <div className={css.links}>
                                        <span
                                            onClick={() => {
                                                setOpenAddResources(true);
                                                setCount(resource?.count);
                                            }}
                                        >
                                            + Add Another
                                        </span>
                                        <span
                                            className={css.delete}
                                            onClick={() => setOpenDelete(true)}
                                        >
                                            Delete
                                        </span>
                                    </div>
                                ) : (
                                    
                                )} */}
                                {resource?.costDetails && (
                                    <div className={css.infoContainer}>
                                        <div className={css.info}>
                                            <p>
                                                {getExperienceLabel(
                                                    resource?.experience
                                                )}
                                            </p>
                                            <small
                                                className={cn(css.money, {
                                                    [css.increase]:
                                                        Math.abs(
                                                            resources[
                                                                currentResourceIndex
                                                            ]?.costDetails
                                                                ?.experience_cost
                                                        ) > 0,
                                                })}
                                            >
                                                {formatAmountDifferenceWithSign(
                                                    resources[
                                                        currentResourceIndex
                                                    ]?.costDetails
                                                        ?.experience_cost
                                                )}{" "}
                                                <span>/month</span>
                                            </small>
                                        </div>
                                        {resource?.workFormat && (
                                            <div className={css.info}>
                                                <p>{resource?.workFormat}</p>
                                                <small
                                                    className={cn(css.money, {
                                                        [css.increase]:
                                                            Math.abs(
                                                                resources[
                                                                    currentResourceIndex
                                                                ]?.costDetails
                                                                    ?.work_format_cost
                                                            ) > 0,
                                                    })}
                                                >
                                                    {formatAmountDifferenceWithSign(
                                                        resources[
                                                            currentResourceIndex
                                                        ]?.costDetails
                                                            ?.work_format_cost
                                                    )}{" "}
                                                    <span>/month</span>
                                                </small>
                                            </div>
                                        )}
                                        <div className={css.info}>
                                            <p>
                                                {resource?.weeklyWorkload * 8}
                                                hr/week
                                            </p>
                                            <small
                                                className={cn(css.money, {
                                                    [css.increase]:
                                                        Math.abs(
                                                            resources[
                                                                currentResourceIndex
                                                            ]?.costDetails
                                                                ?.days_per_week_cost
                                                        ) > 0,
                                                })}
                                            >
                                                {formatAmountDifferenceWithSign(
                                                    Math.abs(
                                                        resources[
                                                            currentResourceIndex
                                                        ]?.costDetails
                                                            ?.days_per_week_cost
                                                    )
                                                )}{" "}
                                                <span>/month</span>
                                            </small>
                                        </div>
                                        {/* <div className={css.info}>
                                        <p>
                                            {resource?.timeline.endWeek -
                                                resource?.timeline.startWeek ===
                                            1
                                                ? "1 week"
                                                : `${
                                                      resource?.timeline
                                                          .endWeek -
                                                      resource?.timeline
                                                          .startWeek
                                                  } weeks`}
                                        </p>
                                        <small
                                            className={cn(css.money, {
                                                [css.increase]:
                                                    Math.abs(
                                                        resources[
                                                            currentResourceIndex
                                                        ]?.costDetails
                                                            ?.days_per_week_cost
                                                    ) > 0,
                                            })}
                                        >
                                            {formatAmountDifferenceWithSign(
                                                    Math.abs(
                                                        (resources[
                                                        currentResourceIndex
                                                    ]?.costDetails
                                                        ?.days_per_week_cost) *
                                                        resources[
                                                            currentResourceIndex
                                                        ]?.costDetails
                                                            ?.days_per_week_cost
                                                    )
                                            )}{" "}
                                            <span>/month</span>
                                        </small>
                                    </div> */}
                                        {resource?.region && (
                                            <div className={css.info}>
                                                <p>{resource?.region}</p>
                                                <small
                                                    className={cn(css.money, {
                                                        [css.increase]:
                                                            Math.abs(
                                                                resources[
                                                                    currentResourceIndex
                                                                ]?.costDetails
                                                                    ?.resource_region_cost
                                                            ) > 0,
                                                    })}
                                                >
                                                    {formatAmountDifferenceWithSign(
                                                        resources[
                                                            currentResourceIndex
                                                        ]?.costDetails
                                                            ?.resource_region_cost
                                                    )}{" "}
                                                    <span>/month</span>
                                                </small>
                                            </div>
                                        )}
                                        {/* {currentResourceIndex === index &&
                                        resource?.count > 1 && (
                                            <button
                                                className={css.setDetailsBtn}
                                                onClick={setResourceDetails}
                                            >
                                                Make Resources Unique
                                            </button>
                                        )} */}
                                    </div>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
                <button
                    className={css.addResource}
                    onClick={() => setOpenNewResources(true)}
                >
                    <Icon path={plusIcon} />
                    Add another resource
                </button>
            </div>
        </div>
    );
};

export default ResourcesCarousel;
