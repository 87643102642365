import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    copyIcon,
    projectIcon,
    resourceIcon,
    youpalLogo,
} from "../../../../../css/icons";
import {
    setIsSaveModalOpen,
    setProjectType,
} from "../../../../../features/Resources/resourceSlice";
import { setCurrentTab } from "../../../../../features/Tabs/tabSlice";
import { Grey } from "../../../../../mui/colors";
import ClickableCard from "../../../../ClickableCard/ClickableCard";
import Icon from "../../../../Icon/Icon";
import Input from "../../../../Input/Input";
import ModalBase from "../../../../Modals/ModalBase";
import TabPanel from "../../../TabPanel";
import css from "./HomeTabDesktop.module.scss";
import ResourceService from "../../../../../services/resource.service";
import { ENVIROMENT } from "../../../../../constants";

interface TabProps {
    index: number;
}

const resourceService = new ResourceService();

const HomeTabDesktop: React.FC<TabProps> = ({ index }) => {
    const dispatch = useAppDispatch();
    const { isSaveModalOpen, name } = useAppSelector(
        (state) => state.resources
    );
    const [quotaURL, setQuotaURL] = useState("");
    const [emailVal, setEmailVal] = useState("");

    const onSaveAsQuota = async () => {
        if (name && emailVal.length > 0) {
            await resourceService.sendUrlToEmail(name, emailVal, quotaURL);
        }
        dispatch(setIsSaveModalOpen(false));
        setEmailVal("");
        dispatch(setCurrentTab(17));
    };

    useEffect(() => {
        if (name && isSaveModalOpen) {
            resourceService.saveAsQuota(name).then((res) => {
                setQuotaURL(
                    res.data.message[
                        ENVIROMENT === "STAGE"
                            ? "staging_url"
                            : "production_url"
                    ]
                );
            });
        }
    }, [isSaveModalOpen, name]);

    return (
        <TabPanel index={0} value={index}>
            <ModalBase
                open={isSaveModalOpen}
                onClose={() => dispatch(setIsSaveModalOpen(false))}
                title="Save as Quota"
            >
                <div className={css.modalContent}>
                    <Input
                        fieldName="email"
                        placeholder="Enter your email"
                        label="Send it to your email"
                        type="text"
                        value={emailVal}
                        onChange={(e: any) => {
                            setEmailVal(e.target.value);
                        }}
                    />
                    <p className={css.modalOr}>OR</p>
                    <Input
                        fieldName="link"
                        placeholder=""
                        label="Save as Link"
                        type="text"
                        value={quotaURL}
                        disabled
                        postfix={
                            <button
                                type="button"
                                className={css.unstyledBtn}
                                onClick={() => {
                                    navigator.clipboard.writeText(quotaURL);
                                }}
                            >
                                <Icon path={copyIcon} />
                            </button>
                        }
                    />
                    <Button
                        color="primary"
                        fullWidth
                        sx={{
                            margin: "16px 0",
                        }}
                        onClick={onSaveAsQuota}
                    >
                        Done
                    </Button>
                </div>
            </ModalBase>
            <div className={css.wrapper}>
                <div className={css.logoContainer}>
                    <Icon path={youpalLogo} className={css.logo} />
                </div>
                <div className={css.cardContainer}>
                    <ClickableCard
                        colors={{
                            background: Grey[50],
                            border: Grey[200],
                            text: Grey[700],
                        }}
                        title="I want to plan a project"
                        subtitle="Setup budget, timeline and resources"
                        icon={<Icon path={projectIcon} />}
                        onClick={() => {
                            dispatch(setProjectType("project"));
                            dispatch(setCurrentTab(1));
                        }}
                    />
                    <ClickableCard
                        colors={{
                            background: Grey[50],
                            border: Grey[200],
                            text: Grey[700],
                        }}
                        title="I need specific resources"
                        subtitle="Jump right into the resources"
                        icon={<Icon path={resourceIcon} />}
                        onClick={() => {
                            dispatch(setProjectType("resources"));
                            dispatch(setCurrentTab(3));
                        }}
                    />
                </div>
                {/* <div className={css.blankCard}>
                    Find the best fit for your project/company!
                </div> */}
            </div>
        </TabPanel>
    );
};

export default HomeTabDesktop;
