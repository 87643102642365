import { useEffect, useState, useCallback } from "react";
import css from "./ExperienceTab.module.scss";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import TabPanel from "../../TabPanel";
import Icon from "../../../Icon/Icon";
import {
    youpalLogo,
    chevronRightIcon,
    chevronLeftIcon,
} from "../../../../css/icons";
import { Button, Slider } from "@mui/material";
import { Grey } from "../../../../mui/colors";
import ResourcesCarousel from "../../../ResourcesCarousel/ResourcesCarousel";
import CloseButton from "../../../CloseButton/CloseButton";
import {
    computeProjectTotals,
    setCurrentResources,
    setExperience,
    setResourceTabIndex,
} from "../../../../features/Resources/resourceSlice";
import TopNavigationBar from "../../../TopNavigationBar/TopNavigationBar";
import PropertiesCarousel from "../../../PropertiesCarousel/PropertiesCarousel";
import ModalBase from "../../../Modals/ModalBase";
import { colorSet } from "../../../../utils/helpers";
import { debounce } from "lodash";

interface TabProps {
    index: number;
}

const ExperienceTab: React.FC<TabProps> = ({ index }) => {
    const [open, setOpen] = useState(false);
    const [experienceSliderValue, setExperienceSliderValue] = useState(1);
    const { currentResources } = useAppSelector((state) => state.resources);
    const { currentResourceIndex, currentTab } = useAppSelector(
        (state) => state.tabs
    );

    const [activeButton, setActiveButton] = useState(1);

    const dispatch = useAppDispatch();

    const onNext = () => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: 5,
            })
        );
        dispatch(setCurrentTab(5));
        // dispatch(
        //     setExperience({
        //         index: currentResourceIndex,
        //         experience: experienceSliderValue,
        //     })
        // );
    };

    const onDone = () => {
        const isAllResourcesDone = currentResources.every(
            (res) => res?.costDetails
        );

        if (isAllResourcesDone) {
            addColor();
            // dispatch(completeResources());
            dispatch(computeProjectTotals());
        } else {
            setOpen(true);
        }
    };

    const addColor = () => {
        for (let resource of currentResources) {
            document.dispatchEvent(
                new CustomEvent("update_price_details", {
                    detail: {
                        resource: {
                            ...resource,
                            timeline: {
                                startWeek: resource.timeline.startWeek,
                                endWeek: resource.timeline.endWeek,
                            },
                        },
                    },
                })
            );
        }
        setTimeout(() => {
            dispatch(setCurrentTab(8));
        }, 1000);
        dispatch(
            setCurrentResources([
                ...currentResources.map((role, index) => {
                    return {
                        ...role,
                        color: colorSet[index % colorSet.length],
                    };
                }),
            ])
        );
    };

    const debounceUpdate = useCallback(
        debounce((val) => {
            setExperienceSliderValue(val);
            if (val >= 1 && val < 3) {
                setActiveButton(1);
            } else if (val >= 3 && val < 5) {
                setActiveButton(2);
            } else if (val >= 5 && val < 7) {
                setActiveButton(3);
            } else {
                setActiveButton(4);
            }
        }, 50),
        []
    );

    const handleSliderChange = (e: any) => {
        const val = e.target.value;
        debounceUpdate(+val);
    };

    useEffect(() => {
        if (currentResources && currentResourceIndex >= 0) {
            dispatch(
                setExperience({
                    index: currentResourceIndex,
                    experience: experienceSliderValue,
                })
            );
        }

        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        experience: experienceSliderValue,
                    },
                },
            })
        );
        document.dispatchEvent(
            new CustomEvent("update_price_details", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        experience: experienceSliderValue,
                    },
                },
            })
        );
    }, [experienceSliderValue]);

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        experience: experienceSliderValue,
                    },
                },
            })
        );
        if (currentResources && currentResourceIndex >= 0) {
            dispatch(
                setExperience({
                    index: currentResourceIndex,
                    experience: experienceSliderValue,
                })
            );
        }
    }, []);

    useEffect(() => {
        if (currentResources.length > 0 && currentResourceIndex >= 0) {
            const experience =
                currentResources[currentResourceIndex]?.experience;
            setExperienceSliderValue(experience);
            if (experience >= 1 && experience < 3) {
                setActiveButton(1);
            } else if (experience >= 3 && experience < 5) {
                setActiveButton(2);
            } else if (experience >= 5 && experience < 7) {
                setActiveButton(3);
            } else {
                setActiveButton(4);
            }
        }
    }, [currentResourceIndex, currentTab]);

    return (
        <TabPanel index={4} value={index}>
            <ModalBase
                onClose={() => setOpen(false)}
                open={open}
                title="Are you sure you want to proceed?"
            >
                <p>You haven't added details for other talents.ss</p>
                <div className={cn(css.flexSB, css.modalBtns)}>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => setOpen(false)}
                    >
                        Add Details
                    </Button>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => {
                            addColor();
                            setOpen(false);
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </ModalBase>
            <TopNavigationBar title="Configuring Resources" />
            <div className={css.wrapper}>
                <div className={css.resourceCarouselWrapper}>
                    <ResourcesCarousel resources={currentResources} />
                </div>
                <div className={css.mainConatiner}>
                    <PropertiesCarousel resources={currentResources} />
                    <div className={css.innerConatiner}>
                        <div className={css.leftButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronLeftIcon} />
                            </button>
                        </div>
                        <div>
                            <h1 className={css.title}>
                                How much experience should they have?
                            </h1>
                            <div className={css.experiencePriceBlock}>
                                <p className={css.title}>
                                    ${" "}
                                    {Number(
                                        currentResources[currentResourceIndex]
                                            ?.computedPrice
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                    <span className={css.subtext}>/ month</span>
                                </p>
                                <p className={css.experienceRow}>
                                    {experienceSliderValue === 0 ? (
                                        "No experience"
                                    ) : (
                                        <>
                                            <span className={css.orange}>
                                                {experienceSliderValue <= 7
                                                    ? experienceSliderValue
                                                    : "7+"}{" "}
                                                {experienceSliderValue > 1
                                                    ? "years"
                                                    : "year"}
                                            </span>{" "}
                                            of experience
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className={css.sliderContainer}>
                                <span
                                    className={cn(
                                        css.sliderLimit,
                                        css.sliderLimitLeft
                                    )}
                                >
                                    0
                                </span>
                                <Slider
                                    sx={{
                                        display: "block",
                                        margin: "0 auto",
                                    }}
                                    min={1}
                                    max={8}
                                    value={experienceSliderValue}
                                    onChange={handleSliderChange}
                                />
                                <span
                                    className={cn(
                                        css.sliderLimit,
                                        css.sliderLimitRight
                                    )}
                                >
                                    7+
                                </span>
                            </div>
                            <div className={css.optionContainer}>
                                <div
                                    className={cn(css.option, {
                                        [css.activeOption]: activeButton === 1,
                                    })}
                                    onClick={() => {
                                        setActiveButton(1);
                                        setExperienceSliderValue(2);
                                    }}
                                >
                                    Junior
                                </div>
                                <div
                                    className={cn(css.option, {
                                        [css.activeOption]: activeButton === 2,
                                    })}
                                    onClick={() => {
                                        setActiveButton(2);
                                        setExperienceSliderValue(4);
                                    }}
                                >
                                    Intermediate
                                </div>
                                <div
                                    className={cn(css.option, {
                                        [css.activeOption]: activeButton === 3,
                                    })}
                                    onClick={() => {
                                        setActiveButton(3);
                                        setExperienceSliderValue(6);
                                    }}
                                >
                                    Senior
                                </div>
                                <div
                                    className={cn(css.option, {
                                        [css.activeOption]: activeButton === 4,
                                    })}
                                    onClick={() => {
                                        setActiveButton(4);
                                        setExperienceSliderValue(8);
                                    }}
                                >
                                    Expert
                                </div>
                            </div>
                        </div>
                        <div className={css.rightButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronRightIcon} />
                            </button>
                        </div>
                    </div>
                    <div className={css.doneButton}>
                        <Button
                            color="primary"
                            onClick={onDone}
                            sx={{
                                padding: "12px 24px",
                            }}
                        >
                            Finish Resource Configuration
                        </Button>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default ExperienceTab;
