import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReactElement } from "react";
import { calculateWeeksDifference } from "../../utils/helpers";

interface InitialStateType {
    type?: "project" | "resources";
    budget?: number;
    duration?: number;
    currency?: string;
    currentResources: Resource[];
    resources: Resource[];
    name?: string;
    totals: {
        projectCost: number;
        monthlyCost: number;
        totalHours: number;
    };
    isSaveModalOpen: boolean;
}

export interface Resource {
    id: string;
    title: string;
    role: string;
    count: number;
    experience: number;
    price: number;
    computedPrice: number;
    computedTotalPrice: number;
    workFormat: "remote" | "on-site";
    location: string;
    weeklyWorkload: number;
    region: string;
    icon: ReactElement;
    color: string;
    isUnique: number[];
    tabIndex: number;
    timeline: {
        startWeek: Date;
        endWeek: Date;
    };
    costDetails: {
        resource_per_month: number;
        hours_per_week: number;
        experience_cost: number;
        work_format_cost: number;
        resource_region_cost: number;
        days_per_week_cost: number;
        total_project_hours: number;
        total_project_cost_basic: number;
        talent_monthly_cost: number;
        monthly_accomodation_cost: number;
        project_accomodation_cost: number;
        total_monthly_cost: number;
        total_project_cost: number;
    };
    discountOptions: {
        total_amount: number;
        credit_card: "Yes" | "No";
        credit_card_duration: "Weekly" | "Bi-monthly" | "Monthly";
        credit_card_payment_percent_per_month: string;
        upfront_payment: string;
        frame_agreement: "Yes" | "No";
        invoice_payment_terms: string;
    };
}

const initialState: InitialStateType = {
    currentResources: [],
    resources: [],
    duration: 8,
    currency: "USD",
    totals: {
        projectCost: 0,
        monthlyCost: 0,
        totalHours: 0,
    },
    isSaveModalOpen: false,
};

const resourceSlice = createSlice({
    name: "resourceSlice",
    initialState,
    reducers: {
        setName(state, action) {
            console.log("action.payload ", action.payload);
            state.name = action.payload;
        },
        setResourceCostDetails(
            state,
            action: PayloadAction<{
                cost_details: {
                    resource_per_month: number;
                    hours_per_week: number;
                    experience_cost: number;
                    work_format_cost: number;
                    resource_region_cost: number;
                    days_per_week_cost: number;
                    total_project_hours: number;
                    total_project_cost_basic: number;
                    talent_monthly_cost: number;
                    monthly_accomodation_cost: number;
                    project_accomodation_cost: number;
                    total_monthly_cost: number;
                    total_project_cost: number;
                };
                id: string;
            }>
        ) {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    costDetails: action.payload.cost_details,
                };
            });
        },
        setResourceCostDetailsSaved(
            state,
            action: PayloadAction<{
                cost_details: {
                    resource_per_month: number;
                    hours_per_week: number;
                    experience_cost: number;
                    work_format_cost: number;
                    resource_region_cost: number;
                    days_per_week_cost: number;
                    total_project_hours: number;
                    total_project_cost_basic: number;
                    talent_monthly_cost: number;
                    monthly_accomodation_cost: number;
                    project_accomodation_cost: number;
                    total_monthly_cost: number;
                    total_project_cost: number;
                };
                id: string;
            }>
        ) {
            state.resources = state.resources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    costDetails: action.payload.cost_details,
                };
            });
        },
        computePrice(
            state,
            action: PayloadAction<{
                id: string;
                price: number;
            }>
        ) {
            const { price, id } = action.payload;

            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== id) return res;
                const computedPrice = 4 * res.weeklyWorkload * 8 * price;

                const computedTotalPrice =
                    calculateWeeksDifference(
                        res.timeline.startWeek,
                        res.timeline.endWeek
                    ) *
                    res.weeklyWorkload *
                    8 *
                    price;

                return {
                    ...res,
                    price,
                    computedPrice,
                    computedTotalPrice,
                };
            });
        },
        deleteAllResources: (state) => {
            state.currentResources = [];
        },
        setCurrency(state, action) {
            state.currency = action.payload;
        },
        setTabIndexById: (
            state,
            action: PayloadAction<{
                id: string;
                tabIndex: number;
            }>
        ) => {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    tabIndex: action.payload.tabIndex,
                };
            });
        },
        setProjectType: (
            state,
            action: PayloadAction<"project" | "resources">
        ) => {
            state.type = action.payload;
        },
        setProjectBudget: (state, action) => {
            state.budget = action.payload;
        },
        setProjectDuration: (state, action) => {
            state.duration = action.payload;
        },
        setProjectResources: (state, action) => {
            state.resources = action.payload;
        },
        setCurrentResources: (state, action) => {
            state.currentResources = action.payload;
        },
        setResourceTabIndex: (
            state,
            action: PayloadAction<{
                index: number;
                tabIndex: number;
            }>
        ) => {
            if (state.currentResources[action.payload.index]) {
                state.currentResources[action.payload.index].tabIndex =
                    action.payload.tabIndex;
            }
        },
        setExperience: (
            state,
            action: PayloadAction<{
                index: number;
                experience: number;
            }>
        ) => {
            if (state.currentResources[action.payload.index]) {
                state.currentResources[action.payload.index].experience =
                    action.payload.experience;
            }
        },
        setRegion: (
            state,
            action: PayloadAction<{
                index: number;
                region: string;
            }>
        ) => {
            if (state.currentResources[action.payload.index]) {
                state.currentResources[action.payload.index].region =
                    action.payload.region;
            }
        },
        setRegionById: (
            state,
            action: PayloadAction<{
                id: string;
                region: string;
            }>
        ) => {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    region: action.payload.region,
                };
            });
        },
        setExperienceById: (
            state,
            action: PayloadAction<{
                id: string;
                experience: number;
            }>
        ) => {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    experience: action.payload.experience,
                };
            });
        },
        setWorkFormat: (
            state,
            action: PayloadAction<{
                index: number;
                workFormat: "remote" | "on-site";
            }>
        ) => {
            if (state.currentResources[action.payload.index]) {
                state.currentResources[action.payload.index].workFormat =
                    action.payload.workFormat;
            }
        },
        setWorkFormatById: (
            state,
            action: PayloadAction<{
                id: string;
                workFormat: "remote" | "on-site";
            }>
        ) => {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    workFormat: action.payload.workFormat,
                };
            });
        },
        setWeeklyWorkload: (
            state,
            action: PayloadAction<{
                index: number;
                weeklyWorkload: number;
            }>
        ) => {
            if (state.currentResources[action.payload.index]) {
                state.currentResources[action.payload.index].weeklyWorkload =
                    action.payload.weeklyWorkload;
            }
        },
        setWeeklyWorkloadById: (
            state,
            action: PayloadAction<{
                id: string;
                weeklyWorkload: number;
            }>
        ) => {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    weeklyWorkload: action.payload.weeklyWorkload,
                };
            });
        },
        setLocation: (
            state,
            action: PayloadAction<{
                index: number;
                location: string;
            }>
        ) => {
            if (state.currentResources[action.payload.index]) {
                state.currentResources[action.payload.index].location =
                    action.payload.location;
            }
        },
        setLocationById: (
            state,
            action: PayloadAction<{
                id: string;
                location: string;
            }>
        ) => {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    location: action.payload.location,
                };
            });
        },
        setTimeline: (
            state,
            action: PayloadAction<{
                index: number;
                timeline: {
                    startWeek: Date;
                    endWeek: Date;
                };
            }>
        ) => {
            if (state.currentResources[action.payload.index]) {
                state.currentResources[action.payload.index].timeline =
                    action.payload.timeline;
            }
        },
        setTimelineById: (
            state,
            action: PayloadAction<{
                id: string;
                timeline: {
                    startWeek: Date;
                    endWeek: Date;
                };
            }>
        ) => {
            state.currentResources = state.currentResources.map((res) => {
                if (res.id !== action.payload.id) return res;
                return {
                    ...res,
                    timeline: action.payload.timeline,
                };
            });
        },
        completeResources: (state) => {
            state.resources = [...state.currentResources];
            // state.currentResources = [];
        },
        setCount: (
            state,
            action: PayloadAction<{
                id: string;
                value: number;
            }>
        ) => {
            state.resources = state.resources
                .map((res) => {
                    if (res.id !== action.payload.id) return res;
                    return {
                        ...res,
                        count: action.payload.value,
                    };
                })
                .filter((res) => res.count !== 0);
        },
        computeProjectTotals(state) {
            if (state.resources) {
                state.totals = state.resources.reduce(
                    (acc, item) => ({
                        projectCost:
                            acc.projectCost +
                            item.costDetails.total_project_cost,
                        monthlyCost:
                            acc.monthlyCost +
                            item.costDetails.total_monthly_cost,
                        totalHours:
                            acc.totalHours +
                            item.costDetails.total_project_hours,
                    }),
                    {
                        projectCost: 0,
                        monthlyCost: 0,
                        totalHours: 0,
                    }
                );
            }
        },
        reset(state) {
            state = initialState;
        },
        setIsSaveModalOpen(state, action) {
            state.isSaveModalOpen = action.payload;
        },
    },
});

export const {
    setName,
    setProjectType,
    setProjectBudget,
    setProjectDuration,
    setProjectResources,
    setCurrentResources,
    setResourceTabIndex,
    deleteAllResources,
    setExperience,
    setWorkFormat,
    setWeeklyWorkload,
    setLocation,
    setTimeline,
    completeResources,
    setExperienceById,
    setWorkFormatById,
    setTabIndexById,
    setLocationById,
    setWeeklyWorkloadById,
    setTimelineById,
    setCount,
    setRegion,
    setRegionById,
    setCurrency,
    computePrice,
    setResourceCostDetails,
    setResourceCostDetailsSaved,
    computeProjectTotals,
    reset,
    setIsSaveModalOpen,
} = resourceSlice.actions;

export default resourceSlice;
