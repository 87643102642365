import { Dispatch, SetStateAction } from "react";
import css from "./ContextMenu.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setCurrentResources } from "../../../features/Resources/resourceSlice";

interface Props {
    id: string;
    setOpen: Dispatch<SetStateAction<string>>;
}

const ContextMenu: React.FC<Props> = ({ id, setOpen }) => {
    const { currentResources } = useAppSelector((state) => state.resources);

    const currentResourceIndex = currentResources.findIndex(
        (resource) => resource.id === id
    );

    const dispatch = useAppDispatch();

    const onDuplicate = () => {
        dispatch(
            setCurrentResources([
                ...currentResources.slice(0, currentResourceIndex + 1),
                { ...currentResources[currentResourceIndex], id: uuidv4() },
                ...currentResources.slice(currentResourceIndex + 1),
            ])
        );

        setOpen("");
    };

    const onDelete = () => {
        dispatch(
            setCurrentResources([
                ...currentResources.slice(0, currentResourceIndex),
                ...currentResources.slice(currentResourceIndex + 1),
            ])
        );

        setOpen("");
    };

    return (
        <div className={css.topup}>
            <div className={css.topup_item} onClick={onDuplicate}>
                Duplicate
            </div>
            <div className={css.topup_item} onClick={onDelete}>
                Delete
            </div>
        </div>
    );
};

export default ContextMenu;
