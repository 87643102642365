import cn from "classnames";
import css from "./SelectedResourceCard.module.scss";
import Icon from "../Icon/Icon";
import { vectorIcon, trashIcon } from "../../css/icons";

interface SelectedResourceCard {
    title: string;
    cost: number;
    selectModal: boolean;
    selected?: boolean;
    handleButton?: () => void;
}

const SelectedResourceCard: React.FC<SelectedResourceCard> = ({
    title,
    cost,
    selectModal,
    selected,
    handleButton,
}) => {
    return (
        <div className={css.selectedRole}>
            <div className={css.selectedRoleLeft}>
                <Icon path={vectorIcon} />
                <div className={css.selectedRoleInfo}>
                    <p>{title}</p>
                    <small>
                        ${cost} <span>/month</span>
                    </small>
                </div>
            </div>
            <div className={css.selectedRoleControls}>
                {selectModal ? (
                    <button
                        className={cn(css.selectBtn, {
                            [css["selected"]]: selected,
                        })}
                        onClick={handleButton}
                    >
                        Select
                    </button>
                ) : (
                    <button className={css.trsahBtn} onClick={handleButton}>
                        <Icon path={trashIcon} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default SelectedResourceCard;
