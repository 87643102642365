import { useState, useEffect } from "react";
import TabPanel from "../../TabPanel";
import css from "./DiscountTabsMobileNew.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import BotMobile from "../../../BotMobile/BotMobile";
import {
    setCreditCard,
    setCreditCardDuration,
} from "../../../../features/Discounts/discountSlice";

import DiscountsMobileCommon from "../../../DiscountsMobileCommon/DiscountsMobileCommon";
import { Button } from "@mui/material";

import AUseCreditCardPage from "./Pages/AUseCreditCardPage/AUseCreditCardPage";
import BMonthlyPercentTabMobile from "./Pages/BMonthlyPercentTabMobile/MonthlyPercentTabMobile";
import CInvoiceTermsTabMobile from "./Pages/CInvoiceTermsTabMobile/InvoiceTermsTabMobile";
import DFirstTenPercentTabMobile from "./Pages/DFirstTenPercentTabMobile/FirstTenPercentTabMobile";
import EFrameAgreementTabMobile from "./Pages/EFrameAgreementTabMobile/FrameAgreementTabMobile";
import GVolumeResourcesTabMobile from "./Pages/GVolumeResourcesTabMobile/VolumeResourcesTabMobile";

import ResourceService from "../../../../services/resource.service";

import { setName } from "../../../../features/Resources/resourceSlice";
import {
    calculateWeeksDifference,
    formatDate,
    getExperienceLabel,
} from "../../../../utils/helpers";
interface TabProps {
    index: number;
}

const resourceService = new ResourceService();

const DiscountTabsMobileNew: React.FC<TabProps> = ({ index }) => {
    const {
        discounts: { credit_card_percentage_can_avail },
        total_discount,
        discountPayload,
    } = useAppSelector((state) => state.discounts);
    const {
        resources,
        name,
        budget,
        currency,
        duration,
        totals: { projectCost },
    } = useAppSelector((state) => state.resources);
    const dispatch = useAppDispatch();
    const [yes, setYes] = useState(false);
    const [period, setPeriod] = useState<"Weekly" | "Bi-weekly" | "Monthly">(
        "Bi-weekly"
    );
    const onGoBack = () => {
        dispatch(setCurrentTab(8));
    };

    const [path, setPath] = useState("");
    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setPath(currentPath);
    }, []);

    const onNext = () => {
        dispatch(setCurrentTab(17));
        const resource_id =
            name && name.length > 0
                ? name
                : path && path.length > 0
                ? path
                : "";

        console.log("resource_id", resource_id);
        resourceService
            .addResource(
                resource_id,
                currency || "USD",
                budget || 10000,
                duration || 8,
                17,
                resources.map((res) => ({
                    role: res.role,
                    number: res.count,
                    experience: getExperienceLabel(res.experience),
                    duration: calculateWeeksDifference(
                        res.timeline?.startWeek,
                        res.timeline?.endWeek
                    ),
                    workload: res.weeklyWorkload,
                    start_week: 0,
                    end_week: 4,

                    title: res.title,
                    work_format: res.workFormat,
                    work_location: res.location,
                    resource_location: res.region,
                    start_week_date: formatDate(res.timeline?.startWeek),
                    end_week_date: formatDate(res.timeline?.endWeek),
                })),
                discountPayload.credit_card,
                discountPayload.credit_card_duration,
                discountPayload.frame_agreement,
                discountPayload.upfront_payment,
                discountPayload.credit_card_payment_percent_per_month
            )
            .then((res) => {
                dispatch(setName(res.data.message.name));
            });
    };

    const getMaxDiscount = () => {
        setYes(true);
        setPeriod("Weekly");
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_discount", {
                detail: {
                    total_amount: projectCost,
                    discount: {
                        ...discountPayload,
                        credit_card: yes ? "Yes" : "No",
                        credit_card_duration: period,
                    },
                },
            })
        );
        dispatch(setCreditCard(yes ? "Yes" : "No"));
        dispatch(setCreditCardDuration(period));
    }, [yes, period]);

    return (
        <TabPanel index={11} value={index}>
            <div className={css.wrapper}>
                <DiscountsMobileCommon
                    current={projectCost}
                    total={budget}
                    onCancel={() => {}}
                    onGoBack={onGoBack}
                    saving={
                        (credit_card_percentage_can_avail * projectCost) / 100
                    }
                    onGetDiscount={getMaxDiscount}
                />
                <AUseCreditCardPage index={11} />
                <BMonthlyPercentTabMobile index={12} />
                <CInvoiceTermsTabMobile index={13} />
                <DFirstTenPercentTabMobile index={14} />
                <EFrameAgreementTabMobile index={15} />
                <GVolumeResourcesTabMobile index={16} />

                <div className={css.btnContainer}>
                    <Button
                        color="primary"
                        sx={{
                            width: "100%",
                        }}
                        onClick={onNext}
                    >
                        Applying Discount
                    </Button>
                </div>
            </div>
        </TabPanel>
    );
};

export default DiscountTabsMobileNew;
