import React, { useState, useEffect, useMemo } from "react";
import css from "./MobileWeeklyWorkloadTab.module.scss";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TabPanel from "../../TabPanel";
import { Button, Slider } from "@mui/material";
import {
    setTabIndexById,
    setWeeklyWorkloadById,
} from "../../../../features/Resources/resourceSlice";

interface TabProps {
    index: number;
    id: string;
}

const MobileWeeklyWorkloadTab: React.FC<TabProps> = ({ index, id }) => {
    const [workloadSliderValue, setWorkloadSliderValue] = useState(40);

    const { currentResources } = useAppSelector((state) => state.resources);

    const currentResource = useMemo(() => {
        return currentResources.find((res) => res.id === id);
    }, [id, currentResources]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        let currentResource = currentResources.find((res) => res.id === id);
        let currentWorkload = currentResource?.weeklyWorkload
            ? currentResource?.weeklyWorkload * 8
            : 40;
        setWorkloadSliderValue(currentWorkload);
    }, []);

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResource,
                        weeklyWorkload: workloadSliderValue / 8,
                    },
                },
            })
        );
        dispatch(
            setWeeklyWorkloadById({
                id,
                weeklyWorkload: workloadSliderValue / 8,
            })
        );
    }, [workloadSliderValue]);

    const onChangeSlider = (value: number) => {
        setWorkloadSliderValue(value);
        dispatch(
            setWeeklyWorkloadById({
                id,
                weeklyWorkload: value / 8,
            })
        );
    };

    const onNext = () => {
        dispatch(
            setTabIndexById({
                id,
                tabIndex: 7,
            })
        );
        dispatch(
            setWeeklyWorkloadById({
                id,
                weeklyWorkload: workloadSliderValue / 8,
            })
        );
    };

    const onPrevious = () => {
        dispatch(
            setTabIndexById({
                id,
                tabIndex: 5,
            })
        );
    };

    return (
        <TabPanel index={6} value={index}>
            <div className={css.blockWrap}>
                <h1 className={css.title}>
                    How much work do you expect from them weekly?
                </h1>
                <div className={css.experiencePriceBlock}>
                    <p className={css.title}>
                        ${" "}
                        {Number(currentResource?.computedPrice).toLocaleString(
                            undefined,
                            {
                                maximumFractionDigits: 1,
                            }
                        )}
                        <span className={css.subtext}>/ month</span>
                    </p>
                    <p className={css.experienceRow}>
                        <span className={css.orange}>
                            {workloadSliderValue} hours
                        </span>{" "}
                        a week
                    </p>
                </div>
                <div className={css.sliderContainer}>
                    <span className={cn(css.sliderLimit, css.sliderLimitLeft)}>
                        8 hours
                    </span>
                    <Slider
                        sx={{
                            display: "block",
                            margin: "0 auto",
                        }}
                        min={8}
                        max={40}
                        step={8}
                        value={workloadSliderValue}
                        onChange={(e: any) => onChangeSlider(e.target.value)}
                    />
                    <span className={cn(css.sliderLimit, css.sliderLimitRight)}>
                        40 hours
                    </span>
                </div>
                <div className={css.optionContainer}>
                    {new Array(5).fill(0).map((num, index) => {
                        return (
                            <div
                                key={`mobile-weekly-${index}-${num}`}
                                className={cn(css.option, {
                                    [css.selectedOption]:
                                        (index + 1) * 8 === workloadSliderValue,
                                })}
                                onClick={() => onChangeSlider((index + 1) * 8)}
                            >
                                {index + 1} day/week
                            </div>
                        );
                    })}
                </div>
                {/* <div className={css.buttonFlex}>
                    <Button
                        color="secondary"
                        onClick={onPrevious}
                        sx={{
                            padding: "8px 34px",
                            display: "block",
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        color="primary"
                        onClick={onNext}
                        sx={{
                            padding: "8px 34px",
                            display: "block",
                        }}
                    >
                        Next
                    </Button>
                </div> */}
            </div>
        </TabPanel>
    );
};

export default MobileWeeklyWorkloadTab;
