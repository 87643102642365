import { useEffect, useState } from "react";
import css from "./LocationTab.module.scss";
import { Button, MenuItem, Select } from "@mui/material";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCurrentTab } from "../../../../features/Tabs/tabSlice";
import TabPanel from "../../TabPanel";
import Icon from "../../../Icon/Icon";
import {
    chevronIcon,
    chevronLeftIcon,
    chevronRightIcon,
    youpalLogo,
} from "../../../../css/icons";
import { Grey } from "../../../../mui/colors";
import ResourcesCarousel from "../../../ResourcesCarousel/ResourcesCarousel";
import CloseButton from "../../../CloseButton/CloseButton";
import {
    computeProjectTotals,
    setCurrentResources,
    setRegion,
    setResourceTabIndex,
} from "../../../../features/Resources/resourceSlice";
import ResourceService from "../../../../services/resource.service";
import PropertiesCarousel from "../../../PropertiesCarousel/PropertiesCarousel";
import TopNavigationBar from "../../../TopNavigationBar/TopNavigationBar";
import ModalBase from "../../../Modals/ModalBase";
import { colorSet } from "../../../../utils/helpers";

interface TabProps {
    index: number;
}

const resourceService = new ResourceService();

const LocationTab: React.FC<TabProps> = ({ index }) => {
    const [open, setOpen] = useState(false);
    const [preference, setPreference] = useState(false);
    const [regionValue, setRegionValue] = useState("");
    const [regionOptions, setRegionOptions] = useState([]);

    const { currentResources } = useAppSelector((state) => state.resources);
    const { currentResourceIndex, currentTab } = useAppSelector(
        (state) => state.tabs
    );

    const dispatch = useAppDispatch();

    const onNext = () => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: 8,
            })
        );
        dispatch(setCurrentTab(8));
        // dispatch(
        //     setRegion({
        //         index: currentResourceIndex,
        //         region: preference ? regionValue : "",
        //     })
        // );
    };

    const onPrevious = () => {
        dispatch(
            setResourceTabIndex({
                index: currentResourceIndex,
                tabIndex: 6,
            })
        );
        dispatch(setCurrentTab(6));
    };

    const onDone = () => {
        const isAllResourcesDone = currentResources.every(
            (res) => res?.costDetails
        );

        if (isAllResourcesDone) {
            addColor();
            dispatch(setCurrentTab(8));
            // dispatch(completeResources());
            dispatch(computeProjectTotals());
        } else {
            setOpen(true);
        }
    };

    const addColor = () => {
        for (let resource of currentResources) {
            document.dispatchEvent(
                new CustomEvent("update_price_details", {
                    detail: {
                        resource: {
                            ...resource,
                            timeline: {
                                startWeek: resource.timeline.startWeek,
                                endWeek: resource.timeline.endWeek,
                            },
                        },
                    },
                })
            );
        }
        setTimeout(() => {
            dispatch(setCurrentTab(8));
        }, 1000);
        dispatch(
            setCurrentResources([
                ...currentResources.map((role, index) => {
                    return {
                        ...role,
                        color: colorSet[index % colorSet.length],
                    };
                }),
            ])
        );
    };

    const fetchRegions = async () => {
        const res = await resourceService.getRegionList();

        setRegionOptions(
            res.data.message.filter(
                (reg: { region: string }) => reg.region !== "Remote"
            )
        );
    };

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("update_price", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        region: preference ? regionValue : "Remote",
                    },
                },
            })
        );
        document.dispatchEvent(
            new CustomEvent("update_price_details", {
                detail: {
                    resource: {
                        ...currentResources[currentResourceIndex],
                        region: preference ? regionValue : "Remote",
                    },
                },
            })
        );
        if (currentResources && currentResourceIndex >= 0) {
            dispatch(
                setRegion({
                    index: currentResourceIndex,
                    region: preference ? regionValue : "Remote",
                })
            );
        }
    }, [regionValue, preference]);

    useEffect(() => {
        fetchRegions();
    }, []);

    useEffect(() => {
        if (currentResources.length > 0 && currentResourceIndex >= 0) {
            const region = currentResources[currentResourceIndex]?.region;
            if (region.length > 0 && region !== "Remote") {
                setPreference(true);
                setRegionValue(region);
            } else {
                setPreference(false);
                setRegionValue("");
            }
        }
    }, [currentResourceIndex, currentTab]);

    return (
        <TabPanel index={7} value={index}>
            <ModalBase
                onClose={() => setOpen(false)}
                open={open}
                title="Are you sure you want to proceed?"
            >
                <p>You haven't added details for other talents.</p>
                <div className={cn(css.flexSB, css.modalBtns)}>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => setOpen(false)}
                    >
                        Add Details
                    </Button>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => {
                            addColor();
                            // dispatch(setCurrentTab(8));
                            setOpen(false);
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </ModalBase>
            <TopNavigationBar />
            <div className={css.wrapper}>
                {/* <div className={css.header}>
                    <div className={css.logoFlex}>
                        <CloseButton />
                        <Icon path={youpalLogo} />
                    </div>
                    <Button
                        color="primary"
                        onClick={onDone}
                        sx={{
                            padding: "6px 32px",
                            borderRadius: "6px",
                            backgroundColor: Grey[900],
                            "&:hover": {
                                backgroundColor: Grey[900],
                            },
                            "&:disabled": {
                                opacity: 0.4,
                                color: "#fff",
                            },
                        }}
                        disabled={preference && regionValue.length === 0}
                    >
                        Done
                    </Button>
                </div> */}
                <div className={css.resourceCarouselWrapper}>
                    <ResourcesCarousel resources={currentResources} />
                </div>
                <div className={css.mainConatiner}>
                    <PropertiesCarousel resources={currentResources} />
                    <div className={css.innerConatiner}>
                        <div className={css.leftButton}>
                            <button
                                className={css.nextIcon}
                                onClick={onPrevious}
                            >
                                <Icon path={chevronLeftIcon} />
                            </button>
                        </div>
                        <div>
                            <h1 className={css.title}>
                                Do you have any preferences on where the
                                resource should be from?
                            </h1>
                            <div className={css.selectContainer}>
                                <div className={css.cardContainer}>
                                    <div
                                        className={cn(css.card, {
                                            [css.selectedCard]: preference,
                                        })}
                                        onClick={() => setPreference(true)}
                                    >
                                        Yes
                                    </div>
                                    <div
                                        className={cn(css.card, {
                                            [css.selectedCard]: !preference,
                                        })}
                                        onClick={() => setPreference(false)}
                                    >
                                        No
                                    </div>
                                </div>
                                {preference && (
                                    <>
                                        <p className={css.subtitle}>
                                            Which region should they be from?
                                        </p>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            IconComponent={(props) => (
                                                <Icon
                                                    path={chevronIcon}
                                                    {...props}
                                                />
                                            )}
                                            typeof="select"
                                            value={regionValue}
                                            onChange={(e) =>
                                                setRegionValue(
                                                    e.target.value as string
                                                )
                                            }
                                        >
                                            {regionOptions.map(
                                                (option: {
                                                    region: string;
                                                    cost: number;
                                                }) => {
                                                    return (
                                                        <MenuItem
                                                            value={
                                                                option.region
                                                            }
                                                            key={`location-option-${option.region}`}
                                                        >
                                                            {option.region}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </>
                                )}
                            </div>
                            <div className={css.experiencePriceBlock}>
                                <p className={css.title}>
                                    ${" "}
                                    {Number(
                                        currentResources[currentResourceIndex]
                                            ?.computedPrice
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                    <span className={css.subtext}>/ month</span>
                                </p>
                            </div>
                            {/* <div className={css.buttonFlex}>
                        <Button
                            color="secondary"
                            onClick={onPrevious}
                            sx={{
                                padding: "8px 70px",
                                display: "block",
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            color="primary"
                            onClick={onNext}
                            sx={{
                                padding: "8px 70px",
                                display: "block",
                            }}
                            disabled={preference && regionValue.length === 0}
                        >
                            Next
                        </Button>
                    </div> */}
                        </div>
                        <div className={css.rightButton}>
                            <button className={css.nextIcon} onClick={onNext}>
                                <Icon path={chevronRightIcon} />
                            </button>
                        </div>
                    </div>
                    <div className={css.doneButton}>
                        <Button
                            color="primary"
                            onClick={onDone}
                            sx={{
                                padding: "12px 24px",
                            }}
                        >
                            Finish Resource Configuration
                        </Button>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

export default LocationTab;
