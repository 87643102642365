import { MOBILE_SCREEN_WIDTH } from "../../../../constants";
import { useWindowDimensions } from "../../../../hooks";
import InvoiceTermsTabMobile from "./InvoiceTermsTabMobile/InvoiceTermsTabMobile";
import InvoiceTermsTabDesktop from "./InvoiceTermsTabDesktop/InvoiceTermsTabDesktop";

interface TabProps {
    index: number;
}

const InvoiceTermsTab: React.FC<TabProps> = ({ index }) => {
    const { width } = useWindowDimensions();

    return width > MOBILE_SCREEN_WIDTH ? (
        <InvoiceTermsTabDesktop index={index} />
    ) : (
        <InvoiceTermsTabMobile index={index} />
    );
};

export default InvoiceTermsTab;
